import React, {useState, useEffect} from 'react'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Snackbar } from '@mui/material'

import AddIcon from '@mui/icons-material/Add';
import SportSectionDialog from './SportSectionDialog';

import { useTranslation } from 'react-i18next';

const SportSectionsTab = (props) => {
    const { t } = useTranslation();

    const [rows, setRows] = useState([])

    const [openDialog, setOpenDialog] = useState(false);
    const [openSnack, setOpenSnack] = useState(false)

    useEffect(()=>{
        if(props.sportSections){
            let arr = Object.values(props.sportSections)
            setRows(arr)
        }
    },[props.sportSections])

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClickClose = () => {
        setOpenDialog(false);
    };

    const closeSnack = () => {
        setOpenSnack(false)
    };
    
    return (
        <>
            <TableContainer component={Paper} sx={{maxHeight:"50vh", width:{xs:'90vw',md:'100%'},mt:2}}>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell align="center">{t('administrationCellOne')}</TableCell>
                        <TableCell align="center">{t('administrationCellTen')}</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row) => (
                        <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">{row.type}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button onClick={handleClickOpen} variant="contained" color="secondary" endIcon={<AddIcon/>} sx={{mt:4,width: {xs: "80%", md: "40%"}}}>{t('administrationButtonEight')}</Button>
            <SportSectionDialog openDialog={openDialog} setOpenSnack={setOpenSnack} clubId={props.clubId} handleClickClose={handleClickClose}/>
            <Snackbar
                open={openSnack}
                autoHideDuration={3000}
                onClose={closeSnack}
                message="New section saved to database."
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
        </>
    )
}

export default SportSectionsTab


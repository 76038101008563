import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage"
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDLZ-7VNAM1Fb3vCxpBqfgXlyiHEeigyAY",
  authDomain: "club-manager-71f16.firebaseapp.com",
  projectId: "club-manager-71f16",
  storageBucket: "club-manager-71f16.appspot.com",
  messagingSenderId: "1006063897903",
  appId: "1:1006063897903:web:f224ded34bd7d32b92a5da",
  measurementId: "G-18RCT1BC2X"
};

const app = initializeApp(firebaseConfig);
const secondaryApp = initializeApp(firebaseConfig, "Secondary");

const analytics = getAnalytics(app);
const auth = getAuth(app)
const authSecond = getAuth(secondaryApp)
const storage = getStorage(app)
const firestoreDb = getFirestore(app)

export {auth}
export {authSecond}
export default storage;
export {firestoreDb}
export {app}
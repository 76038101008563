import React, {useState, useEffect} from 'react'

import { Box, Toolbar, Grid, Button,Snackbar } from '@mui/material'

import { useTranslation } from 'react-i18next'

import InvitesComponent from './components/InvitesComponent';
import CalendarComponent from './components/CalendarComponent';

import AddIcon from '@mui/icons-material/Add';
import SelectEventTypeDialog from './components/SelectEventTypeDialog';

const Calendar = (props) => {
    const { t } = useTranslation();

    const drawerWidth = 240;

    const [openDialog, setOpenDialog] = useState(false);
    const [openSnack, setOpenSnack] = useState(false)
    const [messageSnack, setMessageSnack] = useState("")

    const [role, setRole] = useState("");
    const [athletes, setAthletes] = useState([]);
    const [locations, setLocations] = useState([]);

    useEffect(()=>{
        if(props.calendarData.role) {
            setRole(props.calendarData.role)
        }
        if(props.athletes) {
            setAthletes(props.athletes)
        }
        if(props.calendarData.locations){
            setLocations(props.calendarData.locations)
        }
    },[props.calendarData.role,props.athletes, props.calendarData.locations])

    const handleClickOpen = () => {
        if(athletes !== undefined && athletes.length !== 0 && locations.length !== 0 && Object.keys(locations).length !== 0){
            setOpenDialog(true);
        } else if(athletes === undefined || athletes.length === 0 || locations.length === 0 || Object.keys(locations).length === 0){
            setMessageSnack(t("calendarSnackOne"))
            setOpenSnack(true)
        } 
    };

    const handleClickClose = () => {
        setOpenDialog(false);
    };

    const closeSnack = () => {
        setOpenSnack(false)
    };

    
    return (
        <>
            <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                    <Toolbar />
                    <Grid container spacing={2}>
                    {(role === "Club Manager" || role === "Coach" || role === "Owner") &&
                    <>
                        <Grid item xs={12} align="center">
                            <Button onClick={handleClickOpen} variant="contained" color="secondary" endIcon={<AddIcon/>} sx={{mt:1,width: {xs: "80%", md: "40%"}}}>{t("calendarButtonOne")}</Button>
                        </Grid>
                    </>
                    }
                        <Grid item xs={12} align="center">
                            <CalendarComponent calendarData={props.calendarData}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InvitesComponent calendarData={props.calendarData}/>
                        </Grid>
                    </Grid>
            </Box>
            <SelectEventTypeDialog 
                openDialog={openDialog} 
                handleClickClose={handleClickClose}
                calendarData={props.calendarData} 
                athletes={props.athletes} 
                staff={props.staff}
                setOpenSnack={setOpenSnack} 
                setMessageSnack={setMessageSnack}
            />
            <Snackbar
                open={openSnack}
                autoHideDuration={3000}
                onClose={closeSnack}
                message={messageSnack}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
        </>
    )
}

export default Calendar


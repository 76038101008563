import React, {useState} from 'react'

import { Typography, Grid, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Send } from '@mui/icons-material';

import logo_main from '../assets/logo/White_p.png'

import { getAuth, updatePassword } from "firebase/auth";
import { getDatabase, ref, update } from "firebase/database";
import {useNavigate} from 'react-router-dom'


const UpdatePass = (props) => {

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    
    const [errorPass, setErrorPass] = useState(false)
    const [errorPassText, setErrorPassText] = useState("")

    const [loading, setLoading] = React.useState(false);

    const navigate = useNavigate()

    const auth = getAuth();
    const user = auth.currentUser;

    const validatePassword = () => {
        setErrorPass(false)
        setErrorPassText("")
        let isValid = true
        if (password !== '' && confirmPassword !== ''){
          if (password !== confirmPassword) {
            isValid = false
            setErrorPass(true)
            setErrorPassText("Passwords do not match.")
          } else if (password.length < 6) {
            isValid = false
            setErrorPass(true)
            setErrorPassText("The password is too short (6 characters required).")
          }
        }
        return isValid
    }
    
    const updatePass = () => {
        setLoading(true)
        if(validatePassword()) {
            updatePassword(user, password).then(() => {
                const dbRef = ref(getDatabase());
                let uid = auth.currentUser.uid;
                const updates = {};
                updates['/users/' + uid + '/invitedFirstSignIn'] = false
                props.setInvitedFirstSignIn(false)
                update(dbRef, updates).then(()=>{
                    navigate('/home');
                });
            }).catch((error) => {
                console.log(error)
            });  
        } else if (!validatePassword()){
          setLoading(false)
        }
    }
    

    return (
        <>
            <Grid container sx={{width:'100vw',height:'100vh'}} align="center" >
                <Grid item xs={12}>
                    <img
                        src={logo_main}
                        alt={"Logo"}
                        style={{width: '120px', height: '120px',textAlign:'center', marginTop:'20px'}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" fontWeight={"500"}>Update Your Password</Typography>
                </Grid>
                <Grid item xs={12} sx={{px:3}}> 
                    <Typography variant="subtitle2" fontWeight={"500"}>You have to update your current password in order to gain access to your profile.</Typography>
                </Grid>
                <Grid item xs={12} align="center"> 
                    <TextField
                        label="Enter your new password"
                        required
                        type="password"
                        value={password}
                        onChange={(e)=>{setPassword(e.target.value)}}
                        error={errorPass}
                        helperText={errorPass ? errorPassText : ""}
                        sx={{width:{ xs: "80%", md: "40%" },}}
                    />
                </Grid>
                <Grid item xs={12} align="center"> 
                    <TextField
                        label="Re-Enter your new password"
                        required
                        type="password"
                        value={confirmPassword}
                        onChange={(e)=>{setConfirmPassword(e.target.value)}}
                        error={errorPass}
                        helperText={errorPass ? errorPassText : ""}
                        sx={{width:{ xs: "80%", md: "40%" },}}
                    />
                </Grid>
                <Grid item xs={12} align="center"> 
                    <LoadingButton
                                onClick={updatePass}
                                endIcon={<Send/>}
                                loading={loading}
                                loadingPosition="end"
                                variant="contained"
                                color="secondary" 
                                size="large" 
                                sx={{width: {xs: "80%", md: "40%"}}} 
                            >
                                Update password
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    )
}

export default UpdatePass
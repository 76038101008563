import React, {useState, useEffect} from 'react'

import { Typography, Grid, Box, TextField, MenuItem } from '@mui/material';

import StepperComponent from './components/StepperComponent';
// import MobileStepper from "./components/MobileStepper"

import ClubDetailsSignUp from './components/ClubDetailsSignUp';
import AccountDetailsSignUp from './components/AccountDetails';
import InviteTeamSignUp from './components/InviteTeamSignUp';
import Subscription from './components/Subscription';

import logo_side from '../../assets/logo/Black_NoBgnd.png'
import logo_main from '../../assets/logo/White_p.png'

import moment from 'moment-timezone';

import {createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword} from 'firebase/auth'
import {auth} from '../../Firebase/firebase'
import { authSecond } from '../../Firebase/firebase';
import {useNavigate} from 'react-router-dom'
import {useAuthValue} from '../../Firebase/AuthContext'
import { getDatabase, ref, set, update } from "firebase/database";

import { useTranslation } from 'react-i18next';

import { createManagerAccount, paySubscription } from '../../stripe/PaymentFunctions';

import { v4 as uuidv4 } from 'uuid';

import { plans } from '../../utils/plans_data';

export const languagesArr = [
    { label: "English", code: "en" },
    { label: "Romana", code: "ro" },
];

const SignUp = () => {

    const { i18n, t } = useTranslation();
    const [language, setLanguage] = useState("English")
    
    const [activeStep, setActiveStep] = React.useState(0);

    const [clubName, setClubName] = useState("");
    const [website, setWebsite] = useState("");
    const [timezone, setTimezone] = useState(moment.tz.guess());

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const [plan, setPlan] = useState("Demo");
    const [membershipValue, setMembershipValue] = useState("");
    const [inputList, setInputList] = useState([]);
    const [errorTextInvites, setErrorTextInvites] = useState("")
    const [loading, setLoading] = useState(false)

    const [clubId, setClubId] = useState(uuidv4());

    const [errorPass, setErrorPass] = useState(false)
    const [errorPassText, setErrorPassText] = useState("")
    const [errorEmail, setErrorEmail] = useState(false)
    const [errorClubName, setErrorClubName] = useState(false)
    const [errorFirstName, setErrorFirstName] = useState(false)
    const [errorLastName, setErrorLastName] = useState(false)
    const [errorMembership, setErrorMembership] = useState(false)

    const {setTimeActive} = useAuthValue()
    const navigate = useNavigate()

    const onChangeLang = (e) => {
        setLanguage(e.target.value.label)
        const lang_code = e.target.value;
        i18n.changeLanguage(lang_code.code);
    };

    const handleNext = () => {
        if(activeStep === 0) {
            if(validateClubName()){
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else if(activeStep === 1) {
            if(validateName() && validateEmail() && validatePassword()){
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else if(activeStep === 2) {
            if(validateMembership()){
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
                setErrorMembership(true)
            }
        }
        else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const validateClubName = () => {
        setErrorClubName(false)
        let isValid = true
        if (clubName === ''){
            isValid = false
            setErrorClubName(true)
        }
        return isValid
    }

    const validatePassword = () => {
        setErrorPass(false)
        setErrorPassText("")
        let isValid = true
        if (password !== '' && confirmPassword !== ''){
          if (password !== confirmPassword) {
            isValid = false
            setErrorPass(true)
            setErrorPassText(t('signupErrorOne'))
          } else if (password.length < 6) {
            isValid = false
            setErrorPass(true)
            setErrorPassText(t('signupErrorTwo'))
          }
        }
        return isValid
    }

    const validateEmail= () => {
        setErrorEmail(false)
        let isValid = true
        if (email !== ''){
          if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            isValid = false
            setErrorEmail(true)
          }
        } else {
            isValid = false
            setErrorEmail(true)
        }
        return isValid
    }

    const validateName = () => {
        setErrorFirstName(false)
        setErrorLastName(false)
        let isValid = true
        if (firstName !== '' && lastName !== ''){

        } else if (firstName === ''){
            isValid = false
            setErrorFirstName(true)
        } else if (lastName === ''){
            isValid = false
            setErrorLastName(true)
        }
        return isValid
    }

    const validateInvites = () => {
        let isValid = true
        let emailArr = []
        setErrorTextInvites("")
        for(let i = 0; i < inputList.length; i++) {
            if(inputList[i].email !== "" && inputList[i].role === ""){
                isValid = false
            }
            else if(inputList[i].email !== "" && inputList[i].role !== ""){
                emailArr.push(inputList[i].email)
            }
        }
        if(emailArr.length !== new Set(emailArr).size){
            isValid = false
            setErrorTextInvites(t('signupErrorThree'))
        }

        return isValid
    }

    const validateMembership = () => {
        setErrorMembership(false)
        let isValid = true
        if (membershipValue === ''){
            isValid = false
            setErrorMembership(true)
        }
        return isValid
    }


    function send_invitations(invitation_list,uidMaster) {
        for(let i = 0; i < invitation_list.length; i++) {
            if(invitation_list[i].email !== ""){
                let email = invitation_list[i].email;
                let role = invitation_list[i].role;
                let firstName = invitation_list[i].firstName;
                let lastName = invitation_list[i].lastName;
                create_user(email,uidMaster,role,firstName,lastName);
            }
        }
    }
    
    function create_user(email,uidMaster,role,firstName,lastName) {
        let password = "password";
        createUserWithEmailAndPassword(authSecond, email, password)
          .then((userCredential) => {
            // const user = userCredential.user;
            sendEmailVerification(authSecond.currentUser) 
            let uid = authSecond.currentUser.uid
            const db = getDatabase();
            set(ref(db, 'users/' + uid), {
                email: email,
                role: role,
                clubId: clubId,
                invitedBy: uidMaster,
                invitedFirstSignIn: true,
                dateCreated: moment().tz(timezone).format('DD/MM/YYYY'),
                firstName: firstName,
                lastName: lastName,
                
            }).then(()=>{
            authSecond.signOut();
            })
          })
          .catch((error) => {
            console.log(error)
          });
    }


    const register = () => {
        setLoading(true)
        if( validateName() && validateEmail() && validatePassword() &&  validateInvites() && validateMembership()) {
            createUserWithEmailAndPassword(auth, email, password)
            .then(() => {
                sendEmailVerification(auth.currentUser)   
                let uid = auth.currentUser.uid
                const db = getDatabase();

                let countInvites = 0
                if(inputList.length === 1 && inputList[0].email === ""){
                    countInvites = 0
                } else {
                    countInvites = inputList.length
                }
                
                set(ref(db, 'clubs/' + clubId), {
                clubName: clubName,
                website: website,
                timezone: timezone,
                currency: "RON",
                clubManagerEmail: email,
                clubManagerId: uid,
                clubType: plan,
                dateCreated: moment().tz(timezone).format('DD/MM/YYYY'),
                clubInvites: countInvites + 1,
                restricted: false,
                membershipValue: membershipValue,
              })
              .then(() => {
                setTimeActive(true)
                set(ref(db, 'users/' + uid), {
                    email: email,
                    role: "Club Manager",
                    clubId: clubId,
                    clubInvites: countInvites,
                    dateCreated: moment().tz(timezone).format('DD/MM/YYYY'),
                    firstName: firstName,
                    lastName: lastName,
                    invitedFirstSignIn: false,
                })
              }).catch((err) => alert(err.message))
            }).then(()=>{
                send_invitations(inputList,auth.currentUser.uid)
            })
            .then(()=>{
                signInWithEmailAndPassword(auth, email, password).then(()=>{
                    // navigate('/verify-email')
                })
            }).then(()=>{
                // createManagerAccount(auth.currentUser.uid)
            }).then(()=>{
                if(plan!=="Demo"){
                    const subscriptionPlan = plans.find(found => found.name === plan);
                    if(subscriptionPlan){
                        paySubscription("verify-email",subscriptionPlan.stripeId)
                    }
                }
            }).then(()=>{
                navigate('/verify-email')
            })
            .catch(err => console.log(err.message))
            setEmail('')
            setPassword('')
            setConfirmPassword('')
            setLoading(false)
        } else if (!validateInvites() || !validateMembership()){
            setErrorTextInvites(t('signupErrorThree'))
            setLoading(false)
        }
    }
    
    return (
        <>
            <Grid container sx={{width:'100vw',height:'100vh'}}>
                <Grid item md={4} sx={{backgroundColor:"#303030",display: { xs: 'none', md: 'block' }}}> 
                    <Grid container spacing={8}>
                        <Grid item xs={6}>
                                <img
                                    src={logo_side}
                                    alt={"Logo"}
                                    style={{width: '100px', height: '100px'}}
                                />
                        </Grid>
                        <Grid item container xs={6} align="center" alignItems="center" justifyContent="center">
                            <TextField
                                select
                                size="small"
                                value={languagesArr.find((option) => option.label === language)}
                                variant='standard'
                                onChange={(e) => { onChangeLang(e) }}
                                sx={{ minWidth: '10%', '& .MuiSelect-select': { color: 'white' } }}
                            >
                                {languagesArr.map((option) => (
                                    <MenuItem key={option.code} value={option} sx={{color:'white'}}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{m:2}}>
                                <StepperComponent activeStep={activeStep} handleBack={handleBack}/>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption" sx={{m:2, position:'absolute', bottom:'0px'}} color="text.primary">© Club Manager 2023</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container>
                        <Grid item xs={12} align="center"> 
                            <img
                                src={logo_main}
                                alt={"Logo"}
                                style={{width: '120px', height: '120px',textAlign:'center'}}
                            />
                        </Grid>
                        <Grid item xs={12} align="center"> 
                            <Typography variant="subtitle1" sx={{fontWeight:'500'}}>
                                {activeStep === 0 ?
                                    t('signupTitleOne')
                                :
                                activeStep === 1 ?
                                    t('signupTitleTwo')
                                :
                                activeStep === 2 ?
                                    t('signupTitleThree')
                                :
                                activeStep === 3 ?
                                    t('signupTitleFour')
                                :
                                ""
                                }
                            </Typography>
                        </Grid>

                        <Grid item xs={12}> 
                            {activeStep === 0 ? 
                                <ClubDetailsSignUp 
                                    handleNext={handleNext} 
                                    clubName={clubName} 
                                    setClubName={setClubName}
                                    website={website}
                                    setWebsite={setWebsite}
                                    timezone={timezone}
                                    setTimezone={setTimezone}
                                    errorClubName={errorClubName}
                                    />
                            :
                            activeStep === 1 ? 
                                <AccountDetailsSignUp 
                                    handleNext={handleNext}
                                    email={email}
                                    setEmail={setEmail}
                                    password={password}  
                                    setPassword={setPassword}
                                    confirmPassword={confirmPassword}
                                    setConfirmPassword={setConfirmPassword}  
                                    firstName={firstName}
                                    setFirstName={setFirstName}
                                    lastName={lastName}
                                    setLastName={setLastName}
                                    errorPass={errorPass}
                                    errorPassText={errorPassText}
                                    errorEmail={errorEmail}
                                    errorFirstName={errorFirstName}
                                    errorLastName={errorLastName}
                                    />
                            :
                            activeStep === 2 ?
                                <InviteTeamSignUp
                                    handleNext={handleNext} 
                                    membershipValue={membershipValue}
                                    setMembershipValue={setMembershipValue}
                                    errorMembership={errorMembership}
                                    setPlan={setPlan}
                                    register={register}
                                    inputList={inputList}
                                    setInputList={setInputList}
                                    errorTextInvites={errorTextInvites}
                                    loading={loading}
                                    setLoading={setLoading}
                                />
                            : 
                            activeStep === 3 ?
                                <Subscription
                                    register={register}
                                    plan={plan}
                                    setPlan={setPlan}
                                    clubInvites={inputList.length}
                                    loading={loading}
                                    setLoading={setLoading}
                                />
                            : 
                            <></>
                            }
                            
                        </Grid>

                        {/* <Grid item xs={12} sx={{display: { xs: 'block', md: 'none' }, position:'absolute', bottom:'0px',width:'100%'}} align="center"> 
                            <MobileStepper activeStep={activeStep} handleBack={handleBack} handleNext={handleNext}/>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default SignUp
import React, {useState, useEffect} from 'react';

import { Box, Divider, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle  } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { getDatabase, ref, update} from "firebase/database";
import { collection, query, getDocs, doc, getDoc} from "firebase/firestore";
import { firestoreDb } from '../../../Firebase/firebase';
import { useAuthValue } from '../../../Firebase/AuthContext';

import PlansComponent from './PlansComponent';
import { plans } from '../../../utils/plans_data';
import { paySubscription, updateSubscription } from '../../../stripe/PaymentFunctions';

import { useTranslation } from 'react-i18next';

export default function PlansDialog(props) {
    const { t } = useTranslation();

    const auth = useAuthValue()

    const [clubId, setClubId] = useState("")

    const [loading, setLoading] = useState(false)
    const [buttonText, setButtonText] = useState(t('paymentButtonSix'))

    const [planIndex, setPlanIndex] = useState(0)

    useEffect(()=>{
      if(props.planIndex){
        setPlanIndex(props.planIndex)
      }
    },[props.planIndex])

    useEffect(()=>{
        if(props.clubId){
            setClubId(props.clubId)
        }
    },[props.clubId])

    const saveData = () => {
        if(props.planIndex !== planIndex){
            setLoading(true)
            setButtonText("Loading..")

            let priceId = plans[planIndex].stripeId
            let planName = plans[planIndex].name
            let planPrice = plans[planIndex].price

            let userId = auth.currentUser.uid

            const db = firestoreDb;
            
            const subscriptionsCollection = collection(db, `customers/${userId}/subscriptions`);

            getDocs(subscriptionsCollection)
            .then((querySnapshot) => {
            if (!querySnapshot.empty) {
                const fetchedSubscriptions = [];
                querySnapshot.forEach((doc) => {
                fetchedSubscriptions.push({
                    id: doc.id,
                    data: doc.data(),
                });
                });
                if(fetchedSubscriptions[0]){
                    let subscriptionId = fetchedSubscriptions[0].id
                    let subscriptionItemId = fetchedSubscriptions[0].data.items[0].id
                    updateSubscription(priceId,subscriptionId,subscriptionItemId)

                    const db_rt = getDatabase();
                    const updates = {};
                    updates['/clubs/' + clubId + '/clubType'] = planName
                    update(ref(db_rt), updates)
                    .then(()=>{
                    }).then(()=>{
                        setLoading(false)
                        setButtonText(t('paymentButtonSix'))
                        props.setOpenSnack(true)
                        props.handleClickClose()
                        props.setSubscriptionName(planName+"CM")
                        props.setSubscriptionAmount(planPrice)
                        props.setClubType(planName)
                    })
                }
            } else {
                if(props.clubType === "Demo"){
                    if(priceId){
                        const db_rt = getDatabase();
                        const updates = {};
                        updates['/clubs/' + clubId + '/clubType'] = planName
                        update(ref(db_rt), updates)
                        .then(()=>{
                            paySubscription("payment",priceId)
                        }).then(()=>{
                            props.setSubscriptionName(planName+"CM")
                            props.setSubscriptionAmount(planPrice)
                            props.setClubType(planName)
                        })
                    }
                }
            }
            })
            .catch((error) => {
            console.error("Error getting documents:", error);
            });
        }   else {
            setLoading(false)
            setButtonText(t('paymentButtonSix'))
        }
    }

    return (
        <div>
        <Dialog open={props.openDialog} onClose={props.handleClickClose}>
            <DialogTitle color="black" variant="subtitle1" fontWeight={500} sx={{textDecoration: 'underline',textDecorationColor:'#54cdb7', textDecorationThickness:'2px'}}>{t('paymentTextTen')}</DialogTitle>
            <Divider/>
            <DialogContent>
            <DialogContentText  variant="subtitle1" fontWeight={500} >
                {t('paymentTextNine')} 
            </DialogContentText>
            <Box align="center" sx={{mt:2}}>
                <PlansComponent planIndex={planIndex} setPlanIndex={setPlanIndex} clubInvites={props.clubInvites}/>
            </Box>
            </DialogContent>
            <DialogActions sx={{justifyContent:"center"}}>
                <Button onClick={props.handleClickClose} variant="contained" size="small" sx={{color:"#FFF", width:'30%', backgroundColor:"gray", ":hover":{backgroundColor:"#303030"}}}>{t('paymentTextEleven')}</Button>
                <LoadingButton
                    onClick={saveData}
                    loading={loading}
                    variant="contained"
                    color="primary" 
                    size="small" 
                    sx={{color:"#FFF", width:'30%'}}
                >
                    {buttonText}
                </LoadingButton>
            </DialogActions>
        </Dialog>
        </div>
    );
}
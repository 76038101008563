import React from 'react'
import Invite from '../components/Invite'

const InvitePage = (props) => {
    return (
        <>
            <Invite userData={props.userData} athletes={props.athletes}/>
        </>
    )
}

export default InvitePage
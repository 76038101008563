import {useState, useEffect} from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import { plans } from '../../../utils/plans_data';

import { useTranslation } from 'react-i18next';

const PlansComponent = (props) => {
  const { t } = useTranslation();

  const [rows, setRows] = useState(plans)

  const handleRowClick = (row,index) => {
    if(props.clubInvites > row.accounts){

    } else {
      props.setPlanIndex(index)
    }
  };

  return (
    <TableContainer component={Paper} sx={{mt:2}}>
      <Table sx={{ minWidth: 500 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">{t('paymentCellOne')}</TableCell>
            <TableCell align="center">{t('paymentCellSeventeen')}</TableCell>
            <TableCell align="center">{t('paymentCellEighteen')}</TableCell>
            <TableCell align="center">{t('paymentCellNineteen')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={row.name}
              onClick={() => handleRowClick(row,index)} 
              sx={{
                cursor: 'pointer',
                '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: index === props.planIndex ? '#54cdb7' : row.accounts < props.clubInvites ? "grey" : ''
              }}
            >
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="center">{row.accounts}</TableCell>
              <TableCell align="center">{row.price}</TableCell>
              <TableCell align="center">{row.period}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PlansComponent
import React, {useEffect, useState} from 'react'

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Snackbar } from '@mui/material';
import { payEquipment, payEvent } from '../../../stripe/PaymentFunctions';

import { useAuthValue } from '../../../Firebase/AuthContext';

import { useTranslation } from 'react-i18next';

const EquipmentsComponent = (props) => {
    const { t } = useTranslation();

    const [rows, setRows] = useState([])
    const [stripeAccount, setStripeAccount] = useState("")
    const [childId, setChildId] = useState("")

    const {currentUser} = useAuthValue()
    
    useEffect(()=>{
        if(props.equipments){
            setRows(Object.values(props.equipments))
        }
        if(props.stripeAccount){
            setStripeAccount(props.stripeAccount)
        }
        if(props.childId){
            setChildId(props.childId)
        }
    },[props.stripeAccount, props.userRole,props.equipments,props.childId])

    const handlePay = (row) => {
        let cost = row.equipmentData.cost
        let eventId = row.equipmentId
        let uid = currentUser.uid

        if(props.userRole === "Athlete"){
            payEquipment(stripeAccount, cost, uid, eventId)
        } else if(props.userRole === "Parent"){
            payEquipment(stripeAccount, cost, childId, eventId)
        }
    };

    return (
        <>
            <TableContainer component={Paper} sx={{maxHeight:"400px", width:{xs:'90vw',md:'100%'},mt:2}}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                <TableRow>
                    <TableCell align="center">{t('paymentCellOne')}</TableCell>
                    <TableCell align="center">{t('paymentCellTwenty')}</TableCell>
                    <TableCell align="center">{t('paymentCellTwentyOne')}</TableCell>
                    <TableCell align="center">{t('paymentCellTwentyTwo')}</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {rows.map((row, index) => (
                    <TableRow
                    key={row.name}
                    sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                    }}
                    >
                        <TableCell align="center">{row.equipmentData.name}</TableCell>
                        <TableCell align="center">{row.status}</TableCell>
                        <TableCell align="center">{row.equipmentData.cost ? row.equipmentData.cost : "-"}</TableCell>
                        {(row.equipmentData.cost) ? 
                            <TableCell align="center">
                                <Button onClick={()=>{handlePay(row)}} disabled={row.equipmentData.paid ? true : false}>
                                    {row.equipmentData.paid ?  row.equipmentData.paid : "PAY"}
                                </Button>
                            </TableCell>
                        :
                            <TableCell align="center">-</TableCell>
                        }
                        
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
        </>
    )
}

export default EquipmentsComponent


import React, {useState, useEffect} from 'react'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Avatar,Snackbar} from '@mui/material'

import AddIcon from '@mui/icons-material/Add';

import TeamDialog from './TeamDialog';

import { useTranslation } from 'react-i18next';

const TeamsTab = (props) => {
    const { t } = useTranslation();

    const [rows, setRows] = useState([])

    const [openDialog, setOpenDialog] = useState(false);
    const [openSnack, setOpenSnack] = useState(false)
    const [messageSnack, setMessageSnack] = useState("")

    useEffect(()=>{
        if(props.sportSections) {
            let sportSections = props.sportSections
            let keys = Object.keys(sportSections)
            let values = Object.values(sportSections)
            const newSS = []
            for(let i = 0; i < values.length; i++){
                let obj = values[i]
                obj.uid = keys[i]
                newSS.push(obj)
            }

            if(props.teams){
                let arr = Object.values(props.teams)
                for(let i = 0 ; i < arr.length; i++){
                    var result = newSS.find(newSS => newSS.uid === arr[i].sportSection);
                    if(result){
                        arr[i].sportSection = result.name
                    }
                }
                setRows(arr)
            }
        }

    },[props.teams, props.sportSections])

    
    const closeSnack = () => {
        setOpenSnack(false)
    };

    const handleClickOpen = () => {
        if(props.athletes!== undefined && props.athletes.length  !== 0 && props.sportSections!== undefined && props.sportSections != []){
            setOpenDialog(true);
        } else if(props.athletes === undefined || props.athletes.length === 0){
            setMessageSnack(t('administrationSnackTwo'))
            setOpenSnack(true)
        } else if(props.sportSections === undefined || props.sportSection == []){
            setMessageSnack(t('administrationSnackThree'))
            setOpenSnack(true)
        }
        // setOpenDialog(true);
    };

    const handleClickClose = () => {
        setOpenDialog(false);
    };
    
    return (
        <>
            <TableContainer component={Paper} sx={{maxHeight:"50vh", width:{xs:'90vw',md:'100%'},mt:2}}>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell align="center">{t('administrationCellOne')}</TableCell>
                        <TableCell align="center">{t('administrationCellSeven')}</TableCell>
                        <TableCell align="center">{t('administrationCellEight')}</TableCell>
                        <TableCell align="center">{t('administrationCellNine')}</TableCell>
                        <TableCell align="center">Logo</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row) => (
                        <>
                        <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">{row.sportSection}</TableCell>
                            <TableCell align="center">{row.athletes !== undefined ? Object.values(row.athletes).length : 0}</TableCell>
                            <TableCell align="center">{row.staff !== undefined ? Object.values(row.athletes).length : 0}</TableCell>
                            <TableCell align="center">
                                <Avatar sx={{width: 50, height: 50, mx:'auto'}} src={row.image}/>
                            </TableCell>
                        </TableRow>
                    </>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button onClick={handleClickOpen} variant="contained" color="secondary" endIcon={<AddIcon/>} sx={{mt:4,width: {xs: "80%", md: "40%"}}}>{t('administrationButtonSeven')}</Button>
            <TeamDialog 
                sportSections={props.sportSections}
                athletes={props.athletes} 
                staff={props.staff} 
                clubId={props.clubId} 
                openDialog={openDialog} 
                setOpenSnack={setOpenSnack} 
                setMessageSnack={setMessageSnack}
                handleClickClose={handleClickClose}/>
            <Snackbar
                open={openSnack}
                autoHideDuration={3000}
                onClose={closeSnack}
                message={messageSnack}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
            </>
    )
}

export default TeamsTab


import {useState,useEffect} from 'react'

import {useAuthValue} from './AuthContext'

import {sendEmailVerification} from 'firebase/auth'
import {useNavigate} from 'react-router-dom'
import {Link} from 'react-router-dom'

import { Typography, Grid, Button } from '@mui/material';
import { child, getDatabase, ref, get } from "firebase/database";
import { auth } from './firebase'
import logo_main from '../assets/logo/White_p.png'
import { createManagerAccount, paySubscription } from '../stripe/PaymentFunctions'
import { getSubscriptionStatus } from '../stripe/StripeGetUrl'

import { plans } from '../utils/plans_data'


function VerifyEmail() {

  const {currentUser} = useAuthValue()
  const [time, setTime] = useState(60)
  const {timeActive, setTimeActive} = useAuthValue()
  const navigate = useNavigate()

  // const [hasSubscription, setHasSubscription] = useState(true)

  // useEffect(() => {
  //   const checkPremium = async () => {
  //     const status = auth.currentUser
  //       ? await getSubscriptionStatus()
  //       : false;
  //     setHasSubscription(status);
  //   };
  //   checkPremium();
  // }, [auth.currentUser?.uid]);

  useEffect(() => {
    const interval = setInterval(() => {
      currentUser?.reload()
      .then(() => {
        if(currentUser?.emailVerified){
          clearInterval(interval)

          const dbRef = ref(getDatabase());
          let uid = auth.currentUser.uid;

          get(child(dbRef, `users/${uid}`)).then((snapshot) => {
              if (snapshot.exists()) {
                  var data_user = snapshot.val()
                  if(data_user.clubId){
                    get(child(dbRef, `clubs/${data_user.clubId}`)).then((snapshot_club) => {
                      if (snapshot_club.exists()) {
                        var data_club = snapshot_club.val()
                        let hasSubscription = getSubscriptionStatus(auth.currentUser.uid)
                        if(data_user.role === "Club Manager" && data_club.clubType !=="Demo" && !hasSubscription){
                          const subscriptionPlan = plans.find(plan => plan.name === data_club.clubType);
                          if(subscriptionPlan){
                              paySubscription("verify-email",subscriptionPlan.stripeId)
                          }
                        } else if(data_user.role === "Club Manager" && !data_user.stripeAccount){
                            createManagerAccount(uid,data_user.clubId)
                        }  else if(data_user.invitedFirstSignIn && data_user.invitedFirstSignIn === true){
                            navigate('/update-password')
                        }
                      }
                    })
                  }
              } else {
                navigate('/home')
              }
          })
        } 
      })
      .catch((err) => {
        alert(err.message)
      })
    }, 1000)
  }, [navigate, currentUser])

  useEffect(() => {
    let interval = null
    if(timeActive && time !== 0 ){
      interval = setInterval(() => {
        setTime((time) => time - 1)
      }, 1000)
    }else if(time === 0){
      setTimeActive(false)
      setTime(60)
      clearInterval(interval)
    }
    return () => clearInterval(interval);
  }, [timeActive, time, setTimeActive])

  const resendEmailVerification = () => {
    sendEmailVerification(currentUser)
    .then(() => {
      setTimeActive(true)
    }).catch((err) => {
      alert(err.message)
    })
  }

  return (
    
    <Grid container sx={{width:'100vw',height:'100vh', overflow:'hidden'}} align="center">
      <Grid item xs={12}>
          <img
              src={logo_main}
              alt={"Logo"}
              style={{width: '120px', height: '120px',textAlign:'center', marginTop:'20px'}}
          />
      </Grid>
      <Grid item xs={12} md={12}> 
        <Typography variant="subtitle1" fontWeight={"500"}>Verify your Email Address</Typography>
      </Grid>
      <Grid item xs={12} md={12}> 
        <Typography variant="subtitle1" fontWeight={"500"}>A verification email has been sent to:</Typography>
        <Typography variant="subtitle1" fontWeight={"500"} color="primary">{currentUser?.email}</Typography>
      </Grid>
      <Grid item xs={12} md={12}> 
        <Typography variant="subtitle2" fontWeight={"500"}>Follow the instruction in the email to verify your account. Please also check the spam folder.</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Button
          disabled={timeActive}
          onClick={resendEmailVerification}
          variant="contained"
          >
          Resend Email {timeActive && time}
      </Button>
      </Grid>
      <Grid item xs={12} md={12}> 
        <Typography variant="subtitle2" fontWeight={"500"}>
        <Link to="/">Back to sign in</Link></Typography>
      </Grid>
    </Grid>
  )
}

export default VerifyEmail
import React from 'react'
import Administration from '../components/Administration'

const AdministrationPage = (props) => {
    return (
        <>
            <Administration 
                administrationData={props.administrationData} 
                athletes={props.athletes} 
                staff={props.staff}/>
        </>
    )
}

export default AdministrationPage
import React, {useState, useEffect} from 'react'

import { Grid,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Stack, Box, Collapse, Typography, Divider } from '@mui/material'

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { getDatabase, ref, update} from "firebase/database";

import moment from 'moment-timezone';

import { useTranslation } from 'react-i18next'

const PendingInvites = (props) => {

    const { t } = useTranslation();
    
    const [uidMaster, setUidMaster] = useState("");
    const [timezone, setTimezone] = useState("");

    const [invites, setInvites] = useState([]);
    const [rows, setRows] = useState([]);

    useEffect(()=>{
        if(props.uidMaster){
            setUidMaster(props.uidMaster)
        }
        if(props.timezone){
            setTimezone(props.timezone)
        }
        if(props.invites){
            let inv = props.invites
            let keys = Object.keys(inv)
            let val = Object.values(inv)
            const newInv = [];

            let locations = props.locations
            let locKeys = Object.keys(locations)
            let locVals = Object.values(locations)

            for(let i = 0 ; i < val.length; i++){
                val[i].uid = keys[i];
                if(val[i].locationType === 'club'){
                    for(let j = 0; j < locVals.length; j++){
                        if(locKeys[j] === val[i].location){
                            val[i].location = locVals[j].name;
                            break; 
                        }
                    }
                }
                let calculateStatus = ""
                if(val[i].endDate) {
                    calculateStatus = val[i].endDate
                } else if (val[i].date){
                    calculateStatus = val[i].date
                }
                if(calculateStatus){
                    const momentDate = moment.tz(calculateStatus, 'DD/MM/YYYY', props.timezone);
                    const momentNow = moment.tz(props.timezone);

                    if (momentDate.isAfter(momentNow, 'day')) {
                        // console.log('The date is in the future');
                    } else if (momentDate.isBefore(momentNow, 'day')) {
                        // console.log('The date is in the past');
                        val[i].status = "passed"
                    } else {
                        // console.log('The date is today');
                    }
                }
                if(val[i].status === "pending"){
                    newInv.push(val[i]);
                }
            }

            setInvites(newInv)
            setRows(newInv)
        }
    },[props.uidMaster,props.invites,props.locations,props.timezone])

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = useState(false);
        const [statusChange, setStatusChange] = useState(false);

        const handleStatusChange = () => {
            setStatusChange(true);
        };

        const handleJoin = (rowId) => {
            const db = getDatabase()
            const updates = {};
            updates['users/'+uidMaster+'/invites/'+rowId+"/status"] = "joined"
            update(ref(db), updates)
        }

        const handleRefuse = (rowId) => {
            const db = getDatabase()
            const updates = {};
            updates['users/'+uidMaster+'/invites/'+rowId+"/status"] = "refused"
            update(ref(db), updates)
        }

        return (
            <>
                    <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                            <TableCell>
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpen(!open)}
                                >
                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            </TableCell>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">{row.eventType}</TableCell>
                            <TableCell align="center">{row.date}</TableCell>
                            <TableCell align="center">{row.location}</TableCell>
                            <TableCell align="center">
                                {
                                row.status !== "pending" ?
                                <Button variant="text" color={row.status === "joined" ? "primary" : row.status === "refused" ? "error" : "info"}>{row.status}</Button>
                                :
                                <>
                                    <Stack sx={{justifyContent:"center"}} direction="row">
                                        <IconButton  onClick={()=>{handleJoin(row.uid)}}>
                                            <CheckIcon color="primary"/>
                                        </IconButton>
                                        <IconButton onClick={()=>{handleRefuse(row.uid)}}>
                                            <ClearIcon color="secondary"/>
                                        </IconButton>
                                    </Stack>
                                </>
                                }
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                <Typography variant="subtitle2" fontWeight={500} gutterBottom component="div">
                                    {t('homePendingCollapseTitle')}
                                </Typography>
                                {(row.status === "joined" || row.status === "refused") && 
                                    <>
                                        <Button onClick={handleStatusChange} disabled={statusChange === true ? true : false} variant='outlined' color='primary' size="small" sx={{mt:1,mb:1}}>Change Status</Button> 
                                        {
                                            statusChange === true &&
                                            <>
                                                <Typography variant="subtitle2" fontWeight={500}>Are you joining the event?</Typography>
                                                <Stack direction="row">
                                                    <IconButton onClick={()=>{handleJoin(row.uid)}}>
                                                        <CheckIcon color="primary"/>
                                                    </IconButton>
                                                    <IconButton onClick={()=>{handleRefuse(row.uid)}}>
                                                        <ClearIcon color="secondary"/>
                                                    </IconButton>
                                                </Stack>
                                            </>
                                        }

                                        <Divider/>
                                    </>
                                }
                                <Grid container m={1}>
                                    {Object.keys(row).map((key, i) => (
                                        (row[key] !== "" && key !== "status"  && key !== "trainingType" && key !== "team" && key !== "athlete"
                                        && key !== "staff" && key !== "trainingType" && key !== "eventId" && key !== "addedBy" && key !== "uid" &&
                                        key !== "recurrent") &&
                                        <>
                                        <Grid item xs={6} md={4}>
                                            <Grid container direction="row" justifyContent='space-between' paddingLeft={2}>
                                                <Typography variant="subtitle2">{key.split(/(?=[A-Z])/).join(" ").toLocaleLowerCase()}</Typography>
                                                <Typography variant="subtitle2" color="primary">
                                                {key === 'staff' || key === "team" || key === "athlete" ? 
                                                ""
                                                :
                                                row[key]}</Typography>
                                            </Grid>
                                        </Grid>
                                        </>
                                    ))
                                    }
                                </Grid>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
            </>
        )

    }

    return (
        <>
            <TableContainer component={Paper} sx={{maxHeight:"400px", width:{xs:'90vw',md:'100%'},mt:2}}>
                <Table size="small">
                    <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell align="center">{t('homePendingCellOne')}</TableCell>
                        <TableCell align="center">{t('homePendingCellTwo')}</TableCell>
                        <TableCell align="center">{t('homePendingCellThree')}</TableCell>
                        <TableCell align="center">{t('homePendingCellFour')}</TableCell>
                        <TableCell align="center">{t('homePendingCellFive')}</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row) => (
                        <Row key={row.eventId} row={row} />
                    ))}
                    
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default PendingInvites


import React from 'react'
import Club from '../components/Club'

const ClubPage = (props) => {
    return (
        <>
            <Club clubData={props.clubData}/>
        </>
    )
}

export default ClubPage
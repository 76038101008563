import React from 'react'
import Home from '../components/Home'

const HomePage = (props) => {
    return (
        <>
            <Home userData={props.userData}/>
        </>
    )
}

export default HomePage
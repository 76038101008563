import React, {useState,useEffect} from 'react';

import {FormControl, Box, Divider, Button, TextField, MenuItem, InputLabel, OutlinedInput, ListItemText, 
    Select, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton,
    Typography  } from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import { getDatabase, ref, push, update} from "firebase/database";
import storage from '../../../Firebase/firebase';
import { ref as refS, uploadBytesResumable, getDownloadURL } from "firebase/storage" 

import { useTranslation } from 'react-i18next';

export default function TeamDialog(props) {
    const { t } = useTranslation();

    const [clubId, setClubId] = useState("")
    const [sportSectionsFull, setSportSectionsFull] = useState([]);

    const [name, setName] = useState("");
    const [sportSection, setSportSection] = useState("");
    const [athletes, setAthletes] = useState([]);
    const [staff, setStaff] = useState([]);

    const [sportSectionArr, setSportSectionArr] = useState([]);
    const [athletesArr, setAthletesArr] = useState([]);
    const [staffArr, setStaffArr] = useState([]);

    const [image, setImage] = useState("");
    const [textImage, setTextImage] = useState(t('administrationTextTwo'));

    const [loading, setLoading] = useState(false)
    const [buttonText, setButtonText] = useState(t('administrationButtonFour'))

    const [errorName, setErrorName] = useState(false)
    const [errorSection, setErrorSection] = useState(false)
    const [errorAthletes, setErrorAthletes] = useState(false)

    useEffect(()=>{
        setName("")
        setSportSection("")
        setAthletes([])
        setStaff([])
        setButtonText(t('administrationButtonFour'))
        setLoading(false)
        setTextImage(t('administrationTextTwo'))
        setImage("")
    },[props.openDialog])


    useEffect(()=>{
        if(props.clubId){
            setClubId(props.clubId)
        }

        if(props.sportSections){
            let arr = Object.values(props.sportSections)
            let nameArr = []
            for(let i = 0; i < arr.length; i++){
                nameArr.push(arr[i].name)
            }
            setSportSectionArr(nameArr)

            let fullArr =props.sportSections
            const newSS = []

            let keys = Object.keys(fullArr)
            let values = Object.values(fullArr)

            for(let i = 0; i < values.length; i++){
                let obj = values[i]
                obj.uid = keys[i]
                newSS.push(obj)
            }
            setSportSectionsFull(newSS)
        }

        if(props.athletes){
            setAthletesArr(props.athletes)
        }

        if(props.staff){
            setStaffArr(props.staff)
        }
    },[props.clubId,props.sportSections,props.athletes,props.staff])

    const validateName = () => {
        setErrorName(false)
        let isValid = true
        if (name !== ''){

        } else {
            isValid = false
            setErrorName(true)
        } 
        return isValid
    }

    const handleChangeAthletes = (event) => {
        const {
          target: { value },
        } = event;
        setAthletes(
          typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeStaff = (event) => {
        const {
          target: { value },
        } = event;
        setStaff(
          typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleImage = (event) => {
        if (event.target.files && event.target.files[0]) {
            let value = event.target.files[0]
            setImage(value);
            setTextImage(t('administrationTextThree'))
          }
    };

    const saveData = () => {
        if(validateName() && athletes.length > 0 && sportSection!==""){
            setErrorAthletes(false)
            setErrorSection(false)

            setLoading(true)
            setButtonText(t('administrationButtonFour'))

            var resultSportSection = sportSectionsFull.find(item => item.name === sportSection);

            const db = getDatabase();

            push(ref(db, 'clubs/'+clubId+'/teams'), {
                name: name,
                sportSection: resultSportSection.uid,
                // athletes: athletes,
                // staff: staff,
            }).then((snap)=>{
                setLoading(false)
                setButtonText(t('administrationButtonSix'))
                props.setMessageSnack(t('administrationSnackFour'))
                props.setOpenSnack(true)
                props.handleClickClose()

                const key = snap.key 

                athletes.forEach(uid=>{
                    const updates = {};
                    updates['clubs/'+clubId+'/teams/'+key+"/athletes/"+uid] = uid
                    update(ref(db), updates)
                })

                staff.forEach(uid=>{
                    const updates = {};
                    updates['clubs/'+clubId+'/teams/'+key+"/staff/"+uid] = uid
                    update(ref(db), updates)
                })

                athletes.forEach(uid=>{
                    const updates = {};
                    updates['users/'+uid+'/team/'+key] = key
                    update(ref(db), updates)
                })

                athletes.forEach(uid=>{
                    const updates = {};
                    updates['users/'+uid+'/sportSection/'+resultSportSection.uid] = resultSportSection.uid
                    update(ref(db), updates)
                })

                if(staff.length>0){
                    staff.forEach(uid=>{
                        const updates = {};
                        updates['users/'+uid+'/team/'+key] = key
                        update(ref(db), updates)
                    })
    
                    staff.forEach(uid=>{
                        const updates = {};
                        updates['users/'+uid+'/sportSection/'+resultSportSection.uid] = resultSportSection.uid
                        update(ref(db), updates)
                    })
                }

                if(typeof image === 'object' && image && image!=="") {
                    const storageRef = refS(storage, `/files/teams/${key}/image`);
                    const uploadTask = uploadBytesResumable(storageRef, image);

                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                        },
                        (err) => console.log(err),
                        () => {
                        // download url
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            const updates = {};
                            updates['clubs/'+clubId+'/teams/'+key+'/image'] = url
                            update(ref(db), updates)
                        })
                    })
                        
                }

            }).then(()=>{
                athletes.forEach(uid=>{
                    const updates = {};
                    updates['clubs/'+clubId+'/sportSections/'+resultSportSection.uid+'/athletes/'+uid] = uid
                    update(ref(db), updates)
                })

                if(staff.length>0){
                    staff.forEach(uid=>{
                        const updates = {};
                        updates['clubs/'+clubId+'/sportSections/'+resultSportSection.uid+'/staff/'+uid] = uid
                        update(ref(db), updates)
                    })
                }
            })

        } else if(!validateName()){
            setLoading(false)
            setButtonText(t('administrationButtonFour'))
        } else if(sportSection===""){
            setErrorSection(true)
            setLoading(false)
            setButtonText(t('administrationButtonFour'))
        } else if(!athletes.length>0){
            setErrorAthletes(true)
            setLoading(false)
            setButtonText(t('administrationButtonFour'))
        }
    }

    return (
        <div>
        <Dialog open={props.openDialog} onClose={props.handleClickClose}>
            <DialogTitle color="black" variant="subtitle1" fontWeight={500} sx={{textDecoration: 'underline',textDecorationColor:'#54cdb7', textDecorationThickness:'2px'}}>{t('administrationButtonSeven')}</DialogTitle>
            <Divider/>
            <DialogContent>
            <DialogContentText  variant="subtitle1" fontWeight={500} >
                {t('administrationTextFour')}
            </DialogContentText>
            <Box align="center" sx={{mt:2}}>
                <TextField
                    required
                    size="small"
                    label={t('administrationLabelFour')}
                    type="text"
                    value={name}
                    onChange={(e)=>{setName(e.target.value)}}
                    error={errorName}
                    helperText={errorName ? t('administrationErrorOne') : ""}
                    sx={{width:"80%"}}
                />
                <TextField
                    required
                    size="small"
                    select
                    label={t('administrationLabelThree')}
                    value={sportSection}
                    error={errorSection}
                    onChange={(e)=>{setSportSection(e.target.value)}}
                    sx={{width:"80%", mt:2}}
                >
                {sportSectionArr.map((option) => (
                <MenuItem key={option} value={option} color="secondary">
                {option}
                </MenuItem>
                ))}
                </TextField>
                <FormControl sx={{ width: "80%",maxWidth:"80vw",mt:2 }}>
                    <InputLabel id="demo-multiple-checkbox-label">{t('administrationCellEight')}</InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        size="small"
                        notched={true}
                        value={athletes}
                        onChange={handleChangeAthletes}
                        input={<OutlinedInput />}
                        // renderValue={(selected) => selected.join(', ')}
                        renderValue={
                            (selected) => 
                                Object.values(athletesArr).filter( name => selected.includes(name.uid) )
                                    .map( record => (record.firstName + " " + record.lastName).toString() )
                                    .join(", ")
                        }
                        error={errorAthletes}
                        sx={{color:'black',mt:1, "&:hover": {
                        "&& fieldset": {
                            borderColor: "#54cdb7"
                        }
                        }}}
                    >
                    {athletesArr.map((athlete) => (
                        <MenuItem key={athlete.uid} value={athlete.uid}>
                        <Checkbox checked={athletes.indexOf(athlete.uid) > -1} />
                        <ListItemText primary={athlete.firstName + " " + athlete.lastName} />
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                <FormControl sx={{ width: "80%",maxWidth:"80vw",mt:2 }}>
                    <InputLabel id="demo-multiple-checkbox-label">{t('administrationCellNine')}</InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        size="small"
                        placeholder='label'
                        notched={true}
                        value={staff}
                        onChange={handleChangeStaff}
                        input={<OutlinedInput />}
                        renderValue={
                            (selected) => 
                                Object.values(staffArr).filter( name => selected.includes(name.uid) )
                                    .map( record => record.firstName + " " + record.lastName )
                                    .join(", ")
                        }
                        sx={{color:'black',mt:1, "&:hover": {
                        "&& fieldset": {
                            borderColor: "#54cdb7"
                        }
                        }}}
                    >
                    {staffArr.map((person) => (
                        <MenuItem key={person.uid} value={person.uid}>
                        <Checkbox checked={staff.indexOf(person.uid) > -1} />
                        <ListItemText primary={person.firstName + " " + person.lastName} />
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                {/* <input accept="image/*" style={{display:"none"}} id="icon-button-file" type="file" onChange={(event) => handleImage(event)}/>
                <label htmlFor="icon-button-file">
                    <Button aria-label="upload picture" component="span" variant="contained" color="secondary" sx={{mt:2,width: "80%"}}>Upload Team Logo</Button>
                </label> */}
                <Typography variant="subtitle2" color="secondary" sx={{mt:2}}>{textImage}</Typography>
                <input accept="image/*" style={{display:"none"}} id="icon-button-file" type="file" onChange={(event) => handleImage(event)}/>
                    <label htmlFor="icon-button-file">
                        <IconButton color="primary" aria-label="upload picture" component="span">
                            <AddAPhotoIcon sx={{ width: 30, height: 30 }} 
                            // src={prevImg !== "" ? prevImg : typeof image === "string" ? image : null} />
                            />
                    </IconButton>
                </label>
            </Box>
            </DialogContent>
            <DialogActions sx={{justifyContent:"center"}}>
                <Button onClick={props.handleClickClose} variant="contained" size="small" sx={{color:"#FFF", width:'30%', backgroundColor:"gray", ":hover":{backgroundColor:"#303030"}}}>{t('administrationButtonFive')}</Button>
                <LoadingButton
                    onClick={saveData}
                    loading={loading}
                    variant="contained"
                    color="primary" 
                    size="small" 
                    sx={{color:"#FFF", width:'30%'}}
                >
                    {buttonText}
                </LoadingButton>
            </DialogActions>
        </Dialog>
        </div>
    );
}
import React, {useEffect, useState} from 'react'

import { Button, Grid, TextField, MenuItem, Snackbar, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import { Send } from '@mui/icons-material';

import { useTranslation } from 'react-i18next'

import {createUserWithEmailAndPassword, sendEmailVerification} from 'firebase/auth'
import { authSecond } from '../../../Firebase/firebase';
import { getDatabase, ref, set, update } from "firebase/database";

import { plans } from '../../../utils/plans_data';

import moment from 'moment-timezone';

const InvitationForm = (props) => {
    const { t } = useTranslation();

    const [userRole, setUserRole] = useState("");
    const [uidMaster, setUidMaster] = useState("");
    const [clubId, setClubId] = useState("");
    const [timezone, setTimezone] = useState("");
    const [userClubInvites, setUserClubInvites] = useState("");
    const [totalClubInvites, setTotalCountInvites] = useState("")
    const [clubType, setClubType] = useState("")
    const [disabled, setDisabled] = useState(false)

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [role, setRole] = useState("Athlete");
    const [team, setTeam] = useState("");
    const [athlete, setAthlete] = useState("")

    const [rolesArr, setRolesArr] = useState(["Athlete","Coach","Parent"])

    const [teamsArr, setTeamsArr] = useState([])
    const [athletesArr, setAthletesArr] = useState([])

    const [errorEmail, setErrorEmail] = useState(false)
    const [errorFirstName, setErrorFirstName] = useState(false)
    const [errorLastName, setErrorLastName] = useState(false)

    const [loading, setLoading] = useState(false)
    const [buttonText, setButtonText] = useState(t("inviteButtonTextOne"))
    const [openSnack, setOpenSnack] = useState(false)
    const [snackMessage, setSnackMessage] = useState(t("inviteSnackOne"))

    useEffect(()=>{
        if(props.userData.clubId){
            setClubId(props.userData.clubId)
        }
        if(props.userData.uidMaster){
            setUidMaster(props.userData.uidMaster)
        }
        if(props.userData.timezone){
            setTimezone(props.userData.timezone)
        }
        if(props.userData.userClubInvites){
            setUserClubInvites(props.userData.userClubInvites)
        }
        if(props.userData.totalClubInvites){
            setTotalCountInvites(props.userData.totalClubInvites)
        }
        if(props.userData.clubType){
            setClubType(props.userData.clubType)
        }
        if(props.userData.role){
            setUserRole(props.userData.role)

            if(props.userData.role === "Athlete"){
                setRole("Parent")
            }
        }
        if(props.athletes){
            setAthletesArr(props.athletes)
        }

        if(props.userData.teams){
           let teams = props.userData.teams
           let keys = Object.keys(teams)
           let val = Object.values(teams)
           const newTeams = [];

           for(let i = 0 ; i < val.length; i++){
            val[i].uid = keys[i]
            newTeams.push(val[i])
           }
          setTeamsArr(newTeams)
        }

    },[props.userData.userClubInvites,props.userData.clubId,props.userData.uidMaster,props.userData.role,
        props.userData.teams,props.athletes,props.userData.timezone,props.userData.totalClubInvites,
        props.userData.clubType])
    
    useEffect(()=>{
        const subscriptionPlan = plans.find(plan => plan.name === clubType);
        if(subscriptionPlan){
            if(subscriptionPlan.accounts === totalClubInvites.toString()){
                setDisabled(true)
            }
        }
    },[clubType, totalClubInvites])
    
    const closeSnack = () => {
        setOpenSnack(false)
    };

    const validateEmail= () => {
        setErrorEmail(false)
        let isValid = true
        if (email !== ''){
          if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            isValid = false
            setErrorEmail(true)
          }
        } else {
            isValid = false
            setErrorEmail(true)
        }
        return isValid
    }

    const validateName = () => {
        setErrorFirstName(false)
        setErrorLastName(false)
        let isValid = true
        if (firstName !== '' && lastName !== ''){

        } else if (firstName === ''){
            isValid = false
            setErrorFirstName(true)
        } else if (lastName === ''){
            isValid = false
            setErrorLastName(true)
        }
        return isValid
    }

    const sendInvite = () => {
        setLoading(true)
        if(validateEmail() && validateName()) {
            let password = "password";
            createUserWithEmailAndPassword(authSecond, email, password)
              .then((userCredential) => {
                // const user = userCredential.user;
                // authSecond.currentUser.sendEmailVerification(emailTemplate) 
                sendEmailVerification(authSecond.currentUser) 
                let uid = authSecond.currentUser.uid
                const db = getDatabase();
                set(ref(db, 'users/' + uid), {
                    email: email,
                    role: role,
                    clubId: clubId,
                    invitedBy: uidMaster,
                    invitedFirstSignIn: true,
                    firstName: firstName,
                    lastName: lastName,
                    dateCreated: moment().tz(timezone).format('DD/MM/YYYY'),
                    
                }).then(()=>{
                authSecond.signOut();
                setLoading(false)
                setSnackMessage(t("inviteSnackOne"))
                setOpenSnack(true)

                if(userClubInvites!== "" && userClubInvites > 0 ) {
                    let inv = userClubInvites + 1
                    const updates = {};
                    updates['users/'+uidMaster+'/clubInvites'] = inv
                    update(ref(db), updates)
                } else {
                    const updates = {};
                    updates['users/'+uidMaster+'/clubInvites'] = 1
                    update(ref(db), updates)
                }

                if(totalClubInvites!== "" && totalClubInvites > 0 ) {
                    let inv = totalClubInvites + 1
                    const updates = {};
                    updates['clubs/'+clubId+'/clubInvites'] = inv
                    update(ref(db), updates)
                } else {
                    const updates = {};
                    updates['clubs/'+clubId+'/clubInvites'] = 1
                    update(ref(db), updates)
                }

                if(role==="Parent" && userRole === "Athlete"){
                    const updates = {};
                    updates['users/'+uidMaster+'/parent/'+uid] = uid
                    update(ref(db), updates)

                    const parentUpdates = {};
                    parentUpdates['users/'+uid+'/child/'+uidMaster] = uidMaster
                    update(ref(db), parentUpdates)
                }

                if(role==="Parent" && (userRole === "Club Manager" || userRole === "Coach" || userRole === "Owner") && athlete){
                    const updates = {};
                    updates['users/'+uid+'/child/'+athlete.uid] = athlete.uid
                    update(ref(db), updates)

                    const athUpdate = {}
                    athUpdate['users/'+athlete.uid+'/parent/'+uid] = uid
                    update(ref(db), athUpdate)
                }

                if(role === "Athlete"){
                    const updates = {};
                    updates['users/'+uid+'/team/'+team.uid] = team.uid
                    updates['users/'+uid+'/sportSection/'+team.sportSection] = team.sportSection
                    update(ref(db), updates)

                    const teamsUpdates = {};
                    teamsUpdates['clubs/'+clubId+'/teams/'+team.uid+'/athletes/'+uid] = uid
                    update(ref(db), teamsUpdates)

                    const sectionsUpdates = {};
                    sectionsUpdates['clubs/'+clubId+'/sportSections/'+team.sportSection+'/athletes/'+uid] = uid
                    update(ref(db), sectionsUpdates)
                }

                if(role === "Coach"){
                    const updates = {};
                    updates['users/'+uid+'/team/'+team.uid] = team.uid
                    updates['users/'+uid+'/sportSection/'+team.sportSection] = team.sportSection
                    update(ref(db), updates)

                    const teamsUpdates = {};
                    teamsUpdates['clubs/'+clubId+'/teams/'+team.uid+'/staff/'+uid] = uid
                    update(ref(db), teamsUpdates)

                    const sectionsUpdates = {};
                    sectionsUpdates['clubs/'+clubId+'/sportSections/'+team.sportSection+'/staff/'+uid] = uid
                    update(ref(db), sectionsUpdates)
                }

                }).then(()=>{
                    setEmail("")
                    setFirstName("")
                    setLastName("")
                })
              })
              .catch((error) => {
                setLoading(false)
                if(error.message==="Firebase: Error (auth/email-already-in-use)."){
                    setSnackMessage(t("inviteSnackTwo"))
                } else {
                    setSnackMessage(error.message)
                }
                setOpenSnack(true)
              });
        } else {
            setLoading(false)
        }
    };
    
    return (
        <>
            {disabled ? 
                <>
                    <Typography variant="subtitle2">{t("inviteTitleOne")}</Typography>
                    <Typography variant="subtitle2" color="primary">{t("inviteTitleTwo")}</Typography>
                </>
                :
                <Grid container spacing={3} align="center">
                    <Grid item xs={12} md={12}>
                        <TextField
                                required
                                size="small"
                                label="Email"
                                type="email"
                                value={email}
                                onChange={(e)=>{setEmail(e.target.value)}}
                                error={errorEmail}
                                helperText={errorEmail ? t("inviteErrorOne") : ""}
                                sx={{width: {xs: "80%", md: "40%"}}}
                            />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField
                                required
                                size="small"
                                label={t("inviteLabelOne")}
                                type="text"
                                value={firstName}
                                onChange={(e)=>{setFirstName(e.target.value)}}
                                error={errorFirstName}
                                helperText={errorFirstName ? t("inviteErrorTwo") : ""}
                                sx={{width: {xs: "80%", md: "40%"}}}
                            />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField
                                required
                                size="small"
                                label={t("inviteLabelTwo")}
                                type="text"
                                value={lastName}
                                onChange={(e)=>{setLastName(e.target.value)}}
                                error={errorLastName}
                                helperText={errorFirstName ? t("inviteErrorTwo") : ""}
                                sx={{width: {xs: "80%", md: "40%"}}}
                            />
                    </Grid>
                    {userRole === "Club Manager" || userRole === "Coach" || userRole === "Owner" ?
                    <>
                        <Grid item xs={12} md={12}>
                            <TextField
                                required
                                size="small"
                                select
                                label={t("inviteLabelThree")}
                                value={role}
                                defaultValue={"Athlete"}
                                onChange={(e)=>{setRole(e.target.value)}}
                                sx={{width: {xs: "80%", md: "40%"}}}
                            >
                            {rolesArr.map((option) => (
                            <MenuItem key={option} value={option} color="secondary">
                            {option}
                            </MenuItem>
                            ))}
                            </TextField>
                        </Grid>
                        {(role === "Athlete" || role === "Coach") && teamsArr.length>0 ? 
                            <Grid item xs={12} md={12}>
                                <TextField
                                    size="small"
                                    select
                                    label={t("inviteLabelFour")}
                                    value={team}
                                    onChange={(e)=>{setTeam(e.target.value)}}
                                    sx={{width: {xs: "80%", md: "40%"}}}
                                >
                                {teamsArr.map((option) => (
                                <MenuItem key={option.uid} value={option} color="secondary">
                                {option.name}
                                </MenuItem>
                                ))}
                                </TextField>
                            </Grid>
                        : role === "Parent" ?
                        <>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    size="small"
                                    select
                                    label={t("inviteLabelFive")}
                                    value={athlete}
                                    onChange={(e)=>{setAthlete(e.target.value)}}
                                    sx={{width: {xs: "80%", md: "40%"}}}
                                >
                                {athletesArr.map((option) => (
                                <MenuItem key={option.uid} value={option} color="secondary">
                                {option.firstName+" "+option.lastName}
                                </MenuItem>
                                ))}
                                </TextField>
                            </Grid>
                        </>
                        :
                        <></>
                        }
                    </>
                    : userRole === "Athlete" ? 
                    <>
                        <Grid item xs={12} md={12}>
                            <TextField
                                    required
                                    size="small"
                                    label={t("inviteLabelThree")}
                                    type="text"
                                    value={"Parent"}
                                    sx={{width: {xs: "80%", md: "40%"}}}
                                    disabled
                                />
                        </Grid>
                    </>
                    :
                    <></>
                }
                <Grid item xs={12} md={12} sx={{mt:2}} align="center">
                    <LoadingButton
                            onClick={sendInvite}
                            endIcon={<Send/>}
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                            color="secondary"
                            sx={{width: {xs: "80%", md: "40%"}}} 
                        >
                            {buttonText}
                    </LoadingButton>
                </Grid>
            </Grid>
        }
            <Snackbar
                open={openSnack}
                autoHideDuration={3000}
                onClose={closeSnack}
                message={snackMessage}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
        </>
    )
}

export default InvitationForm


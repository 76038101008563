import React, {useEffect, useState} from 'react';

import { Box, Button, IconButton, Dialog, Divider, DialogContent, DialogContentText, DialogTitle, Grid, TextField,
        FormControl, InputLabel, Select, ListItemText, Checkbox, MenuItem, OutlinedInput, Typography  } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

import { getDatabase, ref, push, update} from "firebase/database";

import { useTranslation } from 'react-i18next';

export default function Membership(props) {
    const { t } = useTranslation();

    const [clubId, setClubId] = useState("")

    const [membershipValue, setMembershipValue] = useState("");

    const [loading, setLoading] = useState(false)
    const [buttonText, setButtonText] = useState(t('administrationButtonFour'))
    const [errorMembership, setErrorMembership] = useState(false)

    useEffect(()=>{
        setButtonText(t('administrationButtonFour'))
    },[props.openDialog])

    useEffect(()=>{
        if(props.administrationData.clubId){
            setClubId(props.administrationData.clubId)
        }
        if(props.administrationData.membershipValue){
            setMembershipValue(props.administrationData.membershipValue)
        }
    },[props.administrationData,props.administrationData.uidMaster,props.administrationData.clubId,props.administrationData.membershipValue])

    const validateMV = () => {
        setErrorMembership(false)
        let isValid = true
        if (membershipValue !== ''){

        } else {
            isValid = false
            setErrorMembership(true)
        } 
        return isValid
    }

    const handleSave = () => {
        if(validateMV())
        {
            setErrorMembership(false)
            setLoading(true)
            setButtonText(t('administrationButtonFour'))

            const db = getDatabase()

            const updates = {};
            updates['clubs/'+clubId+'/membershipValue'] = membershipValue
            update(ref(db), updates).then(()=>{
                setLoading(false)
                setButtonText(t('administrationButtonSix'))
                props.setMessageSnack("Membership value updated")
                props.setOpenSnack(true)
                props.handleClickClose()
            })
        } else {
            setLoading(false)
            setButtonText(t('administrationButtonFour'))
        }
    };

    return (
        <div>
        <Dialog open={props.openDialog} onClose={props.handleClickClose}>
            <DialogTitle color="black" variant="subtitle1" fontWeight={500} sx={{textDecoration: 'underline',textDecorationColor:'#54cdb7', textDecorationThickness:'2px'}}>{t('administrationButtonTwo')}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={props.handleClickClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <Divider/>
            <DialogContent>
            <DialogContentText  variant="subtitle1" fontWeight={500} >
                {t('administrationTextSeven')}
            </DialogContentText>
            <Box align="center" sx={{mt:1}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" color="primary">{t('administrationTextEight')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            size="small"
                            label={t('administrationLabelThirteen')}
                            type="number"
                            value={membershipValue}
                            onChange={(e)=>{setMembershipValue(e.target.value)}}
                            error={errorMembership}
                            helperText={errorMembership ? t('administrationErrorFour') : ""}
                            sx={{width:"80%", textAlign:'center'}}
                            inputProps={{min: 0, style: { textAlign: 'center' }}}
                        />
                    </Grid>
                    <Grid item xs={6} sx={{mt:2, width:'80%'}} align="center">
                        <Button onClick={props.handleClickClose} variant="contained" size="small" sx={{color:"#FFF", width:'50%', backgroundColor:"gray", ":hover":{backgroundColor:"#303030"}}}>{t('administrationButtonFive')}</Button>
                    </Grid>
                    <Grid item xs={6} sx={{mt:2, width:'80%'}} align="center">
                        <LoadingButton
                            onClick={handleSave}
                            loading={loading}
                            variant="contained"
                            color="primary" 
                            size="small" 
                            sx={{color:"#FFF", width:'50%'}}
                        >
                            {buttonText}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
            </DialogContent>
        </Dialog>
        </div>
    );
}
import { getDatabase, ref, update } from "firebase/database";

import { getCheckoutUrlSubscription } from './StripeGetUrl';

const stripe = require('stripe')('sk_test_51MGiQGLmmRuGAAh8qN9v1UhR0ouJWQYg5Owc0xe2v6c4ChViapTOneVD7gPlsnGAl2GHnKgm5lXzj0ekoSTDtVya00KTzpRzbi');

export const createManagerAccount = async (uid,clubId) => {
    const account = stripe.accounts.create({
        type: 'express',
      }).then((result)=>{
        let id = result.id

        const db = getDatabase()
        const updates = {};
        updates['users/'+uid+'/stripeAccount'] = id
        updates['clubs/'+clubId+'/stripeAccount'] = id
        update(ref(db), updates)

        const accountLink =  stripe.accountLinks.create({
            account: id,
            refresh_url: `${window.location.origin}/home`,
            return_url: `${window.location.origin}/home`,
            type: 'account_onboarding',
        }).then((result_link)=>{
            window.location.href = result_link.url
        })
      });
};

export const paySubscription = async (page, price) => {
    const checkout_url =  getCheckoutUrlSubscription(page,price).then((result)=>{
        window.location.href = result
    })
};

export const updateSubscription = async (priceId,subscriptionId,subscriptionItemId) => {
    const subscription = await stripe.subscriptions.update(
        subscriptionId,
        {
          items: [
            {
              id: subscriptionItemId,
              price: priceId,
            },
          ],
          proration_behavior: 'always_invoice'
        }
      );
};

export const payMembership = async (destinationAcc, membershipValue, uid, date) => {

    const value = membershipValue * 100;

    const product = await stripe.products.create({
        name: 'membership'
    })

    const price =  await stripe.prices.create({
        unit_amount: value,
        currency: 'ron',
        product: product.id,
    });

    // const checkout_url =  getCheckoutUrl(price.id).then((result)=>{
    //     console.log('result',result)
    //     window.location.href = result
    // })
    
    // ----

    const session =  stripe.checkout.sessions.create({
        success_url: `${window.location.origin}/payment`,
        line_items: [
            {price: price.id, quantity: 1},
        ],
        payment_intent_data: {
            transfer_data: {
            destination: destinationAcc,
            },
        },
        mode: 'payment',
    }).then((result)=>{
        const db = getDatabase();

        const updates = {};
        updates['users/'+uid+'/payments/subscription/'+date] = 'online'
        update(ref(db), updates)

        window.location.href = result.url
    });
};


export const payEvent = async (destinationAcc, cost, uid, eventId) => {

    const value = cost * 100;

    const product = await stripe.products.create({
        name: 'event'
    })

    const price =  await stripe.prices.create({
        unit_amount: value,
        currency: 'ron',
        product: product.id,
    });

    const session =  stripe.checkout.sessions.create({
        success_url: `${window.location.origin}/payment`,
        line_items: [
            {price: price.id, quantity: 1},
        ],
        payment_intent_data: {
            transfer_data: {
            destination: destinationAcc,
            },
        },
        mode: 'payment',
    }).then((result)=>{
        const db = getDatabase();

        const updates = {};
        updates['users/'+uid+'/payments/events/'+eventId] = 'online'
        update(ref(db), updates)

        window.location.href = result.url
    });
};

export const payEquipment = async (destinationAcc, cost, uid, equipmentId) => {

    const value = cost * 100;

    const product = await stripe.products.create({
        name: 'equipment'
    })

    const price =  await stripe.prices.create({
        unit_amount: value,
        currency: 'ron',
        product: product.id,
    });

    const session =  stripe.checkout.sessions.create({
        success_url: `${window.location.origin}/payment`,
        line_items: [
            {price: price.id, quantity: 1},
        ],
        payment_intent_data: {
            transfer_data: {
            destination: destinationAcc,
            },
        },
        mode: 'payment',
    }).then((result)=>{
        const db = getDatabase();

        const updates = {};
        updates['users/'+uid+'/payments/equipments/'+equipmentId] = 'online'
        update(ref(db), updates)

        window.location.href = result.url
    });
};
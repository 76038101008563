import React, {useState, useEffect} from 'react'

import { Grid,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Stack, Box, Collapse, Typography,
     Divider,   MenuItem,
     FormControl,
     Select,
     InputLabel, } from '@mui/material'
import { Link } from 'react-router-dom';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { getDatabase, ref, update} from "firebase/database";

import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone';

const InvitesComponent = (props) => {
    const { t } = useTranslation();
    
    const [uidMaster, setUidMaster] = useState("");
    const [timezone, setTimezone] = useState("");

    const [invites, setInvites] = useState([]);
    const [rows, setRows] = useState([]);

    const [selectedPeriod, setSelectedPeriod] = useState('all'); // Default to show all events

    useEffect(()=>{
        if(props.calendarData.uidMaster){
            setUidMaster(props.calendarData.uidMaster)
        }
        if(props.calendarData.timezone){
            setTimezone(props.calendarData.timezone)
        }
        if(props.calendarData.invites){
            let inv = props.calendarData.invites
            let keys = Object.keys(inv)
            let val = Object.values(inv)
            const newInv = [];

            let locations = props.calendarData.locations
            let locKeys = Object.keys(locations)
            let locVals = Object.values(locations)

            for(let i = 0 ; i < val.length; i++){
                val[i].uid = keys[i];
                if(val[i].locationType === 'club'){
                    for(let j = 0; j < locVals.length; j++){
                        if(locKeys[j] === val[i].location){
                            val[i].location = locVals[j].name;
                            break; 
                        }
                    }
                }
                let calculateStatus = ""
                if(val[i].endDate) {
                    calculateStatus = val[i].endDate
                } else if (val[i].date){
                    calculateStatus = val[i].date
                }
                if(calculateStatus){
                    const momentDate = moment.tz(calculateStatus, 'DD/MM/YYYY', props.calendarData.timezone);
                    const momentNow = moment.tz(props.calendarData.timezone);

                    if (momentDate.isAfter(momentNow, 'day')) {
                        // console.log('The date is in the future');
                    } else if (momentDate.isBefore(momentNow, 'day')) {
                        // console.log('The date is in the past');
                        val[i].status = "passed"
                    } else {
                        // console.log('The date is today');
                    }
                }
                newInv.push(val[i]);
               
            }

            const sortedEvents = newInv.sort((a, b) => {
                const statusOrder = {
                    pending: 0,
                    joined: 1,
                    refused: 2,
                    passed: 3,
                };
                const statusComparison = statusOrder[a.status] - statusOrder[b.status];
                if (statusComparison !== 0) {
                    return statusComparison;
                }
                
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                const dateComparison = dateA - dateB;
                if (dateComparison !== 0) {
                    return dateComparison;
                }
                
                const hourA = a.startHour;
                const hourB = b.startHour;
                return hourA.localeCompare(hourB);
            });
            setInvites(sortedEvents)
            setRows(sortedEvents)
            updateRows(selectedPeriod, sortedEvents);
        }
    },[props.calendarData.uidMaster,props.calendarData.invites,props.calendarData.locations,props.calendarData.timezone, selectedPeriod])

    const updateRows = (period, events) => {
        const currentDate = moment.tz(new Date(), props.calendarData.timezone);
      
        let filteredEvents = events.filter((event) => {
          const startDateTime = moment.tz(event.date, 'DD/MM/YYYY', props.calendarData.timezone);
      
          switch (period) {
            case 'today':
              if (startDateTime.isSame(currentDate, 'day')) {
                return true;
              } else if (event.endDate) {
                const endDateTime = moment.tz(event.endDate, 'DD/MM/YYYY', props.calendarData.timezone);
                return startDateTime.isBefore(currentDate, 'day') && endDateTime.isSameOrAfter(currentDate, 'day');
              }
              break;
            case 'thisWeek':
              if (startDateTime.isSame(currentDate, 'week')) {
                return true;
              } else if (event.endDate) {
                const endDateTime = moment.tz(event.endDate, 'DD/MM/YYYY', props.calendarData.timezone);
                return startDateTime.isBefore(currentDate, 'week') && endDateTime.isSameOrAfter(currentDate, 'week');
              }
              break;
            case 'thisMonth':
              if (startDateTime.isSame(currentDate, 'month')) {
                return true;
              } else if (event.endDate) {
                const endDateTime = moment.tz(event.endDate, 'DD/MM/YYYY', props.calendarData.timezone);
                return startDateTime.isBefore(currentDate, 'month') && endDateTime.isSameOrAfter(currentDate, 'month');
              }
              break;
            case 'thisYear':
              if (startDateTime.isSame(currentDate, 'year')) {
                return true;
              } else if (event.endDate) {
                const endDateTime = moment.tz(event.endDate, 'DD/MM/YYYY', props.calendarData.timezone);
                return startDateTime.isBefore(currentDate, 'year') && endDateTime.isSameOrAfter(currentDate, 'year');
              }
              break;
            default:
              return true; 
          }
        });
        setRows(filteredEvents);
      };
      
      
    
    const handlePeriodChange = (event) => {
        setSelectedPeriod(event.target.value);
    };

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = useState(false);
        const [statusChange, setStatusChange] = useState(false);

        const handleStatusChange = () => {
            setStatusChange(true);
        };

        const handleJoin = (rowId) => {
            const db = getDatabase()
            const updates = {};
            updates['users/'+uidMaster+'/invites/'+rowId+"/status"] = "joined"
            update(ref(db), updates)
        }

        const handleRefuse = (rowId) => {
            const db = getDatabase()
            const updates = {};
            updates['users/'+uidMaster+'/invites/'+rowId+"/status"] = "refused"
            update(ref(db), updates)
        }

        return (
            <>
                <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                        <TableCell>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}
                            >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">{row.eventType}</TableCell>
                        <TableCell align="center">{row.date}</TableCell>
                        <TableCell align="center">{row.location}</TableCell>
                        <TableCell align="center">
                            {
                            row.status === "pending" ?
                            <>
                                <Stack sx={{justifyContent:"center"}} direction="row">
                                    <IconButton  onClick={()=>{handleJoin(row.uid)}}>
                                        <CheckIcon color="primary"/>
                                    </IconButton>
                                    <IconButton onClick={()=>{handleRefuse(row.uid)}}>
                                        <ClearIcon color="secondary"/>
                                    </IconButton>
                                </Stack>
                            </>
                            :
                            row.status === "joined" ?
                                <Link to={"/payment"} style={{ textDecoration: 'none' }}>
                                    <Button 
                                        variant="text"
                                        color={"primary"}
                                        
                                    >
                                        {row.cost && "Pay" || "Joined"}
                                    </Button>
                                </Link>
                            :
                            row.status === "refused" ?
                                <Button 
                                    variant="text"
                                    color={"error"}
                                    
                                >
                                    {t('calendarButtonTwo')}
                                </Button>
                            :
                                <Button 
                                        variant="text"
                                        color={"info"}
                                        
                                    >
                                    {t('calendarButtonThree')}
                                </Button>
                            }
                    </TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                            <Typography variant="subtitle2" fontWeight={500} gutterBottom component="div">
                                {t('calendarTextOne')}
                            </Typography>
                            {(row.status === "joined" || row.status === "refused") && 
                                <>
                                    <Button onClick={handleStatusChange} disabled={statusChange === true ? true : false} variant='outlined' color='primary' size="small" sx={{mt:1,mb:1}}>{t("calendarButtonFour")}</Button> 
                                    {
                                        statusChange === true &&
                                        <>
                                            <Typography variant="subtitle2" fontWeight={500}>{t('calendarTextTwo')}</Typography>
                                            <Stack direction="row">
                                                <IconButton onClick={()=>{handleJoin(row.uid)}}>
                                                    <CheckIcon color="primary"/>
                                                </IconButton>
                                                <IconButton onClick={()=>{handleRefuse(row.uid)}}>
                                                    <ClearIcon color="secondary"/>
                                                </IconButton>
                                            </Stack>
                                        </>
                                    }

                                    <Divider/>
                                </>
                            }
                            <Grid container m={1}>
                                {Object.keys(row).map((key, i) => (
                                    (row[key] !== "" && key !== "status"  && key !== "trainingType" && key !== "team" && key !== "athlete"
                                    && key !== "staff" && key !== "trainingType" && key !== "eventId" && key !== "addedBy" && key !== "uid" &&
                                    key !== "recurrent") &&
                                    <>
                                    <Grid item xs={6} md={4}>
                                        <Grid container direction="row" justifyContent='space-between' paddingLeft={2}>
                                            <Typography variant="subtitle2">{key.split(/(?=[A-Z])/).join(" ").toLocaleLowerCase()}</Typography>
                                            <Typography variant="subtitle2" color="primary">
                                            {key === 'staff' || key === "team" || key === "athlete" ? 
                                            ""
                                            :
                                            row[key]}</Typography>
                                        </Grid>
                                    </Grid>
                                    </>
                                ))
                                }
                            </Grid>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        )

    }

    return (
        <>
            <FormControl sx={{mt: 2}}>
                <InputLabel id="period-select-label">{t('calendarMenuTitle')}</InputLabel>
                    <Select
                        labelId="period-select-label"
                        id="period-select"
                        value={selectedPeriod}
                        label={t('calendarMenuTitle')}
                        onChange={handlePeriodChange}
                        sx={{width:'200px'}}
                        size='small'
                    >
                    <MenuItem value="all">{t('calendarMenuValueOne')}</MenuItem>
                    <MenuItem value="today">{t('calendarMenuValueTwo')}</MenuItem>
                    <MenuItem value="thisWeek">{t('calendarMenuValueThree')}</MenuItem>
                    <MenuItem value="thisMonth">{t('calendarMenuValueFour')}</MenuItem>
                    <MenuItem value="thisYear">{t('calendarMenuValueFive')}</MenuItem>
                </Select>
            </FormControl>
            <TableContainer component={Paper} sx={{maxHeight:"400px", width:{xs:'90vw',md:'100%'},mt:2}}>
                <Table size="small">
                    <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell align="center">{t('calendarCellOne')}</TableCell>
                        <TableCell align="center">{t('calendarCellTwo')}</TableCell>
                        <TableCell align="center">{t('calendarCellThree')}</TableCell>
                        <TableCell align="center">{t('calendarCellFour')}</TableCell>
                        <TableCell align="center">{t('calendarCellFive')}</TableCell>
                    </TableRow>
                    </TableHead> 
                    <TableBody>
                    {rows.map((row) => (
                        <Row key={row.eventId} row={row} />
                    ))}
                    
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default InvitesComponent


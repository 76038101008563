import {useState,useEffect} from 'react'

import { Box, Toolbar, Grid, Divider, Button, Snackbar } from '@mui/material'

import TabComponent from './components/TabComponent'
import Equipment from './components/Equipment';
import Membership from './components/Membership';

import { useTranslation } from 'react-i18next';

const Administration = (props) => {
    const { t } = useTranslation();

    const drawerWidth = 240;

    const [openDialogEq, setOpenDialogEq] = useState(false);
    const [openDialogMv, setOpenDialogMv] = useState(false);

    const [openSnack, setOpenSnack] = useState(false)
    const [messageSnack, setMessageSnack] = useState("")

    const handleClickCloseEq = () => {
        setOpenDialogEq(false);
    };

    const handleClickCloseMv = () => {
        setOpenDialogMv(false);
    };

    const closeSnack = () => {
        setOpenSnack(false)
    };
    
    return (
        <>
            <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                    <Toolbar />
                    <Grid container spacing={2} align="center">
                        <Grid item xs={12} md={6}>
                            <Button variant="contained" onClick={()=>{setOpenDialogEq(true)}}>{t('administrationButtonOne')}</Button>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Button variant="contained" color="secondary" onClick={()=>{setOpenDialogMv(true)}}>{t('administrationButtonTwo')}</Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <TabComponent 
                                administrationData={props.administrationData}
                                athletes={props.athletes}
                                staff={props.staff}
                                />
                        </Grid>
                    </Grid>
                    <Equipment 
                        openDialog={openDialogEq} 
                        handleClickClose={handleClickCloseEq}
                        setOpenSnack={setOpenSnack}
                        athletes={props.athletes}
                        administrationData={props.administrationData}
                        setMessageSnack={setMessageSnack}
                    />
                    <Membership 
                        openDialog={openDialogMv} 
                        handleClickClose={handleClickCloseMv}
                        setOpenSnack={setOpenSnack}
                        administrationData={props.administrationData}
                        setMessageSnack={setMessageSnack}
                    />
                    <Snackbar
                        open={openSnack}
                        autoHideDuration={3000}
                        onClose={closeSnack}
                        message={messageSnack}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    />
            </Box>
        </>
    )
}

export default Administration


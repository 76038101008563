import React from 'react'
import Profile from '../components/Profile'

const ProfilePage = (props) => {
    return (
        <>
            <Profile userData={props.userData}/>
        </>
    )
}

export default ProfilePage
const theme = {
    palette: {
        type: 'light',
        primary: {
          main: '#54cdb7',
          contrastText: '#000000',
          light: '#76D7C5',
          dark: '#3A8F80',
        },
        secondary: {
          main: '#303030',
          contrastText: '#54cdb7',
          light: '#595959',
          dark: '#212121',
        },
        error: {
          main: '#ab3248',
          contrastText: '#ffffff',
          light: '#BB5B6C',
          dark: '#772332',
        },
        success: {
          main: '#54cdb7',
          contrastText: '#ffffff',
          light: '#76D7C5',
          dark: '#3A8F80',
        },
        text: {
          primary: '#ffffff',
          secondary: '#000000',
          hint: '#54cdb7',
          disabled: '#A3A3A3',
        },
      },
      components: {
          MuiTextField: {
            styleOverrides: {
              root: {
                "& .MuiOutlinedInput-root:hover": {
                  "& > fieldset": {
                    borderColor: "#54cdb7",
                  },
                },
                "& .MuiOutlinedInput-root":{
                  color:"#121212"
                },
              },
            },
          },
          MuiMenuItem:{
            styleOverrides: {
              root: {
                "&.Mui-selected":{
                  color:"#121212"
                },
                "&.MuiMenuItem-root":{
                    color:"#121212"
                },
              }
            }
          },
          MuiAutocomplete:{
            styleOverrides: {
              listbox: {
                color:'black'
              }
            }
          },
          MuiPaper:{
            styleOverrides: {
              root: {
                backgroundColor:'#fff'
              }
            }
          },
          MuiToolbar:{
            styleOverrides: {
              root: {
                backgroundColor:'#fff'
              }
            }
          },
          MuiTableCell:{
            styleOverrides: {
              root: {
                color:'black'
              }
            }
          },
          MuiPickersDay:{
            styleOverrides: {
              root: {
                color: 'black'
              }
            }
          },
          MuiStepLabel:{
            styleOverrides: {
              labelContainer: {
                color:'white'
              }
            }
          },
          PrivatePickersYear: {
            styleOverrides: {
              button: {
                color: 'black'
              }
            }
          },
          PrivatePickersMonth: {
            styleOverrides: {
              root: {
                color: "black"
              }
            }
          },
          MuiPickersCalendarHeader: {
              styleOverrides: {
                label:{
                  color: 'black'
                }
              }
          },
          PrivatePickersToolbarText: {
            styleOverrides: {
              root:{
                "&.Mui-selected":{
                  color:"black"
                },
              }
            }
          },
          MuiFormControlLabel:{
            styleOverrides: {
              label: {
                color:'black'
              },
              root: {
                '.MuiFormControlLabel-label': {
                  "& checked": {
                    color: "#54cdb7",
                  },
                },
            }}
          },
          MuiInputBase:{
            styleOverrides: {
              root: {
                color:'black'
              }
            }
          },
        },
}

export default theme; 
import React from 'react'

import { Box, Toolbar, Grid } from '@mui/material'

import UserInformation from './components/UserInformation';
import ClubInformation from './components/ClubInformation';

const Profile = (props) => {
    const drawerWidth = 240;
    
    return (
        <>
            <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                    <Toolbar />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <UserInformation userData={props.userData}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ClubInformation 
                                clubName={props.userData.clubName} 
                                clubImage={props.userData.clubImage}
                                teamData={props.userData.teamData}
                                sportSectionData={props.userData.sportSectionData}
                                />
                        </Grid>
                    </Grid>
            </Box>
        </>
    )
}

export default Profile


import {useState,useEffect} from 'react'

import { Typography, Box, Toolbar, Grid } from '@mui/material'

import ClubsTable from './components/ClubsTable';
import OverviewComponent from './components/OverviewComponent';
import MembersComponent from './components/MembersComponent';
import PaymentsComponent from './components/PaymentsComponent';
import SubscriptionComponent from './components/SubscriptionComponent';
import EventsComponent from './components/EventsComponent';
import EquipmentsComponent from './components/EquipmentsComponent';

import { useTranslation } from 'react-i18next';

const Payment = (props) => {
    const { t } = useTranslation();
    const drawerWidth = 240;

    const [userRole, setUserRole] = useState("")
    const [payments, setPayments] = useState([])

    const [fullData, setFullData] = useState([])

    useEffect(()=>{
        if(props.userRole){
            setUserRole(props.userRole)
        }
    },[props.userRole])

    useEffect(()=>{
        if(props.userRole === "Athlete"){
            if(props.paymentData && props.paymentData.userData){
                const userData = props.paymentData.userData;
                const newUserObject = {
                    userData: {
                        ...userData,
                        invites: {},
                        equipments: {}
                    }
                };
                if(props.paymentData.events && props.paymentData.userData.invites){
                    const invites = userData.invites;
                    const events = props.paymentData.events;

                    for (const inviteId in invites) {
                        const invite = invites[inviteId];
                        const eventId = invite.eventId;
                        const event = events[eventId];
                      
                        if (event) {
                          invite.eventData = { ...event };
                        }

                        if(userData.payments && userData.payments.events && userData.payments.events[eventId]){
                            invite.eventData.paid = userData.payments.events[eventId]
                        } else if(invite.eventData.cost){
                            invite.eventData.paid = false
                        }
                      
                        newUserObject.userData.invites[inviteId] = invite;
                       
                    }
                }
                if(props.paymentData.equipments && props.paymentData.userData.equipments){
                    const equipmentsAll = props.paymentData.equipments;
                    const equipmentsUser = userData.equipments
                    
                    for (const equipmentId in equipmentsUser) {
                        const equipment = equipmentsUser[equipmentId];
                        const eq = equipmentsAll[equipment.equipmentId];
                      
                        if (eq) {
                          equipment.equipmentData = { ...eq }; 
                        }
                        if(userData.payments && userData.payments.equipments && userData.payments.equipments[equipment.equipmentId]){
                            equipment.equipmentData.paid = userData.payments.equipments[equipment.equipmentId]
                        } else if(equipment.equipmentData.cost){
                            equipment.equipmentData.paid = false
                        }

                        newUserObject.userData.equipments[equipmentId] = equipment; 
                    }
                }
                setFullData(newUserObject)
            }
        }
        if(props.paymentData && (props.userRole === 'Parent')){
            if(props.paymentData){
                const newUserObject = {
                    userData: {
                        invites: {},
                        equipments: {}
                    }
                };
                if(props.paymentData.events && props.paymentData.childInvites){
                    const invites = props.paymentData.childInvites;
                    const events = props.paymentData.events;

                    for (const inviteId in invites) {
                        const invite = invites[inviteId];
                        const eventId = invite.eventId;
                        const event = events[eventId];
                      
                        if (event) {
                          invite.eventData = { ...event };
                        }

                        if(props.paymentData.evPaid && props.paymentData.evPaid[eventId]){
                            invite.eventData.paid = props.paymentData.evPaid[eventId]
                        } else if(invite.eventData.cost){
                            invite.eventData.paid = false
                        }
                      
                        newUserObject.userData.invites[inviteId] = invite;
                       
                    }
                }
                if(props.paymentData.equipments && props.paymentData.childEquipments){
                    const equipmentsAll = props.paymentData.equipments;
                    const equipmentsUser = props.paymentData.childEquipments
                    
                    for (const equipmentId in equipmentsUser) {
                        const equipment = equipmentsUser[equipmentId];
                        const eq = equipmentsAll[equipment.equipmentId];
                      
                        if (eq) {
                          equipment.equipmentData = { ...eq }; 
                        }
                        if(props.paymentData.eqPaid && props.paymentData.eqPaid[equipment.equipmentId]){
                            equipment.equipmentData.paid = props.paymentData.eqPaid[equipment.equipmentId]
                        } else if(equipment.equipmentData.cost){
                            equipment.equipmentData.paid = false
                        }

                        newUserObject.userData.equipments[equipmentId] = equipment; 
                    }
                }
                setFullData(newUserObject)
            }
        }
    },[props.members, props.paymentData])
    
    return (
        <>
            <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                    <Toolbar />
                    <Grid container spacing={2}>
                        {userRole === "Owner" &&
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('paymentTextTwo')}</Typography>
                            <ClubsTable clubs={props.clubs}/>
                        </Grid>
                        }
                        {userRole === "Club Manager" &&
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{t('paymentTextOne')}</Typography>
                            <OverviewComponent clubData={props.clubData}/>
                            <Typography variant="subtitle2" mt={2}>{t('paymentTextThree')}</Typography>
                            <MembersComponent members={props.members}/>
                        </Grid>
                        }
                        {(userRole === "Club Manager" || userRole === "Coach") &&
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" mt={2}>{t('paymentTextSeven')}</Typography>
                            <PaymentsComponent members={props.members} events={props.paymentData.events} equipments={props.paymentData.equipments}/>
                        </Grid>
                        }
                        {(userRole === "Athlete" || userRole === "Parent") &&
                        <Grid item xs={12} align="center">
                            <Typography variant="subtitle2" mt={2} align='left'>{t('paymentTextOne')}</Typography>
                            <SubscriptionComponent userRole={userRole} paymentData={props.paymentData} membershipValue={props.clubData.membershipValue} stripeAccount={props.clubData.stripeAccount}/>
                            <Typography variant="subtitle2" mt={2} align='left'>{t('paymentTextTwelve')}</Typography>
                            <EventsComponent userRole={userRole} childId={props.paymentData.child} events={fullData.userData.invites} stripeAccount={props.clubData.stripeAccount}/>
                            <Typography variant="subtitle2" mt={2} align='left'>{t('paymentTextThirteen')}</Typography>
                            <EquipmentsComponent userRole={userRole} childId={props.paymentData.child} equipments={fullData.userData.equipments} stripeAccount={props.clubData.stripeAccount}/>
                        </Grid>
                        }
                    </Grid>
            </Box>
        </>
    )
}

export default Payment


import React from 'react'
import SignUp from '../components/SignUp'

const SignUpPage = () => {
    return (
        <>
            <SignUp/>
        </>
    )
}

export default SignUpPage
import React from 'react'
import Payment from '../components/Payment'

const PaymentPage = (props) => {
    return (
        <>
            <Payment 
                clubs={props.clubs} 
                userRole={props.userRole} 
                clubData={props.clubData} 
                members={props.members}
                paymentData={props.paymentData}
            />
        </>
    )
}

export default PaymentPage
import React, {useEffect, useState} from 'react'

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Snackbar } from '@mui/material';
import { payMembership } from '../../../stripe/PaymentFunctions';

import { useAuthValue } from '../../../Firebase/AuthContext';

import { useTranslation } from 'react-i18next';

const SubscriptionComponent = (props) => {
    const { t } = useTranslation();

    const [paymentData, setPaymentData] = useState([])
    const [subscriptionArr, setSubscriptionArr] = useState([])
    const [paidMonth, setPaidMonth] = useState(false)
    const [membershipValue, setMembershipValue] = useState("")
    const [stripeAccount, setStripeAccount] = useState("")
    const [childId, setChildId] = useState("")

    const {currentUser} = useAuthValue()
    
    useEffect(()=>{
        if(props.paymentData){
            setPaymentData(props.paymentData)

            if(props.userRole==="Athlete") {
                if ( props.paymentData.userData.payments && props.paymentData.userData.payments.subscription) {
                    const subscriptionArray = props.paymentData.userData.payments.subscription;
                    const formattedSubscriptionData = Object.entries(subscriptionArray).map(([date, paid]) => ({
                        date,
                        paid
                    }));
                    setSubscriptionArr(formattedSubscriptionData);

                    const currentMonth = new Date().getMonth() + 1; 
                    const currentYear = new Date().getFullYear();
                    const subscriptionDates = Object.keys(props.paymentData.userData.payments.subscription);
                    const hasSubscriptionForThisMonth = subscriptionDates.some(date => {
                        const [day, month, year] = date.split('-').map(Number);
                        return month === currentMonth && year === currentYear;
                    });

                    if(hasSubscriptionForThisMonth){
                        setPaidMonth(true)
                    }
                } else {
                    setPaidMonth(false)
                }
            } else if (props.userRole === "Parent") {
                setChildId(props.paymentData.child)
                setPaymentData(props.paymentData)
                if (props.paymentData.subscription) {
                    const subscriptionArray = props.paymentData.subscription;
                    const formattedSubscriptionData = Object.entries(subscriptionArray).map(([date, paid]) => ({
                        date,
                        paid
                    }));
                    setSubscriptionArr(formattedSubscriptionData);

                    const currentMonth = new Date().getMonth() + 1; 
                    const currentYear = new Date().getFullYear();
                    const subscriptionDates = Object.keys(props.paymentData.subscription);
                    const hasSubscriptionForThisMonth = subscriptionDates.some(date => {
                        const [day, month, year] = date.split('-').map(Number);
                        return month === currentMonth && year === currentYear;
                    });

                    if(hasSubscriptionForThisMonth){
                        setPaidMonth(true)
                    }
                } else {
                    setPaidMonth(false)
                }
            }
        }
        if(props.membershipValue){
            setMembershipValue(props.membershipValue)
        }
        if(props.stripeAccount){
            setStripeAccount(props.stripeAccount)
        }
    },[props.paymentData, props.membershipValue, props.stripeAccount, props.userRole])

    const handlePay = () => {
        let uid = currentUser.uid 
        let currentDate = new Date();
        let day = currentDate.getDate();
        let month = currentDate.getMonth() + 1; 
        let year = currentDate.getFullYear();
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;

        let formattedDate = `${day}-${month}-${year}`;

        if(props.userRole === "Athlete"){
            payMembership(stripeAccount,membershipValue,uid,formattedDate)
        } else if(props.userRole === "Parent"){
            payMembership(stripeAccount,membershipValue,childId,formattedDate)
        }
        
    };

    return (
        <>
        {
            !paidMonth && <Button variant='contained' color='secondary' sx={{marginTop:'20px'}} onClick={handlePay}>{t('paymentButtonFive')} - {membershipValue} Lei</Button>
        }
            <TableContainer component={Paper} sx={{maxHeight:"400px", width:{xs:'90vw',md:'100%'},mt:2}}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                <TableRow>
                    <TableCell align="center">{t('paymentCellSixteen')}</TableCell>
                    <TableCell align="center">{t('paymentCellFourteen')}</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {subscriptionArr.map((row, index) => (
                    <TableRow
                    key={row.name}
                    sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                    }}
                    >
                        <TableCell align="center">{row.date}</TableCell>
                        <TableCell align="center">{row.paid}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
            {/* <DeleteAccountDialog 
                openDialog={openDialog} 
                setOpenSnack={setOpenSnack} 
                handleClickClose={handleClickClose}
                account={deleteAccount}
                />
            <Snackbar
                open={openSnack}
                autoHideDuration={3000}
                onClose={closeSnack}
                message="Account deleted."
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            /> */}
        </>
    )
}

export default SubscriptionComponent


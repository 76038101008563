"use strict";
import { getAuth } from "firebase/auth";
import {auth,app} from '../Firebase/firebase'
import { addDoc, collection, getFirestore, onSnapshot, query, getDocs} from "firebase/firestore";
import { firestoreDb } from "../Firebase/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

export const getCheckoutUrlSubscription = async (page,priceId) => {
  
  const userId = auth.currentUser?.uid;
  if (!userId) throw new Error("User is not authenticated");

  const db = firestoreDb;

  const data = {
    price: priceId, 
    success_url: `${window.location.origin}/${page}`,
    cancel_url: `${window.location.origin}/${page}`,
  };

  const checkoutSessionsCollection = collection(db, `customers/${userId}/checkout_sessions`);
  const docRef = await addDoc(checkoutSessionsCollection, data);

  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(docRef, (snap) => {
      const { error, url } = snap.data();
      if (error) {
        unsubscribe();
        reject(new Error(`An error occurred: ${error.message}`));
      }
      if (url) {
        unsubscribe();
        resolve(url);
      }
    });
  });
};

export const getPortalUrl = async () => {
  const user = auth.currentUser;

  let dataWithUrl;
  try {
    const functions = getFunctions(app, "europe-west3");

    const functionRef = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );
    const { data } = await functionRef({
      customerId: user?.uid,
      returnUrl: `${window.location.origin}/home`,
    });

    dataWithUrl = data;
  } catch (error) {
    console.error(error);
  }
  return new Promise((resolve, reject) => {
    if (dataWithUrl.url) {
     
      resolve(dataWithUrl.url);
    } else {
      reject(new Error("No url returned"));
    }
  });
};

// export const getSubscriptionStatus = async () => {
//   const userId = auth.currentUser?.uid;
//   const db = firestoreDb;
    
//   if (!userId) throw new Error("User not logged in");

//   const subscriptionsRef = collection(db, "customers", userId, "subscriptions");
//   const q = query(
//     subscriptionsRef,
//     // where("status", "in", ["trialing", "active"])
//   );

//   return new Promise((resolve, reject) => {
//     const unsubscribe = onSnapshot(
//       q,
//       (snapshot) => {
//         // In this implementation we only expect one active or trialing subscription to exist.
//         if (snapshot.docs.length === 0) {
//           resolve(false);
//         } else {
//           resolve(true);
//         }
//         unsubscribe();
//       },
//       reject
//     );
//   });
// };

export const getSubscriptionStatus = (customerId) => {
  const db = firestoreDb;

  const subscriptionsRef = collection(db, "customers", customerId, "subscriptions");

  if(subscriptionsRef){
    return true
  } else {
    return false
  }
  
}
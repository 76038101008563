import React from 'react'

import { Typography, Box, Toolbar, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import InvitationForm from './components/InvitationForm'

const Invite = (props) => {
    const { t } = useTranslation();

    const drawerWidth = 240;
    
    return (
        <>
            <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                    <Toolbar />
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" fontWeight={500}>{t("inviteMainTitle")}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <InvitationForm userData={props.userData} athletes={props.athletes}/>
                        </Grid>
                    </Grid>
            </Box>
        </>
    )
}

export default Invite


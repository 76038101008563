import React, {useEffect, useState} from 'react'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Snackbar } from '@mui/material'

import AddIcon from '@mui/icons-material/Add';
import LocationDialog from './LocationDialog';

import { useTranslation } from 'react-i18next';

const LocationsTab = (props) => {
    const { t } = useTranslation();

    const [rows, setRows] = useState([])

    const [openDialog, setOpenDialog] = useState(false);
    const [openSnack, setOpenSnack] = useState(false)
    
    useEffect(()=>{
        if(props.locations){
            let arr = Object.values(props.locations)
            setRows(arr)
        }
    },[props.locations])

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClickClose = () => {
        setOpenDialog(false);
    };

    const closeSnack = () => {
        setOpenSnack(false)
    };

    return (
        <>
            <TableContainer component={Paper} sx={{maxHeight:"50vh", width:{xs:'90vw',md:'100%'},mt:2}}>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell align="center">{t('administrationCellOne')}</TableCell>
                        <TableCell align="center">{t('administrationCellTwo')}</TableCell>
                        <TableCell align="center">{t('administrationCellThree')}</TableCell>
                        <TableCell align="center">{t('administrationCellFour')}</TableCell>
                        <TableCell align="center">{t('administrationCellFive')}</TableCell>
                        <TableCell align="center">{t('administrationCellSix')}</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row) => (
                        <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="center">{row.name}
                            </TableCell>
                            <TableCell align="center">{row.hourlyRate}</TableCell>
                            <TableCell align="center">{row.address}</TableCell>
                            <TableCell align="center">{row.contactName}</TableCell>
                            <TableCell align="center">{row.contactPhone}</TableCell>
                            <TableCell align="center">{row.contactMail}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button onClick={handleClickOpen} variant="contained" color="secondary" endIcon={<AddIcon/>} sx={{mt:4,width: {xs: "80%", md: "40%"}}}>{t('administrationButtonThree')}</Button>
            <LocationDialog 
                openDialog={openDialog} 
                setOpenSnack={setOpenSnack} 
                clubId={props.clubId} 
                handleClickClose={handleClickClose}/>
            <Snackbar
                open={openSnack}
                autoHideDuration={3000}
                onClose={closeSnack}
                message={t('administrationSnackOne')}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
        </>
    )
}

export default LocationsTab


import {useState,useEffect} from 'react'

import { auth } from '../Firebase/firebase';
import { getDatabase, ref, get, child } from 'firebase/database';

import {Link} from 'react-router-dom'

import { Typography, Grid, Button } from '@mui/material';

import logo_main from '../assets/logo/White_p.png'

function RestrictAccess() {

  const [canPay, setCanPay] = useState(false)

  useEffect(()=>{
    const dbRef = ref(getDatabase());
    let uid = auth.currentUser.uid;
    get(child(dbRef, `users/${uid}`)).then((snapshot) => {
      if (snapshot.exists()) {
        var data = snapshot.val()
        if(data.role === "Club Manager"){
          setCanPay(true)
        }
      }
    })
  },[])

  return (
    
    <Grid container sx={{width:'100vw',height:'100vh', overflow:'hidden'}} align="center">
      <Grid item xs={12}>
          <img
              src={logo_main}
              alt={"Logo"}
              style={{width: '120px', height: '120px',textAlign:'center', marginTop:'20px'}}
          />
      </Grid>
      <Grid item xs={12} md={12}> 
        <Typography variant="subtitle1" fontWeight={"500"}>Access restricted</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        {
          canPay ? 
          <Typography variant="subtitle1" fontWeight={"500"}>Please pay your subscription in order to access the content.</Typography>
          :
          <Typography variant="subtitle1" fontWeight={"500"}>You are not allowed to access your account at the moment.</Typography>
        } 
      </Grid>
      <Grid item xs={12} md={12}> 
        {
          canPay ? 
          <Button variant="contained">Pay now</Button>
          :
          <Typography variant="subtitle2" fontWeight={"500"}>Please contact your club manager for more details. We apologize for any inconvenience.</Typography>
        }
      </Grid>
      <Grid item xs={12} md={12}> 
        <Typography variant="subtitle2" fontWeight={"500"}>
        <Link to="/">Back to sign in</Link></Typography>
      </Grid>
    </Grid>
  )
}

export default RestrictAccess
import React, {useState,useEffect} from 'react'

import { Typography, Grid, Avatar } from '@mui/material'

import { useTranslation } from 'react-i18next'

const ClubInformation = (props) => {
    const { t } = useTranslation();
    
    const [clubName, setClubName] = useState("");

    const [clubLogo, setClubLogo] = useState("");
    // const [teamName, setTeamName] = useState("");
    // const [teamLogo, setTeamLogo] = useState("");
    const [sportSections, setSportSections] = useState([]);
    const [teams, setTeams] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [sportSectionData, setSportSectionData] = useState([]);

    useEffect(()=>{
        const cachedUrl = localStorage.getItem('clubPhoto');
        if (cachedUrl) {
          setClubLogo(cachedUrl);
        }else if(props.clubImage){
            setClubLogo(props.clubImage)
        }
        if(props.clubName){
            setClubName(props.clubName)
        }
        if(props.teamData){
            setTeamData(props.teamData)

                
            let valuesTeams = Object.values(props.teamData)
            let teams = []

            for(let i = 0; i < valuesTeams.length; i++){
                let obj = valuesTeams[i]
                teams.push(obj)
            }
            setTeams(teams)
        }
        if(props.sportSectionData){
            setSportSectionData(props.sportSectionData)
                   
            let valuesSS = Object.values(props.sportSectionData)
            let nameSS = []

            for(let i = 0; i < valuesSS.length; i++){
                nameSS.push(valuesSS[i].name)
            }
            setSportSections(nameSS)
        }
        
    },[props.clubName,props.clubImage,props.teamData,props.sportSectionData])
    
    return (
        <>
            <Grid container spacing={1.5}>
                <Grid item xs={6}>
                    <Typography variant="subtitle1" fontWeight={500}>{t("profileTitleTwo")}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" fontWeight={500}>{t("profileSubtitleOne")}</Typography>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center'}}>
                    <Typography variant="subtitle1" fontWeight="bold" color="primary" fontStyle="italic">{clubName ? clubName : t("profilePlaceholder")}</Typography>
                </Grid>
                {
                    clubLogo !== "" ?
                <Grid item xs={6} align="center">
                    <Avatar sx={{ width: 50, height: 50 }} src={clubLogo} />
                </Grid>
                :
                <></>
                }
                <Grid item xs={12}>
                    <Typography variant="subtitle1" fontWeight={500}>{t("profileSubtitleTwo")}</Typography>
                </Grid>
                {
                teams.length>0 ?
                teams.map((item) => (
                <>
                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center'}}>
                    <Typography variant="subtitle1" fontWeight="bold" color="primary" fontStyle="italic">{item.name}</Typography>
                </Grid>

                <Grid item xs={6} align="center">
                    <Avatar sx={{ width: 50, height: 50 }} src={item.image} />
                </Grid>
                </>
                ))
                :
                <>
                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center'}}>
                        <Typography variant="subtitle1" fontWeight="bold" color="primary" fontStyle="italic">{t("profilePlaceholder")}</Typography>
                    </Grid>
                </>
                }
                <Grid item xs={12}>
                    <Typography variant="subtitle1" fontWeight={500}>{t("profileSubtitleThree")}</Typography>
                </Grid>
                {
                sportSections.length>0 ?
                sportSections.map((item) => (
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center'}}>
                    <Typography variant="subtitle1" fontWeight="bold" color="primary" fontStyle="italic">{item}</Typography>
                </Grid>
                ))
                :
                <>
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center'}}>
                        <Typography variant="subtitle1" fontWeight="bold" color="primary" fontStyle="italic">{t("profilePlaceholder")}</Typography>
                    </Grid>
                </>
                }
            </Grid>
        </>
    )
}

export default ClubInformation


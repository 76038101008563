import React, {useState,useEffect} from 'react'

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' 
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick

import './calendar.css'

import moment from 'moment-timezone';

import { useTranslation } from 'react-i18next'

const CalendarComponent = (props) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const [timezone, setTimezone] = useState("");
    const [events, setEvents] = useState([]);

    useEffect(()=>{
        if(props.calendarData.timezone){
            setTimezone(props.calendarData.timezone)
        }
        if(props.calendarData.invites){
            let inv = props.calendarData.invites
            let keys = Object.keys(inv)
            let val = Object.values(inv)
            const newEvents = [];
            for(let i = 0 ; i < val.length; i++){
                if(val[i].status === "joined") {
                    let calculateStatus = ""
                    if(val[i].endDate) {
                        calculateStatus = val[i].endDate
                    } else if (val[i].date){
                        calculateStatus = val[i].date
                    }
                    const momentDate = moment.tz(calculateStatus, 'DD/MM/YYYY', props.calendarData.timezone);
                    const momentNow = moment.tz(props.calendarData.timezone);
                    if (!momentDate.isBefore(momentNow, 'day')){
                        if(val[i].eventType === "tournament"){
                        
                            let ev = {}
                            val[i].uid = keys[i];
                            const date = moment.tz(val[i].date, "DD/MM/YYYY", timezone)
                            const endDate = moment.tz(val[i].endDate, "DD/MM/YYYY", timezone);
                            ev = {
                            title: val[i].name,
                            start: date.format(),
                            end: endDate.add(1, 'days').format(),
                            allDay: true,
                            backgroundColor: '#121212', 
                            borderColor: 'black'
                            };
                            newEvents.push(ev);
                        } else {
                            let ev = {}
                            val[i].uid = keys[i];

                            const date = moment.tz(val[i].date, "DD/MM/YYYY", timezone);
                            const startTime = moment(val[i].startHour, "HH:mm");
                            const endTime = moment(val[i].endHour, "HH:mm");
                            const start = moment.tz(date.format('YYYY-MM-DD') + 'T' + startTime.format('HH:mm:ss'), timezone).format();
                            const end = moment.tz(date.format('YYYY-MM-DD') + 'T' + endTime.format('HH:mm:ss'), timezone).format();
                            ev = {
                            title: val[i].name,
                            start: start,
                            end: end,
                            }
                            if (val[i].recurrent) {
                                const endDate = moment.tz(val[i].endDate, "DD/MM/YYYY", timezone);
                                const repeatEvery = val[i].repeatEvery; // "Thursday"
                                let nextDate = moment.tz(date.format('YYYY-MM-DD'), timezone);
                                while (nextDate.isSameOrBefore(endDate)) {
                                if (nextDate.format('dddd') === repeatEvery) {
                                    const recurringStart = moment.tz(nextDate.format('YYYY-MM-DD') + 'T' + startTime.format('HH:mm:ss'), timezone).format();
                                    const recurringEnd = moment.tz(nextDate.format('YYYY-MM-DD') + 'T' + endTime.format('HH:mm:ss'), timezone).format();
                                    const recurringEv = {
                                    title: val[i].name,
                                    start: recurringStart,
                                    end: recurringEnd,
                                    };
                                    newEvents.push(recurringEv);
                                }
                                nextDate.add(1, 'days');
                                }
                            } else {
                                newEvents.push(ev);
                            }
                        }
                    }
                }
            }
            setEvents(newEvents)
        }
    },[props.calendarData.invites,props.calendarData.timezone])


    return (
        <>
           <FullCalendar
             plugins={[ dayGridPlugin, interactionPlugin ]}
            initialView="dayGridMonth"
            headerToolbar={{
              left: 'prev,next',
              center: 'title',
              right: 'today'
            }}
            events={events}
            timeZone={timezone}
            displayEventEnd={true}
            timeFormat={'HH:mm'}
            eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }}
            locale={currentLanguage == 'en' ? "en" : "ro"}
            // dateClick={this.handleDateClick}
            eventColor="#54cdb7"
            height="800px"
            slotLabelFormat={{hour: 'numeric', minute: '2-digit', hour12: false}}
            firstDay={1}
        />
        </>
    )
}

export default CalendarComponent


import React, {useState, useEffect} from 'react';

import { Box, Divider, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper  } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { getDatabase, ref, update} from "firebase/database";

import { useTranslation } from 'react-i18next';

export default function InternalPaymentsDialog(props) {
    const { t } = useTranslation();

    const [event, setEvent] = useState([])
    const [members, setMembers] = useState([])
    const [loading, setLoading] = useState(false)
    const [buttonText, setButtonText] = useState(t('paymentButtonThree'))

    useEffect(()=>{
      if(props.row){
        setEvent(props.row)
      }
      if(props.members){
        setMembers(props.members)
      }
    },[props.row,props.members])


    const updatePayment = (row) => {
        const db = getDatabase();
        const updates = {};
        setLoading(true)
        setButtonText("Loading")

        if(event.eventType === "equipment"){
            updates['users/'+row.memberId+'/payments/equipments/'+row.eventId] = 'cash'
            update(ref(db), updates).then(()=>{
                setLoading(false)
                setButtonText(t('paymentButtonFour'))
                props.handleClickClose()
                setButtonText(t('paymentButtonThree'))
            })
        }  else{
            updates['users/'+row.memberId+'/payments/events/'+row.eventId] = 'cash'
            update(ref(db), updates).then(()=>{
                setLoading(false)
                setButtonText(t('paymentButtonFour'))
                props.handleClickClose()
                setButtonText(t('paymentButtonThree'))
            })
        }
    }

    return (
        <div>
        <Dialog open={props.openDialog} onClose={props.handleClickClose}>
            <DialogTitle color="black" variant="subtitle1" fontWeight={500} sx={{textDecoration: 'underline',textDecorationColor:'#54cdb7', textDecorationThickness:'2px'}}>{event.name}</DialogTitle>
            <Divider/>
            <DialogContent>
                <DialogContentText  variant="subtitle1" fontWeight={500} >
                    {t('paymentTextEight')}
                </DialogContentText>
                    <TableContainer component={Paper} sx={{mt:2}}>
                        <Table sx={{ minWidth: 500 }} size="small" aria-label="a dense table">
                            <TableHead>
                            <TableRow>
                                <TableCell align="left">{t('paymentCellOne')}</TableCell>
                                <TableCell align="center">{t('paymentCellFourteen')}</TableCell>
                                <TableCell align="center">{t('paymentCellFifteen')}</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {members.map((row, index) => (
                                <TableRow key={row.memberId}>
                                    <TableCell align="left">{row.firstName + " " + row.lastName}</TableCell>
                                    <TableCell align="center">{row.paid === 'unpaid' ? "-" : row.paid}</TableCell>
                                    <TableCell align="center">
                                        {row.paid === 'unpaid' ? 
                                            <LoadingButton
                                                onClick={()=>{updatePayment(row)}}
                                                loading={loading}
                                                variant="contained"
                                                color="primary" 
                                                size="small" 
                                                sx={{color:"#FFF", width:'30%'}}
                                            >
                                                {buttonText}
                                            </LoadingButton>
                                        : 
                                        "-"}
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            </DialogContent>
        </Dialog>
        </div>
    );
}
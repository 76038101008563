import React,{useEffect, useState} from 'react'

import { IconButton, TextField, Grid, Button, MenuItem, Typography } from '@mui/material';

import { DeleteOutlined as DeleteIcon } from '@mui/icons-material';

import { v4 as uuidv4 } from 'uuid';

import { plans } from '../../../utils/plans_data';

import { useTranslation } from 'react-i18next'

const InviteForm = (props) => {
  const { t } = useTranslation();

  const [inputList, setInputList] = useState(props.inputList.length !== 0 ? props.inputList : [
      { id: uuidv4(), email: "", password: "password", role: "", firstName: "", lastName: ""}
    ]);

  const [rolesArr, setRolesArr] = useState(["Athlete","Coach","Parent"])

  const [textError, setTextError] = useState("")
  const [textDemo, setTextDemo] = useState("")

  useEffect(() => {
    if (inputList.length > 2) {
      const accounts = inputList.length;
      const selectedPlan = plans.find((plan) => {
        const planAccounts = parseInt(plan.accounts);
        return accounts < planAccounts || plan.accounts === '>200';
      });
  
      if (selectedPlan) {
        props.setPlan(selectedPlan.name);
        setTextDemo(t('signupTextTwo'));
      } else {
        setTextDemo(t('signupTextThree'));
      }
    } else {
      props.setPlan("Demo"); 
      setTextDemo("");
    }
  }, [inputList]);    

  useEffect(()=>{
      props.setInputList(inputList)
      setTextError(props.errorTextInvites)
  },[inputList,props.errorTextInvites])


  const handleInputChange = (id) => (evt) => {
      const { value } = evt.target;
      setInputList((list) =>
        list.map((el) =>
          el.id === id
            ? {
                ...el,
                [evt.target.name]: value
              }
            : el
        )
      );
    };

    const handleRemoveClick = (id) => {
      if(inputList.length === 1){
          setInputList([{ id: 1, email: "", password: "password", role: "", firstName: "", lastName: ""}])
      } else {
      setInputList((list) => list.filter((el) => el.id !== id));
      }
    };

    const handleAddClick = () => {
      let isValid = true
      for (let i = 0; i < inputList.length; i++){
        if(inputList[i].email === "" || inputList[i].role === "" || inputList[i].firstName === "" || inputList[i].lastName === ""){
          setTextError(t('signupErrorEight'))
          isValid = false
        }
      }
      if(isValid === true) {
        setTextError("")
        setInputList([
          ...inputList,
          { id: uuidv4(), email: "", password: "password", role: "", firstName: "", lastName:"" }
        ]);
      }
    };


    return (
        <>
            <Grid container align="center">
              <Grid item xs={12}>
                <Typography variant="subtitle2" fontWeight={500} color="primary">{textError}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" fontWeight={500} color="error">{textDemo}</Typography>
              </Grid>
              <Grid item xs={12}>
              {inputList.map((x, i) => {
                  return (
                  <Grid key={x.id} container spacing={1} sx={{pt:2}}>
                      <Grid item xs={5}>
                          <TextField
                              label={t('signupLabelFour')}
                              size="small"
                              name="firstName"
                              value={x.firstName}
                              onChange={handleInputChange(x.id)}
                              // sx={{width:{ xs: "80%", md: "60%" }}}
                              fullWidth
                          />
                      </Grid>
                      <Grid item xs={5}>
                          <TextField
                              label={t('signupLabelFive')}
                              size="small"
                              name="lastName"
                              value={x.lastName}
                              onChange={handleInputChange(x.id)}
                              // sx={{width:{ xs: "80%", md: "60%" }}}
                              fullWidth
                          />
                      </Grid>
                      <Grid item xs={5}>
                          <TextField
                              label="Email"
                              size="small"
                              name="email"
                              value={x.email}
                              onChange={handleInputChange(x.id)}
                              // sx={{width:{ xs: "80%", md: "60%" }}}
                              fullWidth
                          />
                      </Grid>
                      <Grid item xs={5}>
                          <TextField
                                required
                                size="small"
                                select
                                label="Role"
                                name="role"
                                value={x.role}
                                defaultValue={"Athlete"}
                                onChange={handleInputChange(x.id)}
                                // sx={{width: {xs: "80%", md: "60%"}}}
                                fullWidth
                              >
                              {rolesArr.map((option) => (
                              <MenuItem key={option} value={option} color="secondary">
                              {option}
                              </MenuItem>
                              ))}
                              </TextField>
                      </Grid>
                      <Grid item xs={2}>
                          <IconButton onClick={() => handleRemoveClick(x.id)}>  
                              <DeleteIcon></DeleteIcon>
                          </IconButton>
                      </Grid>
                  </Grid>
                  );
              })}
              <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddClick}
                  sx={{width:'200px',mt:2}}
              >{t('signupButtonThree')}</Button>
              </Grid>
            </Grid>
        </>
    )
}

export default InviteForm
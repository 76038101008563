import React, {useState, useEffect} from 'react';

import { Box, Divider, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle  } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { getDatabase, ref, update} from "firebase/database";

import { useTranslation } from 'react-i18next';

export default function PayDialog(props) {
    const { t } = useTranslation();

    const [account, setAccount] = useState("")

    const [loading, setLoading] = useState(false)
    const [buttonText, setButtonText] = useState(t('paymentButtonSeven'))

    useEffect(()=>{
      if(props.account){
        setAccount(props.account)
      }
    },[props.account])

    const updatePayment = () => {
        const uid = account.uid;
       
        let currentDate = new Date();
        let day = currentDate.getDate();
        let month = currentDate.getMonth() + 1; 
        let year = currentDate.getFullYear();
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;

        let formattedDate = `${day}-${month}-${year}`;

        if(uid){
            setLoading(true)
            setButtonText("Loading..")
            const db = getDatabase();

            const updates = {};
            updates['users/'+uid+'/payments/subscription/'+formattedDate] = 'cash'

            update(ref(db), updates)
            .then(()=>{
                setLoading(false)
                setButtonText(t('paymentButtonSeven'))
                props.handleClickClose()
            })
        }   else {
            setLoading(false)
            setButtonText(t('paymentButtonSeven'))
        }
    }

    return (
        <div>
        <Dialog open={props.openDialog} onClose={props.handleClickClose}>
            <DialogTitle color="black" variant="subtitle1" fontWeight={500} sx={{textDecoration: 'underline',textDecorationColor:'#54cdb7', textDecorationThickness:'2px'}}>{t('paymentTextFourteen')}</DialogTitle>
            <Divider/>
            <DialogContent>
            <DialogContentText  variant="subtitle1" fontWeight={500} >
                {t('paymentTextFifteen')} {account.firstName + " " + account.lastName} {t('paymentTextSixteen')}
            </DialogContentText>
            </DialogContent>
            <DialogActions sx={{justifyContent:"center"}}>
                <Button onClick={props.handleClickClose} variant="contained" size="small" sx={{color:"#FFF", width:'30%', backgroundColor:"gray", ":hover":{backgroundColor:"#303030"}}}>{t('paymentButtonEight')}</Button>
                <LoadingButton
                    onClick={updatePayment}
                    loading={loading}
                    variant="contained"
                    color="primary" 
                    size="small" 
                    sx={{color:"#FFF", width:'50%'}}
                >
                    {buttonText}
                </LoadingButton>
            </DialogActions>
        </Dialog>
        </div>
    );
}
import React,{useState} from 'react'

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { useTranslation } from 'react-i18next'

const AccountDetailsSignUp = (props) => {

    const { t } = useTranslation();

    return (
        <>
             <Grid container spacing={2} sx={{px:{ xs: 7, md: 20},mt:2}}>
                <Grid item xs={12} md={6} > 
                    <TextField
                        required
                        label={t('signupLabelFour')}
                        value={props.firstName}
                        type="text"
                        onChange={(e)=>{props.setFirstName(e.target.value)}}
                        error={props.errorFirstName}
                        helperText={props.errorFirstName ? t('signupErrorFive') : ""}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6} > 
                    <TextField
                        required
                        label={t('signupLabelFive')}
                        value={props.lastName}
                        type="text"
                        onChange={(e)=>{props.setLastName(e.target.value)}}
                        error={props.errorLastName}
                        helperText={props.errorLastName ? t('signupErrorFive') : ""}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={12}> 
                    <TextField
                        required
                        label={t('signupLabelSix')}
                        value={props.email}
                        type="email"
                        onChange={(e)=>{props.setEmail(e.target.value)}}
                        error={props.errorEmail}
                        helperText={props.errorEmail ? t('signupErrorSix') : ""}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={12}> 
                    <TextField
                        label={t('signupLabelSeven')}
                        required
                        type="password"
                        value={props.password}
                        onChange={(e)=>{props.setPassword(e.target.value)}}
                        error={props.errorPass}
                        helperText={props.errorPass ? props.errorPassText : ""}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={12}> 
                    <TextField
                        label={t('signupLabelEight')}
                        required
                        type="password"
                        value={props.confirmPassword}
                        onChange={(e)=>{props.setConfirmPassword(e.target.value)}}
                        error={props.errorPass}
                        helperText={props.errorPass ? props.errorPassText : ""}
                        fullWidth
                    />
                </Grid>
                
                <Grid item xs={12} md={12}> 
                    <Button variant="contained" color="secondary" size="large" fullWidth onClick={props.handleNext}>{t('signupButtonTwo')}</Button>
                </Grid>
             </Grid>
        </>
    )
}

export default AccountDetailsSignUp
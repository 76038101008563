import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from '../src/utils/translation/en.json'
import ro from '../src/utils/translation/ro.json'

i18n.use(initReactI18next).init({
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {    
        en: {
            translation: en
        },
        ro: {
            translation: ro
        },
    },
});

export default i18n;
import React, {useState, useEffect} from 'react';

import { Box, Divider, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle  } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { getDatabase, ref, push} from "firebase/database";

import { useTranslation } from 'react-i18next';

export default function LocationDialog(props) {
    const { t } = useTranslation();

    const [clubId, setClubId] = useState("")

    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [hourlyRate, setHourlyRate] = useState("");
    const [contactName, setContactName] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [contactMail, setContactMail] = useState("");

    const [loading, setLoading] = useState(false)
    const [buttonText, setButtonText] = useState(t('administrationButtonFour'))

    const [errorName, setErrorName] = useState(false)
    const [errorAddress, setErrorAddress] = useState(false)

    useEffect(()=>{
        if(props.clubId){
            setClubId(props.clubId)
        }
    },[props.clubId])

    useEffect(()=>{
        setName("")
        setAddress("")
        setHourlyRate("")
        setContactName("")
        setContactPhone("")
        setContactMail("")
        setButtonText(t('administrationButtonFour'))
    },[props.openDialog])

    const validateName = () => {
        setErrorName(false)
        let isValid = true
        if (name !== ''){

        } else {
            isValid = false
            setErrorName(true)
        } 
        return isValid
    }

    const validateAddress = () => {
        setErrorAddress(false)
        let isValid = true
        if (address !== ''){

        } else {
            isValid = false
            setErrorAddress(true)
        } 
        return isValid
    }

    const saveData = () => {
        if(validateName() && validateAddress()){
            setLoading(true)
            setButtonText(t('administrationButtonFour'))
            const db = getDatabase();
            push(ref(db, 'clubs/'+clubId+'/locations'), {
                name: name,
                address: address,
                hourlyRate: hourlyRate,
                contactName: contactName,
                contactPhone: contactPhone,
                contactMail: contactMail
            }).then(()=>{
                setLoading(false)
                setButtonText(t('administrationButtonSix'))
                props.setOpenSnack(true)
                props.handleClickClose()
            })
        }   else {
            setLoading(false)
            setButtonText(t('administrationButtonFour'))
        }
    }

    return (
        <div>
        <Dialog open={props.openDialog} onClose={props.handleClickClose}>
            <DialogTitle color="black" variant="subtitle1" fontWeight={500} sx={{textDecoration: 'underline',textDecorationColor:'#54cdb7', textDecorationThickness:'2px'}}>{t('administrationButtonThree')}</DialogTitle>
            <Divider/>
            <DialogContent>
            <DialogContentText  variant="subtitle1" fontWeight={500} >
               {t('administrationTextOne')}
            </DialogContentText>
            <Box align="center" sx={{mt:2}}>
                <TextField
                    required
                    size="small"
                    label={t('administrationLabelFour')}
                    type="text"
                    value={name}
                    onChange={(e)=>{setName(e.target.value)}}
                    error={errorName}
                    helperText={errorName ? t('administrationErrorOne') : ""}
                    sx={{width:"80%"}}
                />
                <TextField
                    required
                    size="small"
                    label={t('administrationLabelFive')}
                    type="text"
                    value={address}
                    onChange={(e)=>{setAddress(e.target.value)}}
                    error={errorAddress}
                    helperText={errorAddress ? t('administrationErrorTwo') : ""}
                    sx={{width:"80%", mt:2}}
                />
                <TextField
                    size="small"
                    label={t('administrationLabelSix')}
                    type="text"
                    value={hourlyRate}
                    onChange={(e)=>{setHourlyRate(e.target.value)}}
                    sx={{width:"80%", mt:2}}
                />
                <TextField
                    size="small"
                    label={t('administrationLabelSeven')}
                    type="text"
                    value={contactName}
                    onChange={(e)=>{setContactName(e.target.value)}}
                    sx={{width:"80%", mt:2}}
                />
                <TextField
                    size="small"
                    label={t('administrationLabelEight')}
                    type="text"
                    value={contactPhone}
                    onChange={(e)=>{setContactPhone(e.target.value)}}
                    sx={{width:"80%", mt:2}}
                />
                <TextField
                    size="small"
                    label={t('administrationLabelNine')}
                    type="text"
                    value={contactMail}
                    onChange={(e)=>{setContactMail(e.target.value)}}
                    sx={{width:"80%", mt:2}}
                />
            </Box>
            </DialogContent>
            <DialogActions sx={{justifyContent:"center"}}>
                <Button onClick={props.handleClickClose} variant="contained" size="small" sx={{color:"#FFF", width:'30%', backgroundColor:"gray", ":hover":{backgroundColor:"#303030"}}}>{t('administrationButtonFive')}</Button>
                <LoadingButton
                    onClick={saveData}
                    loading={loading}
                    variant="contained"
                    color="primary" 
                    size="small" 
                    sx={{color:"#FFF", width:'30%'}}
                >
                    {buttonText}
                </LoadingButton>
            </DialogActions>
        </Dialog>
        </div>
    );
}
import React, {useEffect, useState} from 'react';

import { Box, Button, IconButton, Dialog, Divider, DialogContent, DialogContentText, DialogTitle, Grid, Typography  } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import AddTraining from './AddTraining';
import AddMatch from './AddMatch';
import AddTournament from './AddTournament';

import { useTranslation } from 'react-i18next'

export default function SelectEventTypeDialog(props) {
    const { t } = useTranslation();

    const [eventType, setEventType] = useState("none");

    useEffect(()=>{
        setEventType("none")
    },[props.openDialog])

    const changeEventType = (type) => {
        setEventType(type);
    };

    return (
        <div>
        <Dialog open={props.openDialog} onClose={props.handleClickClose}>
            <DialogTitle color="black" variant="subtitle1" fontWeight={500} sx={{textDecoration: 'underline',textDecorationColor:'#54cdb7', textDecorationThickness:'2px'}}>{t("calendarDialogTitleOne")}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={props.handleClickClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <Divider/>
            <DialogContent>
            <DialogContentText  variant="subtitle1" fontWeight={500} >
                {eventType === "none" ?
                    t('calendarDialogTextOne')
                :
                eventType === "training" ?
                    t('calendarDialogTextTwo')
                :
                eventType === "match" ?
                    t('calendarDialogTextThree')
                :
                eventType === "tournament" ?
                    t('calendarDialogTextFour')
                :
                ""
                }
            </DialogContentText>
            <Box align="center" sx={{mt:1}}>
            {eventType === "none" ?
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button onClick={() => { changeEventType("training")}} variant="contained" color="primary" size="small" sx={{color:"#FFF", width:'50%'}}>{t('calendarDialogButtonOne')}</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={() => { changeEventType("match")}} variant="contained" color="primary" size="small" sx={{color:"#FFF", width:'50%'}}>{t('calendarDialogButtonTwo')}</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={() => { changeEventType("tournament")}} variant="contained" color="primary" size="small" sx={{color:"#FFF", width:'50%'}}>{t('calendarDialogButtonThree')}</Button>
                    </Grid>
                </Grid>
            :
            eventType === "training" ?
            <AddTraining 
                openDialog={props.openDialog} 
                handleClickClose={props.handleClickClose}
                calendarData={props.calendarData} 
                athletes={props.athletes} 
                staff={props.staff}
                setOpenSnack={props.setOpenSnack} 
                setMessageSnack={props.setMessageSnack}
                />
            :
            eventType === "match" ?
            <AddMatch 
                openDialog={props.openDialog}
                handleClickClose={props.handleClickClose}
                calendarData={props.calendarData} 
                athletes={props.athletes} 
                staff={props.staff}
                setOpenSnack={props.setOpenSnack} 
                setMessageSnack={props.setMessageSnack}
                />
            :
            eventType === "tournament" ?
            <AddTournament 
                openDialog={props.openDialog}
                handleClickClose={props.handleClickClose}
                calendarData={props.calendarData} 
                athletes={props.athletes} 
                staff={props.staff}
                setOpenSnack={props.setOpenSnack} 
                setMessageSnack={props.setMessageSnack}
                />
            :
            <Typography variant="subtitle1" color="primary">{eventType}</Typography>
            }
            </Box>
            </DialogContent>
        </Dialog>
        </div>
    );
}
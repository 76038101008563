import React, {useEffect} from 'react';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next'

export default function StepperComponent(props) {
  const { t } = useTranslation();

  const steps = [
    {
      label: t('signupStepTitleOne'),
      description: t('signupStepDescriptionOne'),
    },
    {
      label: t('signupStepTitleTwo'),
      description: t('signupStepDescriptionTwo'),
    },
    {
      label: t('signupStepTitleThree'),
      description: t('signupStepDescriptionThree'),
    },
    {
      label: t('signupStepTitleFour'),
      description: t('signupStepDescriptionFour'),
    },
  ];

 
  const [activeStep, setActiveStep] = React.useState(props.activeStep);

  useEffect(() => {
    setActiveStep(props.activeStep)
  }, [props.activeStep]); 

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} classes={{
                  color: "green"
                }} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>
                {step.label}
            </StepLabel>
            <StepContent>
              <Typography sx={{color:"text.primary"}}>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    disabled={index === 0}
                    onClick={props.handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {t('signupButtonOne')}
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
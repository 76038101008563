import React from 'react'
import Calendar from '../components/Calendar'

const CalendarPage = (props) => {
    return (
        <>
            <Calendar calendarData={props.calendarData} athletes={props.athletes} staff={props.staff}/>
        </>
    )
}

export default CalendarPage
import React from "react";

import { Card, CardContent, Divider, Typography } from "@mui/material";

import { useTranslation } from 'react-i18next'

const TrainingCard= (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <Card sx={{maxWidth:"500px",backgroundColor:"white",height: '135px'}}>
        <CardContent >
          <Typography variant={"h6"} color="primary.contrastText">
            {t('homeCardOneTitle')}
          </Typography>
          <Typography display="inline" variant={"subtitle1"} color="primary.contrastText">
            {Object.keys(props.firstTraining).length !== 0?
              t('homeCardOneSubtitleOne')
              :
              t('homeCardOneSubtitleTwo')
            }
          </Typography>
          <Typography display="inline" variant={"subtitle1"} color="primary">
            {Object.keys(props.firstTraining).length !== 0 ? 
              props.firstTraining.showDate 
              :
              ""
            }
          </Typography>
          <Divider  light />
          {Object.keys(props.firstTraining).length !== 0 ?
          <>
            <Typography display="inline" variant={"subtitle1"} color="primary.contrastText">
              {t('homeCardOneText')} &nbsp;
            </Typography>
            <Typography display="inline" variant={"subtitle1"} color="primary">
              {props.firstTraining.location}
            </Typography>
          </>
          :
          <></>
          }
        </CardContent>
      </Card>
    </div>
  );
}

export default TrainingCard;
import React, {useEffect, useState} from 'react'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Snackbar } from '@mui/material'

import RestrictDialog from './RestrictDialog'
import { plans } from '../../../utils/plans_data'

import { useTranslation } from 'react-i18next';

const ClubsTable = (props) => {
    const { t } = useTranslation();

    const [rows, setRows] = useState([])

    // const [restrictClub, setRestrictClub] = useState([])

    const [openDialog, setOpenDialog] = useState(false);
    const [openSnack, setOpenSnack] = useState(false)
    
    useEffect(()=>{
        if(props.clubs){
            let arr = Object.values(props.clubs)
            setRows(arr)
        }
    },[props.clubs])

    // const handleClickOpen = (row) => {
    //     setRestrictClub(row)
    //     setOpenDialog(true);
    // };

    // const handleClickClose = () => {
    //     setOpenDialog(false);
    // };

    const closeSnack = () => {
        setOpenSnack(false)
    };

    return (
        <>
            <TableContainer component={Paper} sx={{maxHeight:"50vh", width:{xs:'90vw',md:'100%'},mt:2}}>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell align="center">{t('paymentCellOne')}</TableCell>
                        <TableCell align="center">{t('paymentCellTwo')}</TableCell>
                        <TableCell align="center">{t('paymentCellThree')}</TableCell>
                        <TableCell align="center">{t('paymentCellFour')}</TableCell>
                        <TableCell align="center">{t('paymentCellFive')}</TableCell>
                        <TableCell align="center">{t('paymentCellSix')}</TableCell>
                        <TableCell align="center">{t('paymentCellSeven')}</TableCell>
                        {/* <TableCell align="center">Edit access</TableCell> */}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row) => (
                        <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="center">{row.clubName}</TableCell>
                            <TableCell align="center">{row.clubInvites}</TableCell>
                            <TableCell align="center">{row.dateCreated}</TableCell>
                            <TableCell align="center">{row.clubType}</TableCell>
                            <TableCell align="center">Done</TableCell>
                            <TableCell align="center">{plans.find((plan) => plan.name === row.clubType)?.price || 'N/A'}</TableCell>
                            <TableCell align="center">{row.restricted.toString()}</TableCell>
                            {/* <TableCell align="center">
                                <Button onClick={()=>{handleClickOpen(row)}}>Options</Button>
                            </TableCell> */}
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <RestrictDialog
                openDialog={openDialog} 
                setOpenSnack={setOpenSnack} 
                handleClickClose={handleClickClose}
                club={restrictClub}
            />
            <Snackbar
                open={openSnack}
                autoHideDuration={3000}
                onClose={closeSnack}
                message="Club updated."
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            /> */}
        </>
    )
}

export default ClubsTable


import React, {useState,useEffect} from 'react';

import { Tabs, Tab, Box } from '@mui/material';

import LocationsTab from './LocationsTab';
import TeamsTab from './TeamsTab';
import SportSectionsTab from './SportSectionsTab';

import { useTranslation } from 'react-i18next';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box align="center">
          {children}
        </Box>
      )}
    </div>
  );
}

export default function TabComponent(props) {
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} variant="fullWidth">
          <Tab label={t('administrationLabelOne')}/>
          <Tab label={t('administrationLabelTwo')}/>
          <Tab label={t('administrationLabelThree')}/>
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <LocationsTab clubId={props.administrationData.clubId} locations={props.administrationData.locations}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TeamsTab 
          clubId={props.administrationData.clubId}
          athletes={props.athletes} 
          staff={props.staff}
          sportSections={props.administrationData.sportSections}
          teams={props.administrationData.teams}
          />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SportSectionsTab 
          clubId={props.administrationData.clubId} 
          sportSections={props.administrationData.sportSections} />
      </TabPanel>
    </Box>
  );
}

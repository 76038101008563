import {useState} from 'react'

import {Divider, Grid, TextField, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Send } from '@mui/icons-material';

import { plans } from '../../../utils/plans_data';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next'

const Subscription = (props) => {

    const { t } = useTranslation();

    const [rows, setRows] = useState(plans)
    const [planIndex, setPlanIndex] = useState(0)

    useEffect(()=>{
        let type = props.plan
        const subscriptionPlan = plans.find(plan => plan.name === type);
        const index = plans.indexOf(subscriptionPlan);
        setPlanIndex(index)
    },[props.plan])

    const handleRowClick = (row,index) => {
      if(props.clubInvites > row.accounts){
  
      } else {
        setPlanIndex(index)
        props.setPlan(plans[index].name)
      }
    };

    return (
        <>
             <Grid container sx={{mt:2}}>
                <Grid item xs={12} md={12} align="center"> 
                   <Typography variant="subtitle2">{t('signupTextFour')}</Typography>
                </Grid>
                <Grid item xs={12} md={12} align="center"> 
                   <Typography variant="subtitle2" color="primary" sx={{fontWeight:'bold'}}>{props.plan}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper} sx={{mt:2,padding:'20px'}}>
                        <Table sx={{ minWidth: 500 }} size="small" aria-label="a dense table">
                            <TableHead>
                            <TableRow>
                                <TableCell align="left">{t('signupCellOne')}</TableCell>
                                <TableCell align="center">{t('signupCellTwo')}</TableCell>
                                <TableCell align="center">{t('signupCellThree')}</TableCell>
                                <TableCell align="center">{t('signupCellFour')}</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map((row, index) => (
                                <TableRow
                                key={row.name}
                                onClick={() => handleRowClick(row,index)} 
                                sx={{
                                    cursor: 'pointer',
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    backgroundColor: index === planIndex ? '#54cdb7' : row.accounts < props.clubInvites ? "grey" : ''
                                }}
                                >
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="center">{row.accounts}</TableCell>
                                <TableCell align="center">{row.price}</TableCell>
                                <TableCell align="center">{row.period}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} md={12} sx={{m:2}} align="center"> 
                    <LoadingButton
                            onClick={props.register}
                            endIcon={<Send/>}
                            loading={props.loading}
                            loadingPosition="end"
                            variant="contained"
                            color="secondary" 
                            size="large" 
                            sx={{width: {xs: "80%", md: "60%"}}} 
                        >
                            {t('signupButtonFour')}
                    </LoadingButton>
                </Grid>
             </Grid>
        </>
    )
}

export default Subscription
import React, {useEffect, useState} from 'react'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Snackbar } from '@mui/material';
import DeleteAccountDialog from './DeleteAccountDialog';
import PayDialog from './PayDialog';

import { useTranslation } from 'react-i18next';

const MembersComponent = (props) => {
    const { t } = useTranslation();

    const [members, setMembers] = useState([])
    const [deleteAccount, setDeleteAccount] = useState([])
    const [payAccount, setPayAccount] = useState([])

    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    const [openDialogPay, setOpenDialogPay] = useState(false);
    const [openSnack, setOpenSnack] = useState(false)
    
    useEffect(()=>{
        if(props.members){
            
            const currentMonth = new Date().getMonth() + 1; 
            const currentYear = new Date().getFullYear();
            let members = props.members
            let new_members = []
            members.forEach(user => {
                if(user.role === "Athlete"){
                    if (user.payments && user.payments.subscription) {
                        let payType = ""
                        const subscriptionDates = Object.keys(user.payments.subscription);
                        const hasSubscriptionForThisMonth = subscriptionDates.some(date => {
                            const [day, month, year] = date.split('-').map(Number);
                            if(month === currentMonth && year === currentYear){
                                payType = user.payments.subscription[date]
                            }
                            return month === currentMonth && year === currentYear;
                        });
                
                        if (hasSubscriptionForThisMonth) {
                            user.payment = payType
                        } else {
                            user.payment='Unpaid'
                        }
                    } else {
                        user.payment='Unpaid'
                    }
                } else {
                    user.payment='-'
                }

            });

            function convertDate(dateString) {
                const [day, month, year] = dateString.split('/');
                return new Date(`${year}-${month}-${day}`);
            }
            
            members.sort((a, b) => convertDate(b.dateCreated) - convertDate(a.dateCreated));
            
           setMembers(members)
        }
    },[props.members])

    const handleClickOpenPay = (row) => {
        setPayAccount(row)
        setOpenDialogPay(true);
    };

    const handleClickClosePay = () => {
        setOpenDialogPay(false);
    };

    const handleClickOpenDelete = (row) => {
        setDeleteAccount(row)
        setOpenDialogDelete(true);
    };

    const handleClickCloseDelete = () => {
        setOpenDialogDelete(false);
    };

    const closeSnack = () => {
        setOpenSnack(false)
    };

    return (
        <>
            <TableContainer component={Paper} sx={{maxHeight:"400px", width:{xs:'90vw',md:'100%'},mt:2}}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                <TableRow>
                    <TableCell align="center">{t('paymentCellOne')}</TableCell>
                    <TableCell align="center">{t('paymentCellEight')}</TableCell>
                    <TableCell align="center">{t('paymentCellNine')}</TableCell>
                    <TableCell align="center">{t('paymentCellTen')}</TableCell>
                    <TableCell align="center">{t('paymentCellEleven')}</TableCell>
                    <TableCell align="center">{t('paymentCellTwelve')}</TableCell>
                    {/* <TableCell align="center">Delete Account</TableCell> */}
                </TableRow>
                </TableHead>
                <TableBody>
                {members.map((row, index) => (
                    <TableRow
                    key={row.name}
                    sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                    }}
                    >
                        <TableCell align="center">{row.firstName + " " + row.lastName}</TableCell>
                        <TableCell align="center">{row.role}</TableCell>
                        <TableCell align="center">{row.dateCreated}</TableCell>
                        <TableCell align="center">{row.role !== "Club Manager" ? (row.invitedFirstSignIn === true ? "Not Active" : "Active") : "-"}</TableCell>
                        <TableCell align="center">{row.clubInvites ? row.clubInvites : 0}</TableCell>
                        <TableCell align="center">
                            <Button onClick={()=>{handleClickOpenPay(row)}} 
                                disabled={(row.payment !== 'Unpaid') ? true : false}>
                                {row.payment}
                            </Button>
                        </TableCell>
                        {/* <TableCell align="center">
                            <Button onClick={()=>{handleClickOpenDelete(row)}} disabled={row.role === "Club Manager" ? true : false}>Delete</Button>
                        </TableCell> */}
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
            {/* <DeleteAccountDialog 
                openDialog={openDialogDelete} 
                setOpenSnack={setOpenSnack} 
                handleClickClose={handleClickCloseDelete}
                account={deleteAccount}
            /> */}
            <PayDialog 
                openDialog={openDialogPay} 
                handleClickClose={handleClickClosePay}
                account={payAccount}
            />
            <Snackbar
                open={openSnack}
                autoHideDuration={3000}
                onClose={closeSnack}
                message="Account deleted."
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
        </>
    )
}

export default MembersComponent


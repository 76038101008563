import React, {useEffect, useState} from 'react'

import {useNavigate, useLocation} from 'react-router-dom'

import { useTranslation } from 'react-i18next';

import {AppBar, Box, Divider, Drawer, IconButton, Toolbar, List, ListItem, ListItemButton, 
    ListItemIcon, ListItemText, Typography, Grid, Avatar, TextField, MenuItem } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import RoundaboutLeftIcon from '@mui/icons-material/RoundaboutLeft';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

import routes from './routes';

import logo_main from '../../assets/logo/Black_NoBgnd.png'
import avatar from '../../assets/avatar_prev.png'

import {auth} from '../../Firebase/firebase'

export const languagesArr = [
    { label: "English", code: "en" },
    { label: "Romana", code: "ro" },
  ];

const NavigationComponent = (props) => {
    const navigate = useNavigate()
    const location = useLocation();
    const { i18n, t } = useTranslation();

    const drawerWidth = 240;

    const [selectedIndex, setSelectedIndex] = useState("");

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [role, setRole] = useState("")
    const [clubName, setClubName] = useState("")
    const [image, setImage] = useState("")

    const [language, setLanguage] = useState("English")

    useEffect(()=>{
        const cachedUrl = localStorage.getItem('userPhoto');
        if (cachedUrl) {
          setImage(cachedUrl);
        }else if(props.userData.image){
            setImage(props.userData.image)
        }
        if(props.userData.role){
            setRole(props.userData.role)
        }
        if(props.userData.clubName){
            setClubName(props.userData.clubName)
        }
        if(props.userData.firstName){
            setFirstName(props.userData.firstName)
        }
        if(props.userData.lastName){
            setLastName(props.userData.lastName)
        }
    },[props.userData.role, props.userData.clubName,props.userData.image,props.userData.lastName,props.userData.firstName])

    useEffect(() => {
        if(location.pathname === "/home") {
            setSelectedIndex(0)
        } else if(location.pathname === "/profile") {
            setSelectedIndex(1)
        } else if(location.pathname === "/club") {
            setSelectedIndex(2)
        } else if(location.pathname === "/club-administration") {
            setSelectedIndex(3)
        } else if(location.pathname === "/calendar") {
            setSelectedIndex(4)
        } else if(location.pathname === "/club-invite") {
            setSelectedIndex(5)
        } else if(location.pathname === "/payment") {
            setSelectedIndex(6)
        } 
      }, [location]);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
      };

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const onChangeLang = (e) => {
        setLanguage(e.target.value.label)
        const lang_code = e.target.value;
        i18n.changeLanguage(lang_code.code);
      };


    const signOut = () => {
        auth.signOut().then(() => {
            navigate('/')
            }).catch((error) => {
                console.log(error)
        });
    }

    const drawer = (
        <div>
          <Grid container align="center">
            <Grid item xs={12}>
                <img
                    src={logo_main}
                    alt={"Logo"}
                    style={{width: '120px', height: '120px'}}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2" fontWeight={400} color="primary">
                    {firstName !== "" && lastName !=="" ? 
                        firstName + " " + lastName + " - " + t(`${role}`)
                    :
                        role
                    }
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2" fontWeight={400} color="text.primary" fontStyle={"italic"}>{clubName}</Typography>
            </Grid>
          </Grid>
          <Divider sx={{color:'white',borderColor:'white'}}/>
          <List  sx={{
                '&& .Mui-selected, && .Mui-selected:hover': {
                    bgcolor: '#E3E3E3',
                '&, & .MuiSvgIcon-root': {
                    color: 'black',
                },
                '&, & .MuiTypography-root': {
                    color: 'black',
                }
                },
                // hover states
                '& .MuiListItemButton-root:hover': {
                bgcolor: '#E3E3E3',
                '&, & .MuiSvgIcon-root': {
                    color: 'black',
                },
                '&, & .MuiTypography-root': {
                    color: 'black',
                }
                },
            }}>
            {routes.map((route) => (
                role === "Club Manager" ||
                ((route.key === "home") && role === "Owner") ||
                ((route.key === "payment") && role === "Owner") ||
                ((route.key !== "club_profile") && role === "Coach") ||
                ((route.key !== "club_profile" && route.key !== "club_administration") && role === "Athlete") ||
                ((route.key === "home" || route.key === "profile" || route.key === "payment") && role === "Parent")  ? 
              <ListItem key={route.key} disablePadding onClick={() => navigate(route.route)}>
                <ListItemButton
                 selected={selectedIndex === route.index}
                 onClick={(event) => handleListItemClick(event, route.index)}
                >
                  <ListItemIcon>
                    {route.icon}
                  </ListItemIcon>
                  <ListItemText primary={t(`${route.name}`)} sx={{color:"text.hint"}} />
                </ListItemButton>
              </ListItem>
              :
              <></>
            ))}
            <ListItem disablePadding key="logout">
                <ListItemButton onClick={signOut}>
                  <ListItemIcon>
                    <RoundaboutLeftIcon sx={{color:"white"}}/>
                  </ListItemIcon>
                  <ListItemText primary={"Sign Out"} sx={{color:"text.hint"}} />
                </ListItemButton>
              </ListItem>
          </List>
        </div>
      );


    return (
        <>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, overflow:'hidden' }}
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor:'#303030'},
                    }}
                    >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor:'#303030' },
                    }}
                    open
                >
                {drawer}
                </Drawer>
                

                <Box sx={{ display: 'flex' }}>
                    <AppBar
                        position="fixed"
                        sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        }}
                        color="primary"
                    >
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, display: { sm: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="subtitle1" fontWeight={500} sx={{display:{md:'block',xs:'none'},textDecoration: 'underline',textDecorationColor:'#54cdb7', textDecorationThickness:'2px'}} noWrap >
                                {props.page}
                            </Typography>
                            <TextField
                                select
                                size="small"
                                value={languagesArr.find((option) => option.label === language)}
                                variant='standard'
                                onChange={(e) => { onChangeLang(e) }}
                                sx={{ minWidth: '10%', marginLeft: 'auto', marginRight: '10px' }}
                            >
                                {languagesArr.map((option) => (
                                    <MenuItem key={option.code} value={option} color="secondary">
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <IconButton
                                sx={{marginRight:'10px'}}
                            >
                                <NotificationsNoneIcon/>
                            </IconButton>
                            {
                                image !=="" ?
                                <div onClick={()=>{navigate('/profile')}}>
                                    <Avatar  alt="User avatar" src={image} sx={{cursor:'pointer'}}/>
                                </div>
                                :
                                <div onClick={()=>{navigate('/profile')}}>
                                    <Avatar  alt="User avatar" src={avatar} sx={{cursor:'pointer'}}/>
                                </div>
                            }
                        </Toolbar>
                    </AppBar>
                    </Box>
            </Box>
        </>
    )
}

export default NavigationComponent



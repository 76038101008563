import React, {useEffect, useState} from 'react'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Snackbar } from '@mui/material';
import { Info } from '@mui/icons-material';
import InternalPaymentsDialog from './InternalPaymentsDialog';

import { useTranslation } from 'react-i18next';

const PaymentsComponent = (props) => {
    const { t } = useTranslation();

    const [members, setMembers] = useState([])
    const [events, setEvents] = useState([])
    const [equipments, setEquipments] = useState([])

    const [rows, setRows] = useState([])

    const [rowOpen, setRowOpen] = useState([])
    const [membersOpen, setMembersOpen] = useState([])
    const [openDialog, setOpenDialog] = useState(false);
    // const [openSnack, setOpenSnack] = useState(false)
    
    useEffect(()=>{
        let rows = []
        if(props.members){
            setMembers(props.members)
        }
        if(props.events){
            setEvents(props.events)

            let keys = Object.keys(props.events)
            let vals = Object.values(props.events)
            for(let i = 0; i < keys.length; i++){
                if(vals[i].cost){
                    vals[i].id = keys[i]
                    rows.push(vals[i])
                }
            }
        }
        if(props.equipments){
            setEquipments(props.equipments)

            let keys = Object.keys(props.equipments)
            let vals = Object.values(props.equipments)
            for(let i = 0; i < keys.length; i++){
                if(vals[i].cost){
                    vals[i].id = keys[i]
                    rows.push(vals[i])
                }
            }
        }
        setRows(rows)
    },[props.members, props.events, props.equipments])

    const handleClickOpen = (row) => {
        setRowOpen(row)
        let eventId = row.id
        
        if(row.eventType === "equipment"){
            let filteredMembers = members.filter(member => {
                if (member.equipments) {
                    return Object.values(member.equipments).some(eq => eq.equipmentId === eventId);
                }
                return false;
            }).map(member => {
                return {
                    memberId: member.uid,
                    eventId: eventId,
                    firstName: member.firstName,
                    lastName: member.lastName,
                    paid: (member.payments && member.payments.equipments && member.payments.equipments[eventId]) ? member.payments.equipments[eventId] : "unpaid"
                };
            });
            setMembersOpen(filteredMembers)
        } else {
            let filteredMembers = members.filter(member => {
                if (member.invites) {
                    return Object.values(member.invites).some(invite => invite.eventId === eventId && invite.status === "joined");
                }
                return false;
            }).map(member => {
                return {
                    memberId: member.uid,
                    eventId: eventId,
                    firstName: member.firstName,
                    lastName: member.lastName,
                    paid: (member.payments && member.payments.events && member.payments.events[eventId]) ? member.payments.events[eventId] : "unpaid"
                };
            });
            setMembersOpen(filteredMembers)
        }
        
        setOpenDialog(true);
    };

    const handleClickClose = () => {
        setOpenDialog(false);
    };

    // const closeSnack = () => {
    //     setOpenSnack(false)
    // };

    return (
        <>
            <TableContainer component={Paper} sx={{maxHeight:"400px", width:{xs:'90vw',md:'100%'},mt:2}}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                <TableRow>
                    <TableCell align="center">{t('paymentCellOne')}</TableCell>
                    <TableCell align="center">{t('paymentCellFour')}</TableCell>
                    <TableCell align="center">Cost</TableCell>
                    <TableCell align="center">{t('paymentCellThirteen')}</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {rows.map((row, index) => (
                    <TableRow
                    key={row.name}
                    sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                    }}
                    >
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">{row.eventType}</TableCell>
                        <TableCell align="center">{row.cost}</TableCell>
                        <TableCell align="center">
                            <IconButton
                                color='primary'
                                onClick={()=>{handleClickOpen(row)}}
                            >
                                <Info />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
            <InternalPaymentsDialog 
                openDialog={openDialog} 
                handleClickClose={handleClickClose}
                row={rowOpen}
                members={membersOpen}
            />
        </>
    )
}

export default PaymentsComponent


import React, {useEffect, useState} from 'react';

import { Box, Button, IconButton, Dialog, Divider, DialogContent, DialogContentText, DialogTitle, Grid, TextField,
        FormControl, InputLabel, Select, ListItemText, Checkbox, MenuItem, OutlinedInput  } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

import { getDatabase, ref, push, update} from "firebase/database";

import { useTranslation } from 'react-i18next';

export default function Equipment(props) {
    const { t } = useTranslation();

    const [clubId, setClubId] = useState("")
    const [uidMaster, setUidMaster] = useState("");

    const [name, setName] = useState("");
    const [cost, setCost] = useState("");
    const [athletes, setAthletes] = useState([]);
    const [description, setDescription] = useState("");

    const [athletesArr, setAthletesArr] = useState([]);

    const [loading, setLoading] = useState(false)
    const [buttonText, setButtonText] = useState(t('administrationButtonFour'))
    const [errorName, setErrorName] = useState(false)
    const [errorCost, setErrorCost] = useState(false)

    useEffect(()=>{
        setName("")
        setCost("")
        setAthletes([])
        setDescription("")
        setButtonText(t('administrationButtonFour'))
    },[props.openDialog])

    useEffect(()=>{
        if(props.administrationData.uidMaster){
            setUidMaster(props.administrationData.uidMaster)
        }
        if(props.administrationData.clubId){
            setClubId(props.administrationData.clubId)
        }
        if(props.athletes){
            setAthletesArr(props.athletes)
        }
    },[props.administrationData.uidMaster,props.administrationData.clubId,props.athletes])

    const validateName = () => {
        setErrorName(false)
        let isValid = true
        if (name !== ''){

        } else {
            isValid = false
            setErrorName(true)
        } 
        return isValid
    }

    const validateCost = () => {
        setErrorCost(false)
        let isValid = true
        if (cost !== ''){

        } else {
            isValid = false
            setErrorCost(true)
        } 
        return isValid
    }

    const handleChangeAthletes = (event) => {
        const {
          target: { value },
        } = event;
        setAthletes(
          typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleSave = () => {
        if(validateName() && validateCost() && athletes.length > 0)
        {
            setErrorName(false)
            setErrorCost(false)
            setLoading(true)
            setButtonText(t('administrationButtonFour'))

            const db = getDatabase()

            push(ref(db, 'clubs/'+clubId+'/equipments'), {
                addedBy: uidMaster,
                name: name,
                description: description,
                cost: cost,
                eventType: "equipment"
            }).then((snap)=>{
                setLoading(false)
                setButtonText(t('administrationButtonSix'))
                props.setMessageSnack(t('administrationSnackFive'))
                props.setOpenSnack(true)
                props.handleClickClose()

                const key = snap.key 
                if(athletes.length>0){
                    athletes.forEach(uid=>{
                        const updates = {};
                        updates['clubs/'+clubId+'/equipments/'+key+'/athlete/'+uid] = uid
                        update(ref(db), updates)

                        push(ref(db, 'users/'+uid+'/equipments'), {
                            equipmentId: key
                        })
                    })
                }
            })
        } else {
            setLoading(false)
            setButtonText(t('administrationButtonFour'))
        }
    };

    return (
        <div>
        <Dialog open={props.openDialog} onClose={props.handleClickClose}>
            <DialogTitle color="black" variant="subtitle1" fontWeight={500} sx={{textDecoration: 'underline',textDecorationColor:'#54cdb7', textDecorationThickness:'2px'}}>{t('administrationButtonOne')}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={props.handleClickClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <Divider/>
            <DialogContent>
            <DialogContentText  variant="subtitle1" fontWeight={500} >
                {t('administrationTextSix')}
            </DialogContentText>
            <Box align="center" sx={{mt:1}}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            required
                            size="small"
                            label={t('administrationLabelFour')}
                            type="text"
                            value={name}
                            onChange={(e)=>{setName(e.target.value)}}
                            error={errorName}
                            helperText={errorName ? t('administrationErrorOne') : ""}
                            sx={{width:"80%"}}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            size="small"
                            label={t('administrationLabelTwelve')}
                            type="text"
                            value={description}
                            onChange={(e)=>{setDescription(e.target.value)}}
                            sx={{width:"80%"}}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{ width: "80%", mt:1}}>
                            <InputLabel id="demo-multiple-checkbox-label">{t('administrationCellEight')}</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                size="small"
                                placeholder='label'
                                notched={true}
                                value={athletes}
                                onChange={handleChangeAthletes}
                                input={<OutlinedInput />}
                                // renderValue={(selected) => selected.join(', ')}
                                renderValue={
                                    (selected) => 
                                        Object.values(athletesArr).filter( name => selected.includes(name.uid) )
                                            .map( record => record.firstName + " " + record.lastName )
                                            .join(", ")
                                }
                                sx={{color:'black',mt:1, "&:hover": {
                                "&& fieldset": {
                                    borderColor: "#54cdb7"
                                }
                                }}}
                            >
                                {athletesArr.map((person) => (
                                    <MenuItem key={person.uid} value={person.uid}>
                                    <Checkbox checked={athletes.indexOf(person.uid) > -1} />
                                    <ListItemText primary={person.firstName + " " + person.lastName} />
                                    </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            required
                            size="small"
                            label="Cost"
                            type="number"
                            value={cost}
                            onChange={(e)=>{setCost(e.target.value)}}
                            sx={{width:"80%", mt: 2}}
                            error={errorCost}
                            helperText={errorCost ? t('administrationErrorThree') : ""}
                        />
                    </Grid>
                    <Grid item xs={6} sx={{mt:2, width:'80%'}} align="center">
                        <Button onClick={props.handleClickClose} variant="contained" size="small" sx={{color:"#FFF", width:'50%', backgroundColor:"gray", ":hover":{backgroundColor:"#303030"}}}>{t('administrationButtonFive')}</Button>
                    </Grid>
                    <Grid item xs={6} sx={{mt:2, width:'80%'}} align="center">
                        <LoadingButton
                            onClick={handleSave}
                            loading={loading}
                            variant="contained"
                            color="primary" 
                            size="small" 
                            sx={{color:"#FFF", width:'50%'}}
                        >
                            {buttonText}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
            </DialogContent>
        </Dialog>
        </div>
    );
}
import './App.css';

import {useState, useEffect} from 'react'

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { CircularProgress } from '@mui/material';

import NavigationComponent from './components/NavigationComponent';

import SignUpPage from './pages/signup';
import SignInPage from './pages/signin';
import HomePage from './pages/home';
import ProfilePage from './pages/profile';
import ClubPage from './pages/club';
import InvitePage from './pages/invite';
import AdministrationPage from './pages/administration';
import CalendarPage from './pages/calendar';
import VerifyEmail from './Firebase/VerifyEmail'
import UpdatePass from './Firebase/UpdatePass';
import PaymentPage from './pages/payment';
import RestrictAccess from './utils/RestrictAccess';

import theme from './theme';

import {AuthProvider} from './Firebase/AuthContext'
import {auth} from './Firebase/firebase'
import {onAuthStateChanged} from 'firebase/auth'
import { child, getDatabase, ref, get, onValue, serverTimestamp } from "firebase/database";

import PrivateRoute from './Firebase/PrivateRoute'

const getMuiTheme = () => createTheme(theme)


function App() {

  const [currentUser, setCurrentUser] = useState(null)
  const [timeActive, setTimeActive] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [loading, setLoading] = useState(true);

  const [invitedFirstSignIn, setInvitedFirstSignIn] = useState(false)

  const [userRole, setUserRole] = useState("")

  const [clubId, setClubId] = useState([])

  const [userDataNav, setUserDataNav] = useState({})
  const [userDataProfile, setUserDataProfile] = useState([])
  const [clubDataProfile, setClubDataProfile] = useState([])
  const [administrationData, setAdministrationData] = useState([])
  const [inviteData, setInviteData] = useState([])
  const [calendarData, setCalendarData] = useState([])
  const [homeData, setHomeData] = useState([])
  const [paymentData, setPaymentData] = useState([])

  const [athletes, setAthletes] = useState([])
  const [staff, setStaff] = useState([])
  const [members, setMembers] = useState([])

  const [clubs, setClubs] = useState([])

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if(auth.currentUser !== null){
        setCurrentUser(user) 
        setIsAuthenticated(true)
        setTimeout(() => {
          setLoading(false)
        }, 1500);
       

        let data_nav = {}
        let data_profile = {}
        let data_clubProfile = {}
        let data_administration = {}
        let data_invite = {}
        let data_calendar = {}
        let data_home = {}
        let data_payment = {}

        let uid = auth.currentUser.uid;
        const db = getDatabase();

        onValue(ref(db, 'users/' + uid ), (snapshot) => {
          if(snapshot){
            let data = snapshot.val();

            if(data) {
              if(data.invitedFirstSignIn){
                  if(data.invitedFirstSignIn === true){
                      setInvitedFirstSignIn(true)
                  }
              }

              data_nav = {
                role : data.role,
                image: data.image,
                firstName: data.firstName,
                lastName: data.lastName,
              }
              

              data_profile = {
                role: data.role,
                clubId: data.clubId,
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                phone: data.phone,
                fedNumber: data.fedNumber,
                fedSeries: data.fedSeries,
                image: data.image,
                sportSection: data.sportSection,
                team: data.team,
                clubInvites: data.clubInvites
              }

              data_invite = {
                clubId: data.clubId,
                uidMaster: uid,
                role: data.role,
                userClubInvites: data.clubInvites
              }

              data_calendar = {
                clubId: data.clubId,
                uidMaster: uid,
                role: data.role,
                // invites: data.invites,
              }

              data_home = {
                role: data.role,
                uidMaster: uid,
                firstName: data.firstName,
                lastName: data.lastName,
              }

              if(data.image){
                localStorage.setItem('userPhoto', data.image);
              } else {
                localStorage.removeItem('userPhoto')
              }

              setClubId(data.clubId)
              setUserDataNav(data_nav)
              setUserDataProfile(data_profile)
              setInviteData(data_invite)
              setCalendarData(data_calendar)
              setHomeData(data_home)

              if(data.clubId){
                onValue(ref(db, 'clubs/' + data.clubId ), (snapshot) => {
                  if(snapshot){
                    let data_club = snapshot.val();
                    if(data_club){
                      data_clubProfile = {
                        clubId : data.clubId,
                        clubManagerEmail : data_club.clubManagerEmail,
                        clubManagerId: data_club.clubManagerId,
                        clubName: data_club.clubName,
                        contactPhone: data_club.contactPhone,
                        website: data_club.website,
                        timezone: data_club.timezone,
                        currency: data_club.currency,
                        country: data_club.country,
                        county: data_club.county,
                        city: data_club.city,
                        street: data_club.street,
                        number: data_club.number,
                        zip: data_club.zip,
                        image: data_club.image,
                        clubInvites: data_club.clubInvites,
                        clubType: data_club.clubType,
                        dateCreated: data_club.dateCreated,
                        restricted: data_club.restricted,
                        membershipValue: data_club.membershipValue,
                        stripeAccount: data_club.stripeAccount
                      }
        
                      if(data_club.image){
                        localStorage.setItem('clubPhoto', data_club.image);
                      } else {
                        localStorage.removeItem('clubPhoto');
                      }
        
                      setClubDataProfile(data_clubProfile)
        
                      data_profile.clubName = data_club.clubName
                      data_profile.clubImage = data_club.image

                      if(data_club.teams && data_club.sportSections && data_profile.team && data_profile.sportSection){

                          let filteredTeams = Object.fromEntries(
                            Object.entries(data_club.teams).filter(([k]) => data_profile.team[k])
                          );

                          let filteredSS = Object.fromEntries(
                            Object.entries(data_club.sportSections).filter(([k]) => data_profile.sportSection[k])
                          );

                          data_profile.teamData = filteredTeams
                          data_profile.sportSectionData = filteredSS
                      }
        
                      setUserDataProfile(data_profile)
        
                      data_nav.clubName = data_club.clubName
                      setUserDataNav(data_nav)
                    
                      data_invite.teams = data_club.teams
                      data_invite.timezone = data_club.timezone
                      data_invite.totalClubInvites = data_club.clubInvites
                      data_invite.clubType = data_club.clubType
                      setInviteData(data_invite)
        
                      data_administration = {
                        clubId : data_profile.clubId,
                        locations: data_club.locations,
                        teams: data_club.teams,
                        sportSections: data_club.sportSections,
                        uidMaster: uid,
                        membershipValue: data_club.membershipValue
                      }
                      setAdministrationData(data_administration)

                      data_calendar.locations = data_club.locations
                      data_calendar.teams = data_club.teams
                      data_calendar.timezone =  data_club.timezone
                      // data_calendar.events = data_club.events

                      data_home.locations = data_club.locations
                      data_home.timezone = data_club.timezone

                      if(data.invites && data_club.events && data.role !== "Parent"){
                        let invites = data.invites
                        let events = data_club.events
                        const combined = {};
                        for (const inviteId in invites) {
                          const invite = invites[inviteId];
                          const eventId = invite.eventId;
                          const event = events[eventId];

                          combined[inviteId] = { ...invite, ...event };
                        }
                        data_calendar.invites = combined
                        data_home.invites = combined
                      } else if(data.role === "Parent" && data.child){
                        let child = Object.values(data.child)[0]
                        onValue(ref(db, 'users/' + child ), (snapshot) => {
                          if(snapshot){
                            let data_child = snapshot.val();
                            if(data_child){

                              if(data_child.invites && data_club.events){
                                let invites = data_child.invites
                                let events = data_club.events
                                const combined = {};
                                for (const inviteId in invites) {
                                  const invite = invites[inviteId];
                                  const eventId = invite.eventId;
                                  const event = events[eventId];
        
                                  combined[inviteId] = { ...invite, ...event };
                                }
                                data_calendar.invites = combined
                                data_home.invites = combined
                              }
                            }

                            
                            if(data_club.teams && data_club.sportSections && data_child.team && data_child.sportSection){

                              let filteredTeams = Object.fromEntries(
                                Object.entries(data_club.teams).filter(([k]) => data_child.team[k])
                              );
    
                              let filteredSS = Object.fromEntries(
                                Object.entries(data_club.sportSections).filter(([k]) => data_child.sportSection[k])
                              );
    
                              data_profile.teamData = filteredTeams
                              data_profile.sportSectionData = filteredSS
                            }
            
                            setUserDataProfile(data_profile)

                            data_payment.child = child

                            if(data_child.payments.subscription) {
                              data_payment.subscription = data_child.payments.subscription
                            }

                            if(data_child.payments.events){
                              data_payment.evPaid = data_child.payments.events
                            }

                            if(data_child.payments.equipments){
                              data_payment.eqPaid = data_child.payments.equipments
                            }

                            if(data_child.equipments){
                              data_payment.childEquipments = data_child.equipments
                            }
                            
                            if(data_child.invites){
                              data_payment.childInvites = data_child.invites
                            }

                            if(data_club.events){
                              data_payment.events = data_club.events
                            }
                            if(data_club.equipments){
                              data_payment.equipments = data_club.equipments
                            }
                            setPaymentData(data_payment)
                          }
                        })
                      } 
                      setCalendarData(data_calendar)
                      setHomeData(data_home)

                      if((data_club.events || data_club.equipments) && (data.role === "Club Manager"||data.role === "Coach")){
                        if(data_club.events){
                          data_payment.events = data_club.events
                        }
                        if(data_club.equipments){
                          data_payment.equipments = data_club.equipments
                        }
                        setPaymentData(data_payment)
                      } else if(data.role === "Athlete"){
                          data_payment.userData = data
                          if(data_club.events){
                            data_payment.events = data_club.events
                          }
                          if(data_club.equipments){
                            data_payment.equipments = data_club.equipments
                          }
                          setPaymentData(data_payment)
                      }
                    }
                  }
                });
              }
            }
          }
        })
      } else {
        setLoading(false)
      }
    })
  }, []);

  useEffect(()=>{
    const db = getDatabase();
    onValue(ref(db, 'users/'), (snapshot) => {
      if (snapshot) {
        const newAthletes = [];
        const newStaff = [];
        const allMembers = [];
    
        snapshot.forEach((snap) => {
          const userObject = snap.val();
          if(userObject.clubId === clubId){
            const role = userObject['role'];
            userObject.uid = snap.key;
      
            if (role === 'Athlete') {
              newAthletes.push(userObject);
            }
            if (role === 'Club Manager' || role === 'Coach' || role === 'Owner') {
              newStaff.push(userObject);
            }

            allMembers.push(userObject)
          }
        });
    
        setAthletes(newAthletes);
        setStaff(newStaff);
        setMembers(allMembers)
      }
    });
  },[clubId])

  useEffect(()=>{
    if(userRole === 'Owner'){
      const db = getDatabase();
      onValue(ref(db, 'clubs/'), (snapshot) => {
        if (snapshot) {
          const clubs = [];
          snapshot.forEach((snap) => {
            const clubObject = snap.val();
            clubObject.uid = snap.key;
            clubs.push(clubObject)
          });

          setClubs(clubs)
        }
      });
    }
  },[userRole])

  useEffect(()=>{
    if(auth.currentUser !== null){
      let uid = auth.currentUser.uid;
      const db = getDatabase();
  
      onValue(ref(db, 'users/' + uid + "/role"), (snapshot) => {
        if(snapshot){
          let data = snapshot.val();
          setUserRole(data);
        }
      });
    }
  },[auth.currentUser])
  
  if (loading) {
    return     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress sx={{color:'black'}}/>
              </div> 
  }

  return (
    <div style={{display:"flex"}}>
    <ThemeProvider theme={getMuiTheme()}>
     <Router>
      <AuthProvider value={{currentUser,timeActive,setTimeActive}}>
          <Routes>
            <Route path="/" element={<SignInPage/>} exact/>
            <Route path="/sign-up" element={<SignUpPage/>} exact/> 
            <Route path='/verify-email' element={<VerifyEmail/>} />
            <Route path='/restricted' element={<RestrictAccess/>} />
            <Route path='*' exact element={<SignInPage/>} />
            
            <Route exact path='/update-password' element={
              isAuthenticated && invitedFirstSignIn === true ?
              <PrivateRoute>
                <UpdatePass setInvitedFirstSignIn={setInvitedFirstSignIn}/>
              </PrivateRoute> 
              :
              <SignInPage/>
            }/>

            <Route exact path='/home' element={
              isAuthenticated && invitedFirstSignIn === false ?
              <PrivateRoute>
                <NavigationComponent page={'Home'} userData={userDataNav}></NavigationComponent>
                <HomePage userData={homeData}/>
              </PrivateRoute> 
              :
              invitedFirstSignIn === true ?
              <PrivateRoute>
                <UpdatePass setInvitedFirstSignIn={setInvitedFirstSignIn}/>
              </PrivateRoute> 
              :
              <SignInPage/>
            }/>

            <Route exact path='/profile' element={
              isAuthenticated && invitedFirstSignIn === false ?
              <PrivateRoute>
                <NavigationComponent page={'Profile'} userData={userDataNav}></NavigationComponent>
                <ProfilePage userData={userDataProfile}/>
              </PrivateRoute> 
              :
              invitedFirstSignIn === true ?
              <PrivateRoute>
                <UpdatePass setInvitedFirstSignIn={setInvitedFirstSignIn}/>
              </PrivateRoute> 
              :
              <SignInPage/>
            }/>
            
            <Route exact path='/club' element={
              isAuthenticated && invitedFirstSignIn === false  && (userRole === "Club Manager" || userRole === 'Owner') ?
              <PrivateRoute>
                <NavigationComponent page={'Club Profile'} userData={userDataNav}></NavigationComponent>
                <ClubPage clubData={clubDataProfile}/>
              </PrivateRoute> 
              :
              invitedFirstSignIn === true ?
              <PrivateRoute>
                <UpdatePass setInvitedFirstSignIn={setInvitedFirstSignIn}/>
              </PrivateRoute> 
              :
              (userRole === "Athlete" || userRole === "Parent" || userRole === "Coach") ? 
              <PrivateRoute>
                <NavigationComponent page={'Home'} userData={userDataNav}></NavigationComponent>
                <HomePage userData={homeData}/>
              </PrivateRoute>
              :
              <SignInPage/>
            }/>

            <Route exact path='/club-invite' element={
              isAuthenticated && invitedFirstSignIn === false && (userRole === "Club Manager" || userRole === "Owner" || userRole === "Coach" || userRole === "Athlete") ?
              <PrivateRoute>
                <NavigationComponent page={'Club Invite'} userData={userDataNav}></NavigationComponent>
                <InvitePage userData={inviteData} athletes={athletes}/>
              </PrivateRoute> 
              :
              invitedFirstSignIn === true ?
              <PrivateRoute>
                <UpdatePass setInvitedFirstSignIn={setInvitedFirstSignIn}/>
              </PrivateRoute> 
              :
              userRole === "Parent" ? 
              <PrivateRoute>
                <NavigationComponent page={'Home'} userData={userDataNav}></NavigationComponent>
                <HomePage userData={homeData}/>
              </PrivateRoute>
              :
              <SignInPage/>
            }/>

            <Route exact path='/club-administration' element={
              isAuthenticated && invitedFirstSignIn === false && (userRole === "Club Manager" || userRole === "Owner" || userRole === "Coach") ?
              <PrivateRoute>
                <NavigationComponent page={'Club Administration'} userData={userDataNav}></NavigationComponent>
                <AdministrationPage administrationData={administrationData} athletes={athletes} staff={staff}/>
              </PrivateRoute> 
              :
              invitedFirstSignIn === true ?
              <PrivateRoute>
                <UpdatePass setInvitedFirstSignIn={setInvitedFirstSignIn}/>
              </PrivateRoute> 
              :
              (userRole === "Athlete" || userRole === "Parent") ? 
              <PrivateRoute>
                <NavigationComponent page={'Home'} userData={userDataNav}></NavigationComponent>
                <HomePage userData={homeData}/>
              </PrivateRoute>
              :
              <SignInPage/>
            }/>

            <Route exact path='/calendar' element={
              isAuthenticated && invitedFirstSignIn === false && (userRole === "Club Manager" || userRole === "Owner" || userRole === "Coach" || userRole === "Athlete") ?
              <PrivateRoute>
                <NavigationComponent page={'Calendar'} userData={userDataNav}></NavigationComponent>
                <CalendarPage calendarData={calendarData} athletes={athletes} staff={staff}/>
              </PrivateRoute> 
              :
              invitedFirstSignIn === true ?
              <PrivateRoute>
                <UpdatePass setInvitedFirstSignIn={setInvitedFirstSignIn}/>
              </PrivateRoute> 
              :
              userRole === "Parent" ? 
              <PrivateRoute>
                <NavigationComponent page={'Home'} userData={userDataNav}></NavigationComponent>
                <HomePage userData={homeData}/>
              </PrivateRoute>
              :
              <SignInPage/>
            }/>

            <Route exact path='/payment' element={
              isAuthenticated && invitedFirstSignIn === false ?
              <PrivateRoute>
                <NavigationComponent page={'Payment'} userData={userDataNav}></NavigationComponent>
                <PaymentPage clubs={clubs} userRole={userRole} clubData={clubDataProfile} members={members} paymentData={paymentData}/>
              </PrivateRoute> 
              :
              invitedFirstSignIn === true ?
              <PrivateRoute>
                <UpdatePass setInvitedFirstSignIn={setInvitedFirstSignIn}/>
              </PrivateRoute> 
              :
              <SignInPage/>
            }/>
          </Routes>
        </AuthProvider>
      </Router>
    </ThemeProvider>
    </div>
  );
}

export default App;

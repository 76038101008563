import HomeIcon from '@mui/icons-material/Home';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import GroupsIcon from '@mui/icons-material/Groups';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SendIcon from '@mui/icons-material/Send';
import { Payment } from '@mui/icons-material';

const routes = [
    {
        name: 'Home',
        key: 'home',
        index: 0,
        icon: <HomeIcon sx={{color:"white"}}/>,
        route: '/home',
    },
    {
        name: 'Profile',
        key: 'profile',
        index: 1,
        icon: <AccountBoxIcon sx={{color:"white"}}/>,
        route: '/profile',
    },
    {
        name: 'Club Profile',
        key: 'club_profile',
        index: 2,
        icon: <GroupsIcon sx={{color:"white"}}/>,
        route: '/club',
    },
    {
        name: 'Club Administration',
        key: 'club_administration',
        index: 3,
        icon: <LibraryBooksIcon sx={{color:"white"}}/>,
        route: '/club-administration',
    },
    {
        name: 'Calendar',
        key: 'calendar',
        index: 4,
        icon: <CalendarMonthIcon sx={{color:"white"}}/>,
        route: '/calendar',
    },
    {
        name: 'Payment',
        key: 'payment',
        index: 6,
        icon: <Payment sx={{color:"white"}}/>,
        route: '/payment',
    },
    {
        name: 'Club Invite',
        key: 'club_invite',
        index: 5,
        icon: <SendIcon sx={{color:"white"}}/>,
        route: '/club-invite',
    },
];

export default routes;
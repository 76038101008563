import React,{useState} from 'react'

import moment from 'moment-timezone';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { useTranslation } from 'react-i18next'

const ClubDetailsSignUp = (props) => {

    const { t } = useTranslation();
    const [timezoneArr, setTimezoneArr] = useState(moment.tz.names())

    return (
        <>
             <Grid container sx={{mt:2}}>
                <Grid item xs={12} md={12} sx={{m:2}} align="center"> 
                    <TextField
                        required
                        label={t('signupLabelOne')}
                        value={props.clubName}
                        onChange={(e)=>{props.setClubName(e.target.value)}}
                        error={props.errorClubName}
                        helperText={props.errorClubName ? t('signupErrorFour') : ""}
                        sx={{width:{ xs: "80%", md: "60%" },}}
                    />
                </Grid>
                <Grid item xs={12} md={12} sx={{m:2}} align="center"> 
                    <TextField
                        label={t('signupLabelTwo')}
                        value={props.website}
                        onChange={(e)=>{props.setWebsite(e.target.value)}}
                        sx={{width:{ xs: "80%", md: "60%" },}}
                    />
                </Grid>
                <Grid item xs={12} md={12} sx={{m:2}} align="center"> 
                    <TextField
                        select
                        required
                        label={t('signupLabelThree')}
                        value={props.timezone}
                        onChange={(e)=>{props.setTimezone(e.target.value)}}
                        sx={{width:{ xs: "80%", md: "60%" },}}
                        >
                        {timezoneArr.map((option) => (
                            <MenuItem key={option} value={option} color="secondary">
                            {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={12} sx={{m:2}} align="center"> 
                    <Button variant="contained" color="secondary" size="large" sx={{width: {xs: "80%", md: "60%"}}} onClick={props.handleNext}>{t('signupButtonTwo')}</Button>
                </Grid>
             </Grid>
        </>
    )
}

export default ClubDetailsSignUp
import React, {useState, useEffect} from 'react'

import { Typography, Grid, Avatar, IconButton, TextField, Box, MenuItem,Snackbar, Button, Divider } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import { Send } from '@mui/icons-material';

import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone';

import { getDatabase, ref, update} from "firebase/database";
import storage from '../../../Firebase/firebase';
import { ref as refS, uploadBytesResumable, getDownloadURL } from "firebase/storage" 

const ClubInformation = (props) => {
    const { t } = useTranslation();

    const [clubId, setClubId] = useState("");

    const [clubType, setClubType] = useState("");
    const [clubName, setClubName] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [website, setWebsite] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [timezone, setTimezone] = useState(moment.tz.guess());
    // const [timezone, setTimezone] = useState("");
    const [currency, setCurrency] = useState("RON");
    const [country, setCountry] = useState("");
    const [county, setCounty] = useState("");
    const [city, setCity] = useState("");
    const [street, setStreet] = useState("");
    const [number, setNumber] = useState("");
    const [zip, setZip] = useState("");

    const [currencyArr, setCurrencyArr] = useState(["RON","EUR"])
    const [timezoneArr, setTimezoneArr] = useState(moment.tz.names())

    const [image, setImage] = useState("");
    const [prevImg, setPrevImg] = useState("");
    const [url, setUrl] = useState('')

    const [loading, setLoading] = useState(false)
    const [buttonText, setButtonText] = useState(t("clubInfoButtonTextOne"))
    const [openSnack, setOpenSnack] = useState(false)

    const [errorName, setErrorName] = useState(false)
    const [errorTimezone, setErrorTimezone] = useState(false)
    const [errorCurrency, setErrorCurrency] = useState(false)

    useEffect(()=>{
        const cachedUrl = localStorage.getItem('clubPhoto');
        if (cachedUrl) {
          setImage(cachedUrl);
        }else if(props.clubData.image){
            setImage(props.clubData.image)
        }
        if(props.clubData.clubId){
            setClubId(props.clubData.clubId)
        }
        if(props.clubData.clubType){
            setClubType(props.clubData.clubType)
        }
        if(props.clubData.clubManagerEmail){
            setContactEmail(props.clubData.clubManagerEmail)
        }
        if(props.clubData.clubName){
            setClubName(props.clubData.clubName)
        }
        if(props.clubData.timezone){
            setTimezone(props.clubData.timezone)
        }
        if(props.clubData.website){
            setWebsite(props.clubData.website)
        }
        if(props.clubData.contactPhone){
            setContactPhone(props.clubData.contactPhone)
        }
        if(props.clubData.currency){
            setCurrency(props.clubData.currency)
        }
        if(props.clubData.country){
            setCountry(props.clubData.country)
        }
        if(props.clubData.county){
            setCounty(props.clubData.county)
        }
        if(props.clubData.city){
            setCity(props.clubData.city)
        }
        if(props.clubData.number){
            setNumber(props.clubData.number)
        }
        if(props.clubData.street){
            setStreet(props.clubData.street)
        }
        if(props.clubData.zip){
            setZip(props.clubData.zip)
        }
    },[props.clubData.clubId,props.clubData.image,props.clubData.clubManagerEmail,props.clubData.clubName,props.clubData.timezone,props.clubData.website,
        props.clubData.contactPhone,props.clubData.currency,props.clubData.country,props.clubData.county,
        props.clubData.city,props.clubData.street,props.clubData.zip,props.clubData.clubType])

    const handleImage = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
            let prev = e.target.result
            setPrevImg(prev)
            };
            reader.readAsDataURL(event.target.files[0]);
        }
        let value = event.target.files[0]
        setImage(value);
    };

    const validateName = () => {
        setErrorName(false)
        let isValid = true
        if (clubName !== ''){

        } else {
            isValid = false
            setErrorName(true)
        } 
        return isValid
    }

    const validateTimezone = () => {
        setErrorTimezone(false)
        let isValid = true
        if (timezone !== '' && timezone !== undefined){

        } else {
            isValid = false
            setErrorTimezone(true)
        } 
        return isValid
    }

    const validateCurrency = () => {
        setErrorCurrency(false)
        let isValid = true
        if (currency !== '' && currency !== undefined){

        } else {
            isValid = false
            setErrorCurrency(true)
        } 
        return isValid
    }

    const closeSnack = () => {
        setOpenSnack(false)
    };

    const saveData = () => {
        setLoading(true)
        setButtonText(t("clubInfoButtonTextOne"))
        if(validateName() && validateTimezone() && validateCurrency()){
            let uid = clubId
            const db = getDatabase();
            if(typeof image === 'object') {
                const storageRef = refS(storage, `/files/clubs/${uid}/image`);
                const uploadTask = uploadBytesResumable(storageRef, image);
                
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                    },
                    (err) => console.log(err),
                    () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        setUrl(url)
                        localStorage.setItem('clubPhoto', url);
                        const updates = {};
                        updates['/clubs/' + uid + '/image'] = url
                        updates['/clubs/' + uid + '/clubName'] = clubName
                        updates['/clubs/' + uid + '/contactPhone'] = contactPhone
                        updates['/clubs/' + uid + '/website'] = website
                        updates['/clubs/' + uid + '/timezone'] = timezone
                        updates['/clubs/' + uid + '/currency'] = currency
                        updates['/clubs/' + uid + '/country'] = country
                        updates['/clubs/' + uid + '/county'] = county
                        updates['/clubs/' + uid + '/city'] = city
                        updates['/clubs/' + uid + '/street'] = street
                        updates['/clubs/' + uid + '/number'] = number
                        updates['/clubs/' + uid + '/zip'] = zip
                        update(ref(db), updates)
                        .then(()=>{
                                setLoading(false)
                                setButtonText(t("clubInfoButtonTextTwo"))
                                setOpenSnack(true)
                        })
                    })
                })
            } else {
                const updates = {};
                updates['/clubs/' + uid + '/clubName'] = clubName
                updates['/clubs/' + uid + '/contactPhone'] = contactPhone
                updates['/clubs/' + uid + '/website'] = website
                updates['/clubs/' + uid + '/timezone'] = timezone
                updates['/clubs/' + uid + '/currency'] = currency
                // updates['/clubs/' + uid + '/country'] = country
                // updates['/clubs/' + uid + '/county'] = county
                // updates['/clubs/' + uid + '/city'] = city
                // updates['/clubs/' + uid + '/street'] = street
                // updates['/clubs/' + uid + '/number'] = number
                // updates['/clubs/' + uid + '/zip'] = zip
                update(ref(db), updates)
                .then(()=>{
                        setLoading(false)
                        setButtonText(t("clubInfoButtonTextTwo"))
                        setOpenSnack(true)
                })
            }
        } else {
            setLoading(false)
            setButtonText(t("clubInfoButtonTextOne"))
        }
    };

    return (
        <>
            <Grid container spacing={1.5}>
                <Grid item xs={6} md={6}>
                    <Typography variant="subtitle1" fontWeight={500}>{t("clubInfoTitleOne")}</Typography>
                </Grid>
                <Grid item xs={6} md={3} align="center">
                    <input accept="image/*" style={{display:"none"}} id="icon-button-file" type="file" onChange={(event) => handleImage(event)}/>
                    <label htmlFor="icon-button-file">
                        <IconButton color="primary" aria-label="upload picture" component="span">
                            <Avatar sx={{ width: 50, height: 50 }} 
                            src={prevImg !== "" ? prevImg : typeof image === "string" ? image : null} />
                        </IconButton>
                    </label>
                </Grid>
                <Box width="100%"/>
                <Grid item xs={6} md={4}>
                    <Grid container spacing={1.5}>
                        {/* <Grid item xs={12}>
                            <Typography variant="subtitle1" fontWeight={500}>Club Name</Typography>
                        </Grid> */}
                        <Grid item xs={12}>
                            <TextField
                                required
                                label={t("clubInfoLabelOne")}
                                type="text"
                                size="small"
                                variant='standard'
                                value={clubName}
                                sx={{minWidth:'65%'}}
                                onChange={(e)=>{setClubName(e.target.value)}}
                                error={errorName}
                                helperText={errorName ? t("clubInfoErrorOne") : ""}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Grid container spacing={1.5}>
                        {/* <Grid item xs={12}>
                            <Typography variant="subtitle1" fontWeight={500}>Contact E-Mail</Typography>
                        </Grid> */}
                        <Grid item xs={12}>
                            <TextField
                                label="E-mail"
                                type="email"
                                size="small"
                                variant='standard'
                                value={contactEmail}
                                sx={{minWidth:'65%'}}
                                // onChange={(e)=>{setContactEmail(e.target.value)}}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Grid container spacing={1.5}>
                        {/* <Grid item xs={12}>
                            <Typography variant="subtitle1" fontWeight={500}>Contact Phone</Typography>
                        </Grid> */}
                        <Grid item xs={12}>
                            <TextField
                                label={t("clubInfoLabelTwo")}
                                type="tel"
                                size="small"
                                variant='standard'
                                value={contactPhone}
                                sx={{minWidth:'65%'}}
                                onChange={(e)=>{setContactPhone(e.target.value)}}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Grid container spacing={1.5}>
                        {/* <Grid item xs={12}>
                            <Typography variant="subtitle1" fontWeight={500}>Website</Typography>
                        </Grid> */}
                        <Grid item xs={12}>
                            <TextField
                                label="Website"
                                type="website"
                                size="small"
                                variant='standard'
                                value={website}
                                sx={{minWidth:'65%'}}
                                onChange={(e)=>{setWebsite(e.target.value)}}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Grid container spacing={1.5}>
                        {/* <Grid item xs={12}>
                            <Typography variant="subtitle1" fontWeight={500}>Timezone</Typography>
                        </Grid> */}
                        <Grid item xs={12}>
                            <TextField
                                required
                                select
                                label={t("clubInfoLabelThree")}
                                size="small"
                                variant='standard'
                                value={timezone}
                                // defaultValue={moment.tz.guess()}
                                error={errorTimezone}
                                helperText={errorTimezone ? t("clubInfoErrorTwo") : ""}
                                onChange={(e)=>{setTimezone(e.target.value)}}
                                sx={{minWidth:'65%'}}
                            >
                            {timezoneArr.map((option) => (
                            <MenuItem key={option} value={option} color="secondary">
                            {option}
                            </MenuItem>
                            ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Grid container spacing={1.5}>
                        {/* <Grid item xs={12}>
                            <Typography variant="subtitle1" fontWeight={500}>Currency</Typography>
                        </Grid> */}
                        <Grid item xs={12}>
                            <TextField
                                required
                                select
                                label={t("clubInfoLabelFour")}
                                size="small"
                                variant='standard'
                                value={currency}
                                // defaultValue={"RON"}
                                error={errorCurrency}
                                helperText={errorCurrency ? t("clubInfoErrorThree") : ""}
                                onChange={(e)=>{setCurrency(e.target.value)}}
                                sx={{minWidth:'65%'}}
                            >
                            {currencyArr.map((option) => (
                            <MenuItem key={option} value={option} color="secondary">
                            {option}
                            </MenuItem>
                            ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} sx={{mt:2}} align="center"> 
                    <LoadingButton
                                onClick={saveData}
                                endIcon={<Send/>}
                                loadingPosition="end"
                                loading={loading}
                                variant="contained"
                                color="primary" 
                                size="small" 
                                sx={{width: {xs: "80%", md: "40%",color:"#fff"}}}
                            >
                                {buttonText}
                    </LoadingButton>
                </Grid>
                {/* <Grid item xs={6} md={3}>
                    <Typography variant="subtitle1" fontWeight={500}>Full Address</Typography>
                </Grid>
                <Box width="100%"/>
                <Grid item xs={6} md={4}>
                    <TextField
                        label="Country"
                        type="text"
                        size="small"
                        value={country}
                        onChange={(e)=>{setCountry(e.target.value)}}
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <TextField
                        label="County"
                        type="text"
                        size="small"
                        value={county}
                        onChange={(e)=>{setCounty(e.target.value)}}
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <TextField
                        label="City"
                        type="text"
                        size="small"
                        value={city}
                        onChange={(e)=>{setCity(e.target.value)}}
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <TextField
                        label="Street"
                        type="text"
                        size="small"
                        value={street}
                        onChange={(e)=>{setStreet(e.target.value)}}
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <TextField
                        label="Number"
                        type="number"
                        size="small"
                        value={number}
                        onChange={(e)=>{setNumber(e.target.value)}}
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <TextField
                        label="Zip Code"
                        type="number"
                        size="small"
                        value={zip}
                        onChange={(e)=>{setZip(e.target.value)}}
                    />
                </Grid> */}
            </Grid>
            <Snackbar
                open={openSnack}
                autoHideDuration={3000}
                onClose={closeSnack}
                message={t("clubInfoSnack")}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
        </>
    )
}

export default ClubInformation


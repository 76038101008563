import React from "react";

import { Avatar, Card, CardContent, Divider, Typography } from "@mui/material";

import { useTranslation } from 'react-i18next'

const MatchCard= (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <Card sx={{maxWidth:"500px",backgroundColor:"white", height: '135px'}}>
        <CardContent >
            <Typography variant={"h6"} color="primary.contrastText">
                {t('homeCardTwoTitle')}
            </Typography>
            <Typography display="inline" variant={"subtitle1"} color="primary.contrastText">
                {Object.keys(props.firstMatch).length !== 0?
                  t('homeCardTwoSubtitleOne')
                  :
                  t('homeCardTwoSubtitleTwo')
                }
            </Typography>
            <Typography display="inline" variant={"subtitle1"} color="primary">
                {Object.keys(props.firstMatch).length !== 0 ? 
                  props.firstMatch.date 
                  :
                  ""
                }
            </Typography>
            <Divider  light />
            {Object.keys(props.firstMatch).length !== 0 ?
              <>
              <Typography display="inline" variant={"subtitle1"} color="primary.contrastText" maxWidth="500px">
                  {props.firstMatch.opponent ? t('homeCardTwoTextOne') : t('homeCardTwoTextTwo')}
              </Typography>
              <Typography display="inline" variant={"subtitle1"} color="primary">
                  {props.firstMatch.opponent ? props.firstMatch.opponent : props.firstMatch.location}
              </Typography>
              </>
              :
              <></>
            }
        </CardContent>
      </Card>
    </div>
  );
}

export default MatchCard;
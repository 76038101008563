import React, {useEffect, useState} from 'react'

import { Grid, Card, Typography, Button, Snackbar, CardContent, Box, LinearProgress } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { getPortalUrl } from '../../../stripe/StripeGetUrl';
import { collection, query, getDocs} from "firebase/firestore";
import { firestoreDb } from '../../../Firebase/firebase';

import { useAuthValue } from '../../../Firebase/AuthContext';

import PlansDialog from './PlansDialog';

import { plans } from '../../../utils/plans_data'

import { useTranslation } from 'react-i18next';

const OverviewComponent = (props) => {
    const { t } = useTranslation();

    const auth = useAuthValue()

    const [clubData, setClubData] = useState([])
    const [clubId, setClubId] = useState([])
    const [clubType, setClubType] = useState("")
    const [currentProgress, setCurrentProgress] = useState(0)
    const [planIndex, setPlanIndex] = useState(0)
    const [planAccounts, setPlanAccounts] = useState(0)
    const [planPrice, setPlanPrice] = useState("")
    const [dueDate, setDueDate] = useState("")
    const [currentInvites, setCurrentInvites] = useState("")

    const [openDialog, setOpenDialog] = useState(false);
    const [openSnack, setOpenSnack] = useState(false)

    const [subscriptionDate, setSubscriptionDate] = useState("");
    const [subscriptionPeriodEnd, setSubscriptionPeriodEnd] = useState("");
    const [subscriptionStatus, setSubscriptionStatus] = useState("");
    const [subscriptionAmount, setSubscriptionAmount] = useState("");
    const [subscriptionName, setSubscriptionName] = useState("")
    const [subscriptionCancelled, setSubscriptionCancelled] = useState(false)
    
    useEffect(()=>{
        if(props.clubData){
            setCurrentInvites(props.clubData.clubInvites)
            setClubData(props.clubData)
            setClubId(props.clubData.clubId)
            setClubType(props.clubData.clubType)

            let type = props.clubData.clubType

            const subscriptionPlan = plans.find(plan => plan.name === type);
            const index = plans.indexOf(subscriptionPlan);
            setPlanIndex(index)

            setPlanAccounts(subscriptionPlan.accounts)
            setPlanPrice(subscriptionPlan.price)

            const [minAccounts, maxAccounts] = Array.isArray(subscriptionPlan.accounts)
            ? subscriptionPlan.accounts
            : [0, subscriptionPlan.accounts];
    
            const currentProgress = (props.clubData.clubInvites - minAccounts) / (maxAccounts - minAccounts) * 100;
            setCurrentProgress(currentProgress)
        

            const accountCreationDate = props.clubData.dateCreated
            
            const [day, month, year] = accountCreationDate.split("/").map(Number);
            const currentDate = new Date();
            const dueDate = new Date(currentDate);
            dueDate.setDate(day);

            if (currentDate.getDate() > day) {
                dueDate.setMonth(dueDate.getMonth() + 1);

                if (day > 28 && dueDate.getDate() < day) {
                    dueDate.setDate(0); 
                }
            }

            const formattedDueDate =
                dueDate.getDate().toString().padStart(2, "0") +
                "/" +
                (dueDate.getMonth() + 1).toString().padStart(2, "0") +
                "/" +
                dueDate.getFullYear();

            setDueDate(formattedDueDate)

        }
    },[props.clubData])

    useEffect(() => {
        if(props.clubData.clubType !== 'Demo'){
            let userId = auth.currentUser.uid
        
            const db = firestoreDb;

            const subscriptionsCollection = collection(db, `customers/${userId}/subscriptions`);
            if(subscriptionsCollection){
                const q = query(subscriptionsCollection);
        
            getDocs(q)
            .then((querySnapshot) => {
                if(querySnapshot){
                    const fetchedSubscriptions = [];
                    querySnapshot.forEach((doc) => {
                    fetchedSubscriptions.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                    });
                    if(fetchedSubscriptions[0]){
                        const date_created = new Date(fetchedSubscriptions[0].data.created.seconds * 1000);
                        const day_created = date_created.getDate().toString().padStart(2, '0'); 
                        const month_created = (date_created.getMonth() + 1).toString().padStart(2, '0'); 
                        const year_created = date_created.getFullYear();
            

                        const date_end= new Date(fetchedSubscriptions[0].data.current_period_end.seconds * 1000);
                        const day_end = date_end.getDate().toString().padStart(2, '0'); 
                        const month_end = (date_end.getMonth() + 1).toString().padStart(2, '0'); 
                        const year_end = date_end.getFullYear();

                        setSubscriptionDate(`${day_created}/${month_created}/${year_created}`);
                        setSubscriptionPeriodEnd(`${day_end}/${month_end}/${year_end}`);
                        setSubscriptionStatus(fetchedSubscriptions[0].data.status)
                        setSubscriptionAmount(fetchedSubscriptions[0].data.items[0].price.unit_amount/100)
                        setSubscriptionName(fetchedSubscriptions[0].data.items[0].price.product.name)

                        if(fetchedSubscriptions[0].data.cancel_at_period_end === true) {
                            setSubscriptionCancelled(true)
                        }
                    }
                }
            })
            .catch((error) => {
                // Handle error
                console.error("Error getting subscriptions: ", error);
            });
            }
        } else {
            setSubscriptionDate(props.clubData.dateCreated);
            setSubscriptionPeriodEnd("-");
            setSubscriptionStatus("Ongoing")
            setSubscriptionAmount("-")
            setSubscriptionName("Demo")
        }
    }, []); 

    const manageSubscription = async () => {
        const portalUrl = await getPortalUrl();
        window.location.href = portalUrl
    };

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClickClose = () => {
        setOpenDialog(false);
    };

    const closeSnack = () => {
        setOpenSnack(false)
    };

    return (
        <>
            <Grid container spacing={1} sx={{width:{xs:'90vw',md:'100%'},mt:2}}>
                <Grid item xs={12} md={7}>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="subtitle2" color="primary" align="center"
                                            sx={{
                                                display: 'inline-block',
                                                padding: '4px 8px',
                                                backgroundColor: '#303030',
                                                color: 'white',
                                                borderRadius: '4px',
                                                width: {md:'10vw', xs:'25vw'},
                                                textTransform: 'uppercase'
                                                }}
                                        >{subscriptionName}</Typography>
                                        <Typography variant="subtitle2" color="black" marginLeft='5px'>Plan - {subscriptionStatus}</Typography>
                                    </Box>
                                    {clubData.clubType === "Elite" ? 
                                        <Typography sx={{mt:6}} variant="subtitle2" color="black">Unlimited accounts</Typography>
                                        :
                                        <Typography sx={{mt:6}} variant="subtitle2" color="black">{clubData.clubInvites} of {planAccounts} users</Typography>
                                    }
                                    <LinearProgress variant="determinate" value={currentProgress} sx={{ width: '100%'}} />
                                </Grid>
                                <Grid item xs={6} align="center">
                                    <Typography variant="h5" color="black">{subscriptionAmount}</Typography>
                                    <Typography variant="subtitle1" color="black">RON / month</Typography>
                                    <Button 
                                        variant="contained" 
                                        size="small" 
                                        endIcon={<ArrowForwardIcon/>} 
                                        sx={{mt:3, color:'white'}}
                                        onClick={handleClickOpen}
                                    >
                                        {t('paymentButtonOne')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                {clubType!=="Demo" &&
                <Grid item xs={12} md={5}>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12} align="left">
                                {subscriptionCancelled ? 
                                    <Typography variant="caption" color="grey">{t('paymentTextFour')}</Typography>
                                    :
                                    <Typography variant="caption" color="grey">{t('paymentTextFive')}</Typography>
                                }
                                    <Typography variant="subtitle2" color="black">{t('paymentTextSix')} {subscriptionPeriodEnd}</Typography>
                                    <Button 
                                        variant="outlined" 
                                        size="small"
                                        sx={{mt:5}}
                                        onClick={manageSubscription}
                                    >
                                        {t('paymentButtonTwo')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>}
            </Grid>
            <PlansDialog 
                openDialog={openDialog} 
                setOpenSnack={setOpenSnack} 
                clubId={clubId} 
                clubType={clubType}
                setClubType={setClubType}
                clubInvites={currentInvites}
                planIndex={planIndex}
                setSubscriptionName={setSubscriptionName}
                setSubscriptionAmount={setSubscriptionAmount}
                handleClickClose={handleClickClose}/>
            <Snackbar
                open={openSnack}
                autoHideDuration={3000}
                onClose={closeSnack}
                message={t('paymentSnackOne')}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
        </>
    )
}

export default OverviewComponent


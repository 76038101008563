import React from 'react'
import SignIn from '../components/SignIn'

const SignInPage = () => {
    return (
        <>
            <SignIn/>
        </>
    )
}

export default SignInPage
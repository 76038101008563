import React from 'react'

import { Box, Toolbar, Grid } from '@mui/material'

import ClubInformation from './components/ClubInformation'

const Club = (props) => {
    const drawerWidth = 240;
    
    return (
        <>
            <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                    <Toolbar />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <ClubInformation clubData={props.clubData}/>
                        </Grid>
                    </Grid>
            </Box>
        </>
    )
}

export default Club


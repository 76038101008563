import React, {useState, useEffect} from 'react'

import { Typography, Grid, Avatar, IconButton, TextField, Snackbar, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import { Send } from '@mui/icons-material';

import { useTranslation } from 'react-i18next'

import {auth} from '../../../Firebase/firebase'
import {updateEmail} from 'firebase/auth'
import { getDatabase, ref, update} from "firebase/database";
import storage from '../../../Firebase/firebase';
import { ref as refS, uploadBytesResumable, getDownloadURL } from "firebase/storage" 

const UserInformation = (props) => {

    const { t } = useTranslation();
    
    const [firstEmail, setFirstEmail] = useState("")

    const [clubId, setClubId] = useState("");

    const [role, setRole] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [fedNumber, setFedNumber] = useState("");
    const [fedSeries, setFedSeries] = useState("");

    const [image, setImage] = useState("");
    const [prevImg, setPrevImg] = useState("");
    const [url, setUrl] = useState('')

    const [loading, setLoading] = useState(false)
    const [buttonText, setButtonText] = useState(t('profileButtonTextOne'))
    const [openSnack, setOpenSnack] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)

    const [errorEmail, setErrorEmail] = useState(false)
    const [errorFirstName, setErrorFirstName] = useState(false)
    const [errorLastName, setErrorLastName] = useState(false)

    useEffect(()=>{

        const cachedUrl = localStorage.getItem('userPhoto');
        if (cachedUrl) {
          setImage(cachedUrl);
        }else if(props.userData.image){
            setImage(props.userData.image)
        }
        
        if(props.userData.email){
            setFirstEmail(props.userData.email)
            setEmail(props.userData.email)
        }
        if(props.userData.clubId){
            setClubId(props.userData.clubId)
        }
        if(props.userData.role){
            setRole(props.userData.role)
        }
        if(props.userData.firstName){
            setFirstName(props.userData.firstName)
        }
        if(props.userData.lastName){
            setLastName(props.userData.lastName)
        }
        if(props.userData.phone){
            setPhone(props.userData.phone)
        }
        if(props.userData.fedNumber){
            setFedNumber(props.userData.fedNumber)
        }
        if(props.userData.fedSeries){
            setFedSeries(props.userData.fedSeries)
        }
    },[props.userData.clubId,props.userData.image,props.userData.email, props.userData.role, props.userData.firstName, props.userData.lastName, props.userData.phone, props.userData.fedNumber, props.userData.fedSeries])


    const handleImage = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
            let prev = e.target.result
            setPrevImg(prev)
            };
            reader.readAsDataURL(event.target.files[0]);

            let value = event.target.files[0]
            setImage(value);
          }
    };

    const closeSnack = () => {
        setOpenSnack(false)
    };

    const validateEmail= () => {
        setErrorEmail(false)
        let isValid = true
        if (email !== ''){
          if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            isValid = false
            setErrorEmail(true)
          }
        } else {
            isValid = false
            setErrorEmail(true)
        }
        return isValid
    }

    const validateName = () => {
        setErrorFirstName(false)
        setErrorLastName(false)
        let isValid = true
        if (firstName !== '' && lastName !== ''){

        } else if (firstName === ''){
            isValid = false
            setErrorFirstName(true)
        } else if (lastName === ''){
            isValid = false
            setErrorLastName(true)
        }
        return isValid
    }

    const handleCloseDialogCancel = () => {
        setOpenDialog(false);
        setLoading(false)
        setButtonText(t('profileButtonTextOne'))
        setEmail(firstEmail)
    };

    const handleCloseDialogNo = () => {
        setOpenDialog(false);
        setLoading(false)
        setButtonText(t('profileButtonTextOne'))
        setEmail(firstEmail)
    };

    const handleCloseDialogYes = () => {
        setOpenDialog(false);

        if(validateName() && validateEmail()){
            let uid = auth.currentUser.uid
            const db = getDatabase();
            
            if(typeof image === 'object') {
                const storageRef = refS(storage, `/files/users/${uid}/image`);
                const uploadTask = uploadBytesResumable(storageRef, image);
                
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                    },
                    (err) => console.log(err),
                    () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        setUrl(url)
                        localStorage.setItem('userPhoto', url);

                        const updates = {};
                        updates['/users/' + uid + '/firstName'] = firstName
                        updates['/users/' + uid + '/lastName'] = lastName
                        updates['/users/' + uid + '/email'] = email
                        updates['/users/' + uid + '/phone'] = phone
                        updates['/users/' + uid + '/fedNumber'] = fedNumber
                        updates['/users/' + uid + '/fedSeries'] = fedSeries
                        updates['/users/' + uid + '/image'] = url
                        update(ref(db), updates)
                        .then(()=>{
                            if(email!==firstEmail){
                                updateEmail(auth.currentUser, email).then(()=>{
                                    const db = getDatabase();
                                    const updates = {};
                                    updates['/clubs/' + clubId + '/clubManagerEmail'] = email
                                    update(ref(db), updates)
                                })
                                .then(() => {
                                    setLoading(false)
                                    setButtonText(t('profileButtonTextTwo'))
                                    setOpenSnack(true)
                                }).catch((error) => {
                                    console.log(error)
                                });
                            } else {
                                setLoading(false)
                                setButtonText(t('profileButtonTextTwo'))
                                setOpenSnack(true)
                            }
                        })
                    })
                    }
                )
            } else {

                const updates = {};
                updates['/users/' + uid + '/firstName'] = firstName
                updates['/users/' + uid + '/lastName'] = lastName
                updates['/users/' + uid + '/email'] = email
                updates['/users/' + uid + '/phone'] = phone
                updates['/users/' + uid + '/fedNumber'] = fedNumber
                updates['/users/' + uid + '/fedSeries'] = fedSeries
                update(ref(db), updates)
                .then(()=>{
                    if(email!==firstEmail){
                        updateEmail(auth.currentUser, email).then(()=>{
                            const db = getDatabase();
                            const updates = {};
                            updates['/clubs/' + clubId + '/clubManagerEmail'] = email
                            update(ref(db), updates)
                        })
                        .then(() => {
                            setLoading(false)
                            setButtonText(t('profileButtonTextTwo'))
                            setOpenSnack(true)
                        }).catch((error) => {
                            console.log(error)
                        });
                    } else {
                        setLoading(false)
                        setButtonText(t('profileButtonTextTwo'))
                        setOpenSnack(true)
                    }
                })
            }
        } else {
            setLoading(false)
            setButtonText(t('profileButtonTextOne'))
        }

    };

    const saveData = () => {
        setLoading(true)
        setButtonText(t('profileButtonTextOne'))
        if(email !== firstEmail && validateEmail() && role!=="Club Manager") {
            setOpenDialog(true)
        } else {
            if(validateName() && validateEmail()){
                let uid = auth.currentUser.uid
                const db = getDatabase();
                if(typeof image === 'object') {
                    const storageRef = refS(storage, `/files/users/${uid}/image`);
                    const uploadTask = uploadBytesResumable(storageRef, image);
                    
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                        },
                        (err) => console.log(err),
                        () => {
                        // download url
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            setUrl(url)
                            localStorage.setItem('userPhoto', url);

                            const updates = {};
                            updates['/users/' + uid + '/firstName'] = firstName
                            updates['/users/' + uid + '/lastName'] = lastName
                            updates['/users/' + uid + '/email'] = email
                            updates['/users/' + uid + '/phone'] = phone
                            updates['/users/' + uid + '/fedNumber'] = fedNumber
                            updates['/users/' + uid + '/fedSeries'] = fedSeries
                            updates['/users/' + uid + '/image'] = url
                            update(ref(db), updates)
                            .then(()=>{
                                    setLoading(false)
                                    setButtonText("Done")
                                    setOpenSnack(true)
                            })
                        })
                        }
                    )
                } else {
                    const updates = {};
                    updates['/users/' + uid + '/firstName'] = firstName
                    updates['/users/' + uid + '/lastName'] = lastName
                    updates['/users/' + uid + '/email'] = email
                    updates['/users/' + uid + '/phone'] = phone
                    updates['/users/' + uid + '/fedNumber'] = fedNumber
                    updates['/users/' + uid + '/fedSeries'] = fedSeries
                    update(ref(db), updates)
                    .then(()=>{
                            setLoading(false)
                            setButtonText(t('profileButtonTextTwo'))
                            setOpenSnack(true)
                    })
                }
            } else {
                setLoading(false)
                setButtonText(t('profileButtonTextOne'))
            }
        }
    };
    
    return (
        <>
            <Grid container spacing={1.5}>
                <Grid item xs={6}>
                    <Typography variant="subtitle1" fontWeight={500}>{t('profileTitleOne')}</Typography>
                    <Typography variant="caption" color="primary" fontStyle="italic" fontWeight="bold">{role}</Typography>
                </Grid>
                <Grid item xs={6} align="center">
                    <input accept="image/*" style={{display:"none"}} id="icon-button-file" type="file" onChange={(event) => handleImage(event)}/>
                    <label htmlFor="icon-button-file">
                        <IconButton color="primary" aria-label="upload picture" component="span">
                            <Avatar sx={{ width: 50, height: 50 }} 
                            src={prevImg !== "" ? prevImg : typeof image === "string" ? image : null} />
                        </IconButton>
                    </label>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" fontWeight={500}>{t('profileTextFieldOne')}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        label={t('profileLabelOne')}
                        type="text"
                        size="small"
                        value={firstName}
                        error={errorFirstName}
                        helperText={errorFirstName ? t('profileErrorOne') : ""}
                        onChange={(e)=>{setFirstName(e.target.value)}}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        label={t('profileLabelTwo')}
                        type="text"
                        size="small"
                        value={lastName}
                        error={errorLastName}
                        helperText={errorLastName ? t('profileErrorOne') : ""}
                        onChange={(e)=>{setLastName(e.target.value)}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" fontWeight={500}>{t('profileTextFieldTwo')}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        label="Email"
                        type="email"
                        size="small"
                        value={email}
                        error={errorEmail}
                        helperText={errorEmail ? t('profileErrorTwo') : ""}
                        onChange={(e)=>{setEmail(e.target.value)}}
                        disabled={(role==="Club Manager" || role ==="Owner") ? true : false}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label={t('profileLabelThree')}
                        type="tel"
                        size="small"
                        value={phone}
                        onChange={(e)=>{setPhone(e.target.value)}}
                    />
                </Grid>
                {role !== "Parent" &&
                <>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" fontWeight={500}>{t('profileTextFieldThree')}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label={t('profileLabelFour')}
                        type="text"
                        size="small"
                        value={fedNumber}
                        onChange={(e)=>{setFedNumber(e.target.value)}}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label={t('profileLabelFive')}
                        type="text"
                        size="small"
                        value={fedSeries}
                        onChange={(e)=>{setFedSeries(e.target.value)}}
                    />
                </Grid>
                </>
                }
                <Grid item xs={12} md={12} sx={{m:2}} align="center"> 
                    <LoadingButton
                            onClick={saveData}
                            endIcon={<Send/>}
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                            color="primary" 
                            size="small" 
                            sx={{width: {xs: "80%", md: "60%",color:"#fff"}}}
                        >
                            {buttonText}
                    </LoadingButton>
                </Grid>
            </Grid>
            <Snackbar
                open={openSnack}
                autoHideDuration={3000}
                onClose={closeSnack}
                message={t('profileSnack')}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
            <Dialog
                open={openDialog}
                onClose={handleCloseDialogCancel}
            >
                <DialogTitle color="secondary">
                {t('profileDialogTitle')}
                </DialogTitle>
                <DialogContent>
                <DialogContentText>
                    {t('profileDialogText')}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseDialogNo} color="secondary" variant="outlined">{t('profileDialogButtonTextTwo')}</Button>
                <Button onClick={handleCloseDialogYes} autoFocus variant="contained">
                    {t('profileDialogButtonTextOne')}
                </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default UserInformation


import React, {useState,useEffect} from 'react'

import { Typography, Box, Toolbar, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import TrainingCard from './components/TrainingCard'
import MatchCard from './components/MatchCard'
import PendingInvites from './components/PendingInvites'

import moment from 'moment-timezone';

const Home = (props) => {
    const { t } = useTranslation();

    const drawerWidth = 240;

    const [uidMaster, setUidMaster] = useState("")
    const [role, setRole] = useState("")
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [firstTraining, setFirstTraining] = useState({})
    const [firstMatch, setFirstMatch] = useState({})
    const [timezone, setTimezone] = useState("");

    useEffect(()=>{
        if(props.userData.uidMaster){
            setUidMaster(props.userData.uidMaster)
        }
        if(props.userData.role){
            setRole(props.userData.role)
        }
        if(props.userData.firstName){
            setFirstName(props.userData.firstName)
        }
        if(props.userData.lastName){
            setLastName(props.userData.lastName)
        }
        if(props.userData.timezone){
            setTimezone(props.userData.timezone)
        }
        if(props.userData.invites){
            let inv = props.userData.invites
            let keys = Object.keys(inv)
            let val = Object.values(inv)

            let locations = props.userData.locations
            let locKeys = Object.keys(locations)
            let locVals = Object.values(locations)

            for(let i = 0 ; i < val.length; i++){
                val[i].uid = keys[i];
                if(val[i].locationType === 'club'){
                    for(let j = 0; j < locVals.length; j++){
                        if(locKeys[j] === val[i].location){
                            val[i].location = locVals[j].name;
                            break; 
                        }
                    }
                }
            }

            const newEvents = [];
            for(let i = 0 ; i < val.length; i++){
                if(val[i].status === "joined") {
                    let calculateStatus = ""
                    if(val[i].endDate) {
                        calculateStatus = val[i].endDate
                    } else if (val[i].date){
                        calculateStatus = val[i].date
                    }
                    // const momentDate = moment.tz(calculateStatus, 'DD/MM/YYYY', props.userData.timezone);
                    // const momentNow = moment.tz(props.userData.timezone);
                    // if (!momentDate.isBefore(momentNow, 'day')){
                    const momentDate = moment.tz(calculateStatus + ' ' + val[i].startHour, 'DD/MM/YYYY HH:mm', props.userData.timezone);
                    const momentNow = moment.tz(props.userData.timezone);

                    if (!momentDate.isBefore(momentNow, 'minute')) {
                        let ev = {}
                        val[i].uid = keys[i];

                        const date = moment.tz(val[i].date, "DD/MM/YYYY", timezone);
                        const startTime = moment(val[i].startHour, "HH:mm");
                        const endTime = moment(val[i].endHour, "HH:mm");
                        const start = moment.tz(date.format('YYYY-MM-DD') + 'T' + startTime.format('HH:mm:ss'), timezone).format();
                        const end = moment.tz(date.format('YYYY-MM-DD') + 'T' + endTime.format('HH:mm:ss'), timezone).format();
                        ev = {
                            title: val[i].name,
                            start: start,
                            end: end,
                        }
                        if (val[i].recurrent) {
                            const endDate = moment.tz(val[i].endDate, "DD/MM/YYYY", timezone);
                            const repeatEvery = val[i].repeatEvery; 
                            let nextDate = moment.tz(date.format('YYYY-MM-DD'), timezone);
                            while (nextDate.isSameOrBefore(endDate)) {
                            if (nextDate.format('dddd') === repeatEvery) {
                                // const recurringStart = moment.tz(nextDate.format('YYYY-MM-DD') + 'T' + startTime.format('HH:mm:ss'), timezone).format();
                                // const recurringEnd = moment.tz(nextDate.format('YYYY-MM-DD') + 'T' + endTime.format('HH:mm:ss'), timezone).format();
                                // const recurringEv = val[i]
                                const recurringStart = moment.tz(nextDate.format('YYYY-MM-DD') + 'T' + val[i].startHour, timezone).format();
                                const recurringEnd = moment.tz(nextDate.format('YYYY-MM-DD') + 'T' + val[i].endHour, timezone).format();
                    
                                const recurringEv = { ...val[i] };
                                recurringEv.start = recurringStart;
                                recurringEv.end = recurringEnd;
                                newEvents.push(recurringEv);
                            }
                            nextDate.add(1, 'days');
                            }
                        } else {
                            newEvents.push(val[i]);
                        }
                    }
                }
            }
           
            const currentDate = new Date();
            const upcomingEvents = newEvents
            .filter(event => new Date(event.start) > currentDate)
            .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());

            const firstTraining = upcomingEvents[0];
            if(firstTraining !== undefined){
                if(!firstTraining.start){
                    firstTraining.showDate=firstTraining.date
                } else {
                    let newDate = firstTraining.start
                    const inputDate = new Date(newDate);

                    const formattedDate = new Intl.DateTimeFormat('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                    }).format(inputDate);

                    firstTraining.showDate = formattedDate
                }
                setFirstTraining(firstTraining)
            }
            const joinedMatchEvents = val
            .filter(event => event.eventType === "match" && event.status === "joined")
            .sort((a, b) => {
                const aDate = new Date(`${a.date.split("/")[2]}-${a.date.split("/")[1]}-${a.date.split("/")[0]}T${a.startHour}:00`);
                const bDate = new Date(`${b.date.split("/")[2]}-${b.date.split("/")[1]}-${b.date.split("/")[0]}T${b.startHour}:00`);
                return aDate - bDate;
            });

            const firstMatch = joinedMatchEvents[0];
            if(firstMatch !== undefined){
                setFirstMatch(firstMatch)
            }
        }
    },[props.userData.firstName, props.userData.lastName,props.userData.invites,props.userData.locations,
        props.userData.uidMaster,props.userData.role,props.userData.timezone])
    
    return (
        <>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TrainingCard firstTraining={firstTraining}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MatchCard firstMatch={firstMatch}/>
                    </Grid>
                    {role !== "Parent" && 
                        <Grid item xs={12} md={12}>
                            <Typography variant="caption" fontWeight={500}>{t('homeSubtitleOne')}</Typography>
                            <PendingInvites uidMaster={uidMaster} invites={props.userData.invites} locations={props.userData.locations} timezone={props.userData.timezone}/>
                        </Grid>
                    }
                </Grid>
            </Box>
        </>
    )
}

export default Home


import React, {useState,useEffect} from 'react'

import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import { Send } from '@mui/icons-material';

import {Link} from 'react-router-dom'

import logo_main from '../../assets/logo/White_p.png'

import pic_2 from '../../assets/login/login_2.jpg'

import {signInWithEmailAndPassword, sendEmailVerification,sendPasswordResetEmail} from 'firebase/auth'
import {auth} from '../../Firebase/firebase'
import { child, getDatabase, ref, get } from "firebase/database";
import {useNavigate} from 'react-router-dom'
import {useAuthValue} from '../../Firebase/AuthContext'
import { createManagerAccount, paySubscription } from '../../stripe/PaymentFunctions';
import { getSubscriptionStatus } from '../../stripe/StripeGetUrl';

import { plans } from '../../utils/plans_data';

const SignIn = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [forgotPass, setForgotPass] = useState(false)
    const [emailForgotPass, setEmailForgotPass] = useState('') 
    const [textReset, setTextReset] = useState('Reset Password')

    const [loading, setLoading] = useState(false)

    const [error, setError] = useState('')

    const {setTimeActive} = useAuthValue()
    const navigate = useNavigate()

    // const [hasSubscription, setHasSubscription] = useState(true)

    useEffect(() => {
    //   const checkPremium = async () => {
    //     // const status = auth.currentUser
    //     //   ? await getSubscriptionStatus()
    //     //   : false;
    //     setHasSubscription(status);
    //   };
    //   const status = getSubscriptionStatus(auth.currentUser)
    //   console.log(status)
    //   setHasSubscription(status);
    //   checkPremium();
    }, [auth.currentUser?.uid]);

    const triggerResetPassword = async () => {
        setLoading(true)
        await sendPasswordResetEmail(auth, emailForgotPass);
        setTextReset("Email for changing password sent.")
        setLoading(false)
    }

    const login = () => {
        // e.preventDefault()
        signInWithEmailAndPassword(auth, email, password)
        .then(() => {
        if(!auth.currentUser.emailVerified) {
            sendEmailVerification(auth.currentUser)
            .then(() => {
            setTimeActive(true)
            navigate('/verify-email')
            })
        .catch(err => alert(err.message))
        }else{
            const dbRef = ref(getDatabase());
            let uid = auth.currentUser.uid;
            get(child(dbRef, `users/${uid}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    var data_user = snapshot.val()
                    if(data_user.clubId){
                        get(child(dbRef, `clubs/${data_user.clubId}`)).then((snapshot_club) => {
                            if (snapshot_club.exists()) {
                                var data_club = snapshot_club.val()
                                if(data_club.restricted === true && data_user.role !== 'Owner'){
                                    navigate('/restricted')
                                } else if(data_user.invitedFirstSignIn && data_user.invitedFirstSignIn === true){
                                    navigate('/update-password')
                                } else {
                                    let hasSubscription = getSubscriptionStatus(auth.currentUser.uid)
                                    if(data_user.role === "Club Manager" && data_club.clubType !=="Demo" && !hasSubscription){
                                        const subscriptionPlan = plans.find(plan => plan.name === data_club.clubType);
                                        if(subscriptionPlan){
                                            paySubscription("sign-in",subscriptionPlan.stripeId)
                                        }
                                    } else if(data_user.role === "Club Manager" && !data_user.stripeAccount){
                                        createManagerAccount(uid,data_user.clubId)
                                    } else {
                                        navigate('/home')
                                    }
                                }
                            }
                        })
                    }
                }
            })
        }
        })
        .catch(err => setError(err.message))
    }


    return (
        <>
            <Grid container sx={{width:'100vw',height:'100vh', overflow:'hidden'}}>
                <Grid item xs={12} md={6}> 
                    <Grid container align="center">
                        <Grid item xs={12}>
                            <img
                                src={logo_main}
                                alt={"Logo"}
                                style={{width: '120px', height: '120px',textAlign:'center', marginTop:'20px'}}
                            />
                        </Grid>
                        {forgotPass ? 
                        <>
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{fontWeight:'400'}}>Forgot password</Typography>
                            <Typography variant="subtitle1" sx={{fontWeight:'300'}}>Please enter your account's email.</Typography>
                        </Grid>
                        <Grid item xs={12}> 
                            <TextField
                                required
                                label="Email"
                                value={emailForgotPass}
                                type="email"
                                onChange={(e)=>{setEmailForgotPass(e.target.value)}}
                                sx={{width:{ xs: "80%", md: "60%" }, mt:4}}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{mt:4}}>
                            <LoadingButton
                                onClick={triggerResetPassword}
                                endIcon={<Send/>}
                                loading={loading}
                                loadingPosition="end"
                                disabled={textReset==='Reset Password' ? false : true}
                                variant="contained" 
                                color="secondary"
                                size="large" 
                                sx={{width: {xs: "80%", md: "60%"}}}
                            >
                                {textReset}
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={12} sx={{mt:3}}> 
                            <Divider variant="middle" sx={{color:'rgba(0, 0, 0, 0.12)',width:'80%'}}> OR </Divider>
                        </Grid>
                        <Grid item xs={12} sx={{mt:2}}>
                            <Button onClick={()=>{setForgotPass(false)}} color="secondary" size="small" sx={{width: {xs: "80%", md: "60%"}}}>Back to Sign In</Button>
                        </Grid>
                        </>
                        :
                        <>
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{fontWeight:'400'}}>Welcome back</Typography>
                            <Typography variant="subtitle1" sx={{fontWeight:'300'}}>Please enter your account's details.</Typography>
                            <Typography variant="caption" color="error" sx={{fontWeight:'500'}}>{error}</Typography>
                        </Grid>
                        <Grid item xs={12}> 
                            <TextField
                                required
                                label="Email"
                                value={email}
                                type="email"
                                onChange={(e)=>{setEmail(e.target.value)}}
                                sx={{width:{ xs: "80%", md: "60%" }, mt:4}}
                            />
                        </Grid>
                        <Grid item xs={12}> 
                            <TextField
                                required
                                label="Password"
                                value={password}
                                type="password"
                                onChange={(e)=>{setPassword(e.target.value)}}
                                sx={{width:{ xs: "80%", md: "60%" }, mt:4}}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{mt:1}}> 
                            <Button onClick={()=>{setForgotPass(true)}} color="secondary" size="small" sx={{width: {xs: "80%", md: "60%"}}}>Forgot Password?</Button>
                        </Grid>
                        <Grid item xs={12} sx={{mt:1}}> 
                            <Button onClick={login} variant="contained" color="secondary" size="large" sx={{width: {xs: "80%", md: "60%"}}}>Sign In</Button>
                        </Grid>
                        <Grid item xs={12} sx={{mt:3}}> 
                            <Divider variant="middle" sx={{color:'rgba(0, 0, 0, 0.12)',width:'80%'}}> OR </Divider>
                        </Grid>
                        <Grid item xs={12} sx={{mt:2}}>
                            <Typography 
                                variant="caption" 
                                sx={{fontWeight:'500', color:'rgba(0, 0, 0, 0.60)'}}
                            >
                                <Link to="/sign-up" style={{ textDecoration: 'none',color:'rgba(0, 0, 0, 0.60)' }}>
                                    Sign Up
                                </Link>
                            </Typography>
                        </Grid>
                        </>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption" sx={{m:2,position:'absolute', bottom:'0px'}}>© Club Manager 2023</Typography>
                    </Grid>
                </Grid>
                <Grid item md={6} sx={{display: { xs: 'none', md: 'block' }}}> 
                    <img
                        src={pic_2}
                        alt={"Sport"}
                        style={{width: '100%', height: '100vh', borderTopLeftRadius:'20px', borderBottomLeftRadius:'20px'}}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default SignIn
import React from 'react'

import {Divider, Grid, TextField, Button} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Send } from '@mui/icons-material';

import InviteForm from './InviteForm';
import { Typography } from '@mui/material';

import { useTranslation } from 'react-i18next'

const InviteTeamSignUp = (props) => {
    
    const { t } = useTranslation();

    return (
        <>
             <Grid container sx={{mt:2}}>
                <Grid item xs={12} md={12} align="center"> 
                    <TextField
                        required
                        type="number"
                        label={t('signupLabelNine')}
                        size="small"
                        value={props.membershipValue}
                        onChange={(event)=>{props.setMembershipValue(event.target.value)}}
                        error={props.errorMembership}
                        helperText={props.errorMembership ? t('signupErrorSeven'): ""}
                        sx={{width:{ xs: "80%", md: "30%" }}}
                        inputProps={{min: 0, style: { textAlign: 'center' }}}
                    />
                </Grid>
                <Grid item xs={12} md={12} align="center">
                    <Typography variant="caption">{t('signupTextOne')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12} md={12} sx={{m:2}} align="center"> 
                    <InviteForm
                        setPlan={props.setPlan}
                        inputList={props.inputList}
                        setInputList={props.setInputList}
                        errorTextInvites={props.errorTextInvites}
                    />
                </Grid>
                <Grid item xs={12} md={12} sx={{m:2}} align="center"> 
                    {/* <LoadingButton
                            onClick={props.register}
                            endIcon={<Send/>}
                            loading={props.loading}
                            loadingPosition="end"
                            variant="contained"
                            color="secondary" 
                            size="large" 
                            sx={{width: {xs: "80%", md: "60%"}}} 
                        >
                            Finish Setup
                    </LoadingButton> */}
                    <Button variant="contained" color="secondary" size="large" sx={{width: {xs: "80%", md: "60%"}}} onClick={props.handleNext}>{t('signupButtonTwo')}</Button>
                </Grid>
             </Grid>
        </>
    )
}

export default InviteTeamSignUp
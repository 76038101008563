import React, {useState,useEffect} from 'react';

import { Button, TextField, Grid, Radio, RadioGroup, Autocomplete, FormControlLabel, MenuItem, InputLabel, OutlinedInput, ListItemText, Select, Checkbox,FormControl } from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import moment from 'moment-timezone';

import { getDatabase, ref, push, update} from "firebase/database";

import { useTranslation } from 'react-i18next'

export default function AddMatch(props) {
    const { t } = useTranslation();

    const [clubId, setClubId] = useState("")
    const [uidMaster, setUidMaster] = useState("");

    const [name, setName] = useState("");
    const [cost, setCost] = useState("");
    const [type, setType] = useState('team');
    const [team, setTeam] = useState("");
    const [athlete, setAthlete] = useState("");
    const [staff, setStaff] = useState([]);
    const [location, setLocation] = useState("");
    const [date, setDate] = useState("");
    const [startHour, setStartHour] = useState("");
    const [endHour, setEndHour] = useState("");
    const [opponent, setOpponent] = useState("");
    const [description, setDescription] = useState("");

    const [teamsArr, setTeamsArr] = useState([])
    const [athletesArr, setAthletesArr] = useState([]);
    const [staffArr, setStaffArr] = useState([]);
    const [locationsArr, setLocationsArr] = useState([])

    const [loading, setLoading] = useState(false)
    const [buttonText, setButtonText] = useState(t('calendarDialogButtonFour'))

    const [errorName, setErrorName] = useState(false)
    const [errorParticipant, setErrorParticipant] = useState(false)
    const [errorLocation, setErrorLocation] = useState(false)
    const [errorDate, setErrorDate] = useState(false)
    const [errorStartHour, setErrorStartHour] = useState(false)
    const [errorEndHour, setErrorEndHour] = useState(false)

    const [noTeams, setNoTeams] = useState(false)

    useEffect(()=>{
        if(props.calendarData.uidMaster){
            setUidMaster(props.calendarData.uidMaster)
        }
        if(props.calendarData.clubId){
            setClubId(props.calendarData.clubId)
        }
        if(props.athletes){
            setAthletesArr(props.athletes)
        }
        if(props.staff){
            setStaffArr(props.staff)
        }
        if(props.calendarData.teams){
            let teams = props.calendarData.teams
            let keys = Object.keys(teams)
            let val = Object.values(teams)
            const newTeams = [];
 
            for(let i = 0 ; i < val.length; i++){
             val[i].uid = keys[i]
             newTeams.push(val[i])
            }
           setTeamsArr(newTeams)
        } else {
            setNoTeams(true)
            setType('individual')
        }
        if(props.calendarData.locations){
            let locations = props.calendarData.locations
            let keys = Object.keys(locations)
            let val = Object.values(locations)
            const newLocations = [];
 
            for(let i = 0 ; i < val.length; i++){
             val[i].uid = keys[i]
             newLocations.push(val[i])
            }
            setLocationsArr(newLocations)
        }
    },[props.calendarData.uidMaster,props.calendarData.clubId,props.athletes,props.staff,props.calendarData.teams,props.calendarData.locations])

    const handleChangeType = (event) => {
        setType(event.target.value);
    };

    const handleChangeStaff = (event) => {
        const {
          target: { value },
        } = event;
        setStaff(
          typeof value === 'string' ? value.split(',') : value,
        );
    };

    const validateName = () => {
        setErrorName(false)
        let isValid = true
        if (name !== ''){

        } else {
            isValid = false
            setErrorName(true)
        } 
        return isValid
    }
    
    const validateParticipant = () => {
        setErrorParticipant(false)
        let isValid = true
        if (athlete !== '' || team !== ''){

        } else {
            isValid = false
            setErrorParticipant(true)
        } 
        return isValid
    }

    const validateLocation = () => {
        setErrorLocation(false)
        let isValid = true
        if (location !== '' && location !== null){

        } else {
            isValid = false
            setErrorLocation(true)
        } 
        return isValid
    }

    const handleSave = () => {
        if(validateName() && validateParticipant() && validateLocation() && errorDate === null 
            && errorStartHour === null && errorEndHour === null)
        {
            setErrorName(false)
            setErrorParticipant(false)
            setErrorLocation(false)

            setLoading(true)
            setButtonText(t('calendarDialogButtonFour'))

            const db = getDatabase()

            let new_date = ""
            let new_sh = ""
            let new_eh = ""

            if(date._d){
                let formattedDate = moment(date._d).format('DD/MM/YYYY');
                new_date = formattedDate
            }

            if(startHour._d){
                let formattedTime = moment(startHour._d).format('HH:mm');
                new_sh = formattedTime
            }

            if(endHour._d){
                let formattedTime = moment(endHour._d).format('HH:mm');
                new_eh = formattedTime
            }

            push(ref(db, 'clubs/'+clubId+'/events'), {
                eventType: "match",
                addedBy: uidMaster,
                name: name,
                description: description,
                matchType: type,
                date: new_date,
                startHour: new_sh,
                endHour: new_eh,
                opponent: opponent,
                cost: cost,
            }).then((snap)=>{
                setLoading(false)
                setButtonText(t('calendarDialogButtonSix'))
                props.setMessageSnack(t('calendarDialogSnackOne'))
                props.setOpenSnack(true)
                props.handleClickClose()

                const key = snap.key 

                if(typeof location === 'object' && location !== undefined && location !== null){
                    const updates = {};
                    updates['clubs/'+clubId+'/events/'+key+"/location"] = location.uid
                    updates['clubs/'+clubId+'/events/'+key+"/locationType"] = "club"
                    update(ref(db), updates)
                } else if(typeof location === 'string' && location !== '' && location.length > 0) {
                    const updates = {};
                    updates['clubs/'+clubId+'/events/'+key+"/location"] = location
                    updates['clubs/'+clubId+'/events/'+key+"/locationType"] = "custom"
                    update(ref(db), updates)
                }

                if(type === "team" && team){
                    const updates = {};
                    updates['clubs/'+clubId+'/events/'+key+"/team"] = team.uid
                    update(ref(db), updates)

                    for (const athleteId in team.athletes) {    
                        push(ref(db, 'users/'+athleteId+'/invites'), {
                            eventId: key,
                            status: "pending"
                        })
                    }
                }

                if(type === "individual" && athlete){
                    const updates = {};
                    updates['clubs/'+clubId+'/events/'+key+"/athlete"] = athlete.uid
                    update(ref(db), updates)

                    push(ref(db, 'users/'+athlete.uid+'/invites'), {
                        eventId: key,
                        status: "pending"
                    })
                }

                if(staff.length>0){
                    staff.forEach(uid=>{
                        const updates = {};
                        updates['clubs/'+clubId+'/events/'+key+'/staff/'+uid] = uid
                        update(ref(db), updates)

                        push(ref(db, 'users/'+uid+'/invites'), {
                            eventId: key,
                            status: "pending"
                        })
                    })
                }
            })
        } else {
            setLoading(false)
            setButtonText(t('calendarDialogButtonFour'))
        }
    };


    return (
        <>
        <Grid container>
            <Grid item xs={6}>
                <TextField
                    required
                    size="small"
                    label={t('calendarDialogLabelOne')}
                    type="text"
                    value={name}
                    onChange={(e)=>{setName(e.target.value)}}
                    error={errorName}
                    helperText={errorName ? t('calendarDialogErrorOne') : ""}
                    sx={{width:"80%"}}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    size="small"
                    label={t('calendarDialogLabelTwo')}
                    type="text"
                    value={description}
                    onChange={(e)=>{setDescription(e.target.value)}}
                    sx={{width:"80%"}}
                />
            </Grid>
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={type}
                onChange={handleChangeType}
                sx={{width:"100%", mt:1, justifyContent:'center'}}
                align="center"
                row
                >
                    <FormControlLabel disabled={noTeams} value="team" control={<Radio />} label={t('calendarDialogLabelThree')} labelPlacement='end' color="primary"/>
                    <FormControlLabel value="individual" control={<Radio />} label={t('calendarDialogLabelFour')} labelPlacement='end'/>
            </RadioGroup>
            {type === "team" ? 
                <Grid item xs={6} sx={{mt:1}}>
                    <TextField
                        required
                        size="small"
                        select
                        label={t('calendarDialogLabelFive')}
                        value={team}
                        onChange={(e)=>{setTeam(e.target.value)}}
                        error={errorParticipant}
                        helperText={errorParticipant ? t('calendarDialogErrorTwo') : ""}
                        sx={{width: '80%'}}
                    >
                    {teamsArr.map((option) => (
                    <MenuItem key={option.uid} value={option} color="secondary">
                    {option.name}
                    </MenuItem>
                    ))}
                    </TextField>
                </Grid>
                :
                <Grid item xs={6} sx={{mt:1}}>
                    <TextField
                        required
                        size="small"
                        select
                        label={t('calendarDialogLabelSix')}
                        value={athlete}
                        onChange={(e)=>{setAthlete(e.target.value)}}
                        error={errorParticipant}
                        helperText={errorParticipant ? t('calendarDialogErrorTwo') : ""}
                        sx={{width: '80%'}}
                    >
                    {athletesArr.map((option) => (
                    <MenuItem key={option.uid} value={option} color="secondary">
                    {option.firstName+" "+option.lastName}
                    </MenuItem>
                    ))}
                    </TextField>
                </Grid>
            }
            <Grid item xs={6}>
                <FormControl sx={{ width: "80%"}}>
                    <InputLabel id="demo-multiple-checkbox-label">{t('calendarDialogLabelSeven')}</InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        size="small"
                        placeholder='label'
                        notched={true}
                        value={staff}
                        onChange={handleChangeStaff}
                        input={<OutlinedInput />}
                        renderValue={
                            (selected) => 
                                Object.values(staffArr).filter( name => selected.includes(name.uid) )
                                    .map( record => record.firstName + " " + record.lastName )
                                    .join(", ")
                        }
                        sx={{color:'black',mt:1, "&:hover": {
                        "&& fieldset": {
                            borderColor: "#54cdb7"
                        }
                        }}}
                    >
                    {staffArr.map((person) => (
                        <MenuItem key={person.uid} value={person.uid}>
                        <Checkbox checked={staff.indexOf(person.uid) > -1} />
                        <ListItemText primary={person.firstName + " " + person.lastName} />
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6} sx={{mt:1}}>
            {locationsArr.length > 0 ? 
            <>
                <Autocomplete
                    freeSolo
                    options={locationsArr}
                    getOptionLabel={(locationsArr) => locationsArr.name}
                    onChange={(event, newValue) => {
                        setLocation(newValue);
                        }}
                    onInputChange={(event, newInputValue) => {
                        setLocation(newInputValue);
                    }}
                    renderInput={(params) => 
                        <TextField 
                        {...params} 
                        required
                        size="small"
                        label={t('calendarDialogLabelEight')}
                        error={errorLocation}
                        helperText={errorLocation ? t('calendarDialogErrorThree') : ""}
                        sx={{width: '80%'}}
                        />}
                />
            </>
            :
            <>
                <TextField
                    required
                    size="small"
                    label={t('calendarDialogLabelEight')}
                    type="text"
                    value={location}
                    onChange={(e)=>{setLocation(e.target.value)}}
                    error={errorLocation}
                    helperText={errorLocation ? t('calendarDialogErrorThree') : ""}
                    sx={{width:"80%"}}
                />
            </>
            }
            </Grid>
            <Grid item xs={6} sx={{mt:1}}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        inputFormat="DD/MM/yyyy"
                        disablePast
                        required
                        label={t('calendarDialogLabelNine')}
                        openTo="day"
                        views={['year', 'month', 'day']}
                        value={date}
                        onChange={(newValue) => {
                            setDate(newValue);
                        }}
                        onError={(newError) => setErrorDate(newError)}
                        renderInput={(params) => <TextField {...params} required size="small" sx={{width: '80%'}} 
                                                    helperText={errorDate ? t('calendarDialogErrorFour') : ""}
                                                />}
                        />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={6} sx={{mt:1}}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                        required
                        label={t('calendarDialogLabelTen')}
                        value={startHour}
                        ampm={false}
                        onChange={(newValue) => {
                        setStartHour(newValue);
                        }}
                        onError={(newError) => setErrorStartHour(newError)}
                        renderInput={(params) => <TextField {...params} required size="small" sx={{width: '80%'}} 
                                                    helperText={errorStartHour ? t('calendarDialogErrorFive') : ""}
                                                />}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={6} sx={{mt:1}}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                        label={t('calendarDialogLabelEleven')}
                        value={endHour}
                        ampm={false}
                        onChange={(newValue) => {
                        setEndHour(newValue);
                        }}
                        onError={(newError) => setErrorEndHour(newError)}
                        renderInput={(params) => <TextField {...params} required size="small" sx={{width: '80%'}} 
                                                    helperText={errorEndHour ? t('calendarDialogErrorFive') : ""}
                                                    />}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    size="small"
                    label={t('calendarDialogLabelSixteen')}
                    type="text"
                    value={opponent}
                    onChange={(e)=>{setOpponent(e.target.value)}}
                    sx={{width:"80%", mt:1}}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    size="small"
                    label="Cost"
                    type="number"
                    value={cost}
                    onChange={(e)=>{setCost(e.target.value)}}
                    sx={{width:"80%", mt: 1}}
                />
            </Grid>
            <Grid item xs={6} sx={{mt:2, width:'80%'}} align="center">
                <Button onClick={props.handleClickClose} variant="contained" size="small" sx={{color:"#FFF", width:'50%', backgroundColor:"gray", ":hover":{backgroundColor:"#303030"}}}>{t('calendarDialogButtonFive')}</Button>
            </Grid>
            <Grid item xs={6} sx={{mt:2, width:'80%'}} align="center">
                <LoadingButton
                    onClick={handleSave}
                    loading={loading}
                    variant="contained"
                    color="primary" 
                    size="small" 
                    sx={{color:"#FFF", width:'50%'}}
                >
                    {buttonText}
                </LoadingButton>
            </Grid>
        </Grid>
        </>
    )
}